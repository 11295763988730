import { Grid2, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';

interface ToleranceProfileTableComponentProps {
  title: string;
  searchValue: string;
  columns: string[];
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPlusIconClick: () => void;
}

const ToleranceProfileTableComponent: React.FC<
  ToleranceProfileTableComponentProps
> = ({ title, searchValue, columns, onPlusIconClick, onSearchValueChange }) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: unknown;
  }>(null);
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();
  const [totalHeight, setTotalHeight] = useState<number>(
    window.innerHeight - 300
  );

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, []);

  const handleIconClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      direction={'column'}
      columnSpacing={2}
      rowSpacing={1.5}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2
        container
        display={'flex'}
        alignItems={'baseline'}
        paddingBottom={'12px'}
      >
        <Typography variant="h4" fontSize="32px" fontWeight={500}>
          {t(title)}
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: '46px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '100%',
        }}
      >
        <PlusIcon
          onClick={onPlusIconClick}
          cursor={'pointer'}
          style={{
            width: '16px',
            height: '16px',
            marginLeft: '10px',
            padding: '8px 4px',
          }}
        />
        <Grid2
          container
          display={'flex'}
          alignItems={'center'}
          sx={{
            height: '100%',
            width: '100%',
            borderLeft: '1px solid rgba(131, 131, 131, 0.4)',
            marginLeft: '10px',
          }}
        >
          <Grid2
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginLeft: '10px',
            }}
          >
            <SearchIcon
              onClick={handleIconClick}
              cursor={'text'}
              style={{
                fill: '#838383',
                width: '13px',
                height: '13px',
                marginRight: '8px',
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              width: '80%',
            }}
          >
            <TextField
              inputRef={textFieldRef}
              sx={{
                'width': '100%',
                'padding': '0px',
                '& .MuiOutlinedInput-root': {
                  'padding': '0px',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
                '& input::placeholder': {
                  fontSize: '15px',
                },
              }}
              value={searchValue}
              onChange={onSearchValueChange}
              placeholder={t('Search')}
              variant="outlined"
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ToleranceProfileTableComponent;
