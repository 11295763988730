import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import DriverMarker from './DriverMarker';
import { Rtl } from '../../../interfaces/Map/mapMarkers';

interface ClusteredRtlMarkersProps {
  rtlMarkers: Rtl[];
}

const ClusteredRtlMarkers: React.FC<ClusteredRtlMarkersProps> = ({
  rtlMarkers,
}) => {
  const [markers, setMarkers] = useState<{ [key: string]: google.maps.Marker }>(
    {}
  );
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  const setMarkerRef = useCallback(
    (marker: google.maps.Marker | null, key: string) => {
      setMarkers((prevMarkers) => {
        if ((marker && prevMarkers[key]) || (!marker && !prevMarkers[key]))
          return prevMarkers;
        if (marker) {
          return { ...prevMarkers, [key]: marker };
        } else {
          const { [key]: _, ...newMarkers } = prevMarkers;
          return newMarkers;
        }
      });
    },
    []
  );

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));

    return () => {
      clusterer.clearMarkers();
    };
  }, [clusterer, markers]);

  return (
    <>
      {rtlMarkers.map((rtl) => (
        <DriverMarker
          key={rtl.rtl_serial}
          position={{
            lat: Number(rtl.latitude),
            lng: Number(rtl.longitude),
          }}
          mag={2.4}
          name={""}
          sealID={rtl.rtl_serial}
          vehicleAlias={""}
          setMarkerRef={setMarkerRef}
        />
      ))}
    </>
  );
};

export default ClusteredRtlMarkers;
