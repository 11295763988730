import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Drawer,
  Slide,
  Typography,
  useMediaQuery,
  //   IconButton,
  //   Icon,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { io, Socket } from 'socket.io-client';
import env from 'react-dotenv';
import EmergencyModal from './EmergencyModal';
import { getDriverId, setDriverId } from '../../utils/expandDriverCard';
import { ReactComponent as BellIcon } from '../../assets/icons/bell-icon.svg';
import { ReactComponent as CollapseIcon } from '../../assets/icons/collapse.svg';
import { ReactComponent as BellDotIcon } from '../../assets/icons/bell-with-dot.svg';
import Lottie from 'lottie-react';
import openNoDot from './NotificationAnimations/OpenNoDot.json';
import closeNoDot from './NotificationAnimations/CloseNoDot.json';
import closeDot from './NotificationAnimations/CloseDot.json';
import openDot from './NotificationAnimations/OpenDot.json';
import firstDot from './NotificationAnimations/DotFirst.json';
import nthDot from './NotificationAnimations/NthDot.json';
import NotificationTemplate from './NotificationTemplate';
import { keyframes } from '@emotion/react';
import { ReactComponent as RtlActivityLogTemperature } from '../../assets/icons/rtl-activity-temperature.svg';
import { ReactComponent as RtlActivityLogHumidity } from '../../assets/icons/rtl-activity-humidity.svg';
import { ReactComponent as RtlActivityLogLux } from '../../assets/icons/rtl-activity-lux.svg';
import { ReactComponent as RtlActivityLogShock } from '../../assets/icons/rtl-activity-shock.svg';
import { useRtlDevices } from '../../context/RtlDevicesContext';

const API_BASE_URL = env.REACT_APP_API_BASE_URL;
const RTL_SERVICE_URL = env.REACT_APP_NOTIFICATION_SERVICE_URL;

interface EmergencyMessage {
  id: string;
  text: string;
  timestamp?: string;
  highlighted?: boolean;
  details: {
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
  };
}

interface NotificationMessage {
  id: string;
  text: string;
  timestamp?: string;
  details: {
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
    serial_number: string;
    seal_id: string;
    company_id: string;
    unlock_left: boolean;
    route_id?: string;
    trip_id?: string;
    reAdd?: boolean;
  };
}

interface RtlEventData {
  id: string;
  value: number;
  timestamp: string;
  message?: string;
  type: 'temperature' | 'humidity' | 'lux' | 'shock';
}

const ripple = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scale(1.05);
    opacity: 0.75;
  }
  40% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  60% {
    transform: scale(1.15);
    opacity: 0.25;
  }
  80% {
    transform: scale(1.2);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.25);
    opacity: 0;
  }
`;

const dotFade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const NotificationManager: React.FC = () => {
  const [emergencyMessages, setEmergencyMessages] = useState<
    EmergencyMessage[]
  >([]);
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);

  const RTL_STORAGE_KEY = 'rtl_activity_log';
  const RTL_MAX_ENTRIES = 50;
  const RTL_MAX_AGE_DAYS = 7;

  const filterOldEntries = (entries: RtlEventData[]): RtlEventData[] => {
    const maxAge = new Date();
    maxAge.setDate(maxAge.getDate() - RTL_MAX_AGE_DAYS);

    return entries.filter((entry) => {
      const entryDate = new Date(entry.timestamp);
      return entryDate > maxAge;
    });
  };
  const [newEntryId, setNewEntryId] = useState<string | null>(null);

  const { rtlDevices } = useRtlDevices();
  const [rtlActivityLog, setRtlActivityLog] = useState<RtlEventData[]>(() => {
    const savedLog = localStorage.getItem(RTL_STORAGE_KEY);
    const parsedLog = savedLog ? JSON.parse(savedLog) : [];
    return filterOldEntries(parsedLog);
  });

  const [prevRtlLogLength, setPrevRtlLogLength] = useState(
    rtlActivityLog.length
  );
  const [notificationsViewed, setNotificationsViewed] = useState(true);
  const [rtlLogsViewed, setRtlLogsViewed] = useState(true);
  const [cookies] = useCookies(['user', 'super_user', 'token']);
  const [, setSocket] = useState<Socket | null>(null);
  const isSmallViewport = useMediaQuery('(max-width:1500px)');
  const drawerWidth = isSmallViewport ? '70px' : '90px';
  const containerRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentAnimation, setCurrentAnimation] = useState<any>(null);
  const [prevNotificationsLength, setPrevNotificationsLength] = useState(
    notifications.length
  );

  const handleIconClick = () => {
    if (drawerOpen) {
      // When closing drawer
      if (!notificationsViewed || !rtlLogsViewed) {
        setCurrentAnimation(closeDot);
      } else {
        setCurrentAnimation(closeNoDot);
      }
      setDrawerOpen(false);
    } else {
      // When opening drawer
      if (!notificationsViewed || !rtlLogsViewed) {
        setCurrentAnimation(openDot);
      } else {
        setCurrentAnimation(openNoDot);
      }
      setDrawerOpen(true);
      setNotificationsViewed(true);
      setRtlLogsViewed(true);
    }
  };

  useEffect(() => {
    if (!drawerOpen) {
      if (
        (prevNotificationsLength === 0 && notifications.length === 1) ||
        (prevRtlLogLength === 0 && rtlActivityLog.length === 1)
      ) {
        // First notification
        setCurrentAnimation(firstDot);
        setNotificationsViewed(false);
        setRtlLogsViewed(false);
      } else if (
        prevNotificationsLength < notifications.length ||
        prevRtlLogLength < rtlActivityLog.length
      ) {
        // Subsequent notifications
        setCurrentAnimation(nthDot);
        if (prevNotificationsLength < notifications.length) {
          setNotificationsViewed(false);
        }
        if (prevRtlLogLength < rtlActivityLog.length) {
          setRtlLogsViewed(false);
        }
      }
    }
    setPrevNotificationsLength(notifications.length);
    setPrevRtlLogLength(rtlActivityLog.length);
  }, [notifications, rtlActivityLog, drawerOpen]);

  useEffect(() => {
    let timeoutDuration = 1250;

    switch (currentAnimation) {
      case firstDot:
      case nthDot:
        timeoutDuration = 3270;
        break;
      case openDot:
      case openNoDot:
      case closeDot:
      case closeNoDot:
        timeoutDuration = 1250;
        break;
    }

    if (currentAnimation) {
      const timer = setTimeout(() => {
        setCurrentAnimation(null);
      }, timeoutDuration);
      return () => clearTimeout(timer);
    }
  }, [currentAnimation]);

  useEffect(() => {
    if (cookies.user?.id && rtlDevices.length > 0) {
      const deviceIds = rtlDevices.map((device) => device.serial);

      fetch(`${RTL_SERVICE_URL}/register-sse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ device_ids: deviceIds }),
      })
        .then((response) => response.json())
        .then(() => {
          const queryString = deviceIds
            .map((id) => `device_ids=${id}`)
            .join('&');
          const eventSource = new EventSource(
            `${RTL_SERVICE_URL}/sse?${queryString}&channel=notification`
          );

          eventSource.onmessage = (event) => {
            try {
              const jsonString = event.data.replace(/'/g, '"');
              const parsedData = JSON.parse(jsonString);

              const rtlData: RtlEventData = {
                id: parsedData.device_id,
                value: Number(parsedData.value),
                timestamp: parsedData.timestamp,
                type: parsedData.type as
                  | 'temperature'
                  | 'humidity'
                  | 'lux'
                  | 'shock',
              };

              setRtlActivityLog((prevLog) => {
                const newLog = [rtlData, ...prevLog].slice(0, RTL_MAX_ENTRIES);
                return newLog;
              });

              // Set the new entry ID and make sure drawer is open
              setNewEntryId(`${rtlData.id}-0`);
              // Clear the highlight after 1.5 seconds to match animation duration
              setTimeout(() => setNewEntryId(null), 5000);
            } catch (error) {
              console.error('Error processing SSE message:', error);
            }
          };

          eventSource.onerror = (error) => {
            console.error('SSE Error:', error);
            eventSource.close();
          };

          return () => eventSource.close();
        })
        .catch((error) => {
          console.error('Error registering SSE:', error);
        });
    }
  }, [cookies.user?.id, rtlDevices]);

  useEffect(() => {
    const filteredLog = filterOldEntries(rtlActivityLog);
    const trimmedLog = filteredLog.slice(0, RTL_MAX_ENTRIES);
    localStorage.setItem(RTL_STORAGE_KEY, JSON.stringify(trimmedLog));
  }, [rtlActivityLog]);

  useEffect(() => {}, [rtlActivityLog]);
  // Add this helper function above the component
  const getUnitForType = (type: string): string => {
    switch (type) {
      case 'temperature':
        return '°C';
      case 'humidity':
        return '%';
      case 'lux':
        return ' lux';
      case 'shock':
        return 'g';
      default:
        return '';
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleClearRtlLog = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.preventDefault();
    e.stopPropagation();

    setRtlActivityLog([]);
    localStorage.setItem(RTL_STORAGE_KEY, JSON.stringify([]));
  };

  useEffect(() => {
    if (cookies.user) {
      const userData = cookies.user;
      if (userData.id) {
        const newSocket = io(API_BASE_URL, {
          query: { userId: userData.id },
          path: '/api/socket.io',
        });
        setSocket(newSocket);
        newSocket.on(
          'emergency',
          (message: {
            id: string;
            text: string;
            timestamp: string;
            information: {
              contact_number: string;
              vehicle_alias: string;
              driver_id: string;
              driver_name: string;
              latitude: string;
              longitude: string;
            };
          }) => {
            setEmergencyMessages((prevMessages) => {
              const existingMessageIndex = prevMessages.findIndex(
                (msg) => msg.details.driver_id === message.information.driver_id
              );

              if (existingMessageIndex !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[existingMessageIndex] = {
                  ...updatedMessages[existingMessageIndex],
                  text: message.text,
                  details: message.information,
                  highlighted: true,
                };

                setTimeout(() => {
                  updatedMessages[existingMessageIndex].highlighted = false;
                  setEmergencyMessages([...updatedMessages]);
                }, 2000);

                return updatedMessages;
              }

              return [
                ...prevMessages,
                {
                  id: message.id,
                  text: message.text,
                  details: message.information,
                  highlighted: false,
                },
              ];
            });
          }
        );

        newSocket.on('requestunlock', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('requestlock', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('skiprequest', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('lockfeedback', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('cradlelockfeedback', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        return () => {
          newSocket.off('emergency');
          newSocket.off('requestunlock');
          newSocket.off('skiprequest');
          newSocket.off('lockfeedback');
          newSocket.off('requestlock');
          newSocket.off('cradlelockfeedback');
          newSocket.close();
        };
      }
    }
  }, [cookies.user]);

  useEffect(() => {
    if (lastMessageRef.current) {
      const timer = setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [emergencyMessages]);

  const removeMessage = (index: number, id: string) => {
    setEmergencyMessages((prevMessages) =>
      prevMessages.filter((_, i) => i !== index)
    );
    if (getDriverId() === id) {
      setDriverId('');
    }
  };

  const formatTimestamp = (timestamp: string): string => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInMinutes = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60)
    );

    if (diffInMinutes < 1) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} ${
        diffInMinutes === 1 ? 'minute' : 'minutes'
      } ago`;
    } else if (diffInMinutes < 1440) {
      // less than 24 hours
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      });
    } else {
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: now.getFullYear() !== date.getFullYear() ? 'numeric' : undefined,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        closeRef.current &&
        !closeRef.current.contains(event.target as Node)
      ) {
        setDrawerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 1300,
        width: 'calc(100vw)',
        maxWidth: `calc(100vw - ${drawerWidth})`,
        display: 'flex',
        flexDirection: 'row-reverse',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 1400,
        }}
      >
        <Box
          onClick={handleIconClick}
          sx={{
            top: 0,
            right: 0,
            zIndex: 1400,
            height: '60px',
            width: '160px',
            visibility: 'visible',
            transform: drawerOpen ? 'translateX(-400px)' : 'translateX(0)',
            transition: drawerOpen
              ? 'transform 0.3s ease-in-out'
              : 'transform 0.2s ease-in-out',
          }}
        >
          {currentAnimation ? (
            <Lottie
              animationData={currentAnimation}
              loop={true}
              autoplay={true}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice',
              }}
            />
          ) : (
            <Box
              ref={closeRef}
              style={{
                backgroundColor: 'white',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                width: '75px',
                height: '73px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'auto',
                marginTop: '12px',
                boxShadow: '-5px 0px 5px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              {drawerOpen ? (
                <CollapseIcon
                  style={{
                    marginRight: '8.5px',
                    width: 34,
                    height: 34,
                  }}
                />
              ) : (!notificationsViewed && notifications.length > 0) ||
                (!rtlLogsViewed && rtlActivityLog.length > 0) ? (
                <BellDotIcon
                  style={{
                    marginBottom: '4.5px',
                    marginLeft: '2.5px',
                    width: 32,
                    height: 32,
                  }}
                />
              ) : (
                <BellIcon
                  style={{
                    width: 28,
                    height: 28,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        hideBackdrop
        PaperProps={{
          sx: { width: 400, boxShadow: '-10px -10px 50px 0px #0000001A' },
        }}
      >
        <Box
          sx={{
            height: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 8px 0 8px',
          }}
        ></Box>
        <Box sx={{ padding: '0 24px 10px 24px' }}>
          <Typography>Alerts</Typography>
        </Box>
        <Box ref={drawerRef} sx={{ overflowY: 'auto' }}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => {
              const uniqueKey = `${notification.details.seal_id}-${index}`;
              return (
                <NotificationTemplate
                  key={uniqueKey}
                  uniqueKey={uniqueKey}
                  text={notification.text}
                  details={notification.details}
                  onClose={(uniqueKey: string) =>
                    setNotifications((prev) =>
                      prev.filter(
                        (_, i) =>
                          `${notification.details.seal_id}-${i}` !== uniqueKey
                      )
                    )
                  }
                />
              );
            })
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px 24px',
                color: 'text.secondary',
              }}
            >
              <Typography variant="body1" textAlign="center">
                No notifications at the moment
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            padding: '0 24px 10px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>RTL Activity Log ({rtlActivityLog.length})</Typography>
          {rtlActivityLog.length > 0 && (
            <Box onClick={handleClearRtlLog}>
              <Typography
                component="span"
                sx={{
                  'cursor': 'pointer',
                  'color': 'text.secondary',
                  'fontSize': '0.875rem',
                  'display': 'inline-block',
                  'userSelect': 'none',
                  'padding': '4px 8px',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                Clear All
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            padding: '0 24px 10px 24px',
            overflowY: 'auto',
          }}
        >
          {rtlActivityLog.length > 0 ? (
            rtlActivityLog.map((entry, index) => {
              let IconComponent;
              switch (entry.type) {
                case 'temperature':
                  IconComponent = RtlActivityLogTemperature;
                  break;
                case 'humidity':
                  IconComponent = RtlActivityLogHumidity;
                  break;
                case 'lux':
                  IconComponent = RtlActivityLogLux;
                  break;
                case 'shock':
                  IconComponent = RtlActivityLogShock;
                  break;
                default:
                  IconComponent = null;
              }

              return (
                <Box
                  key={`${entry.id}-${index}`}
                  sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    'justifyContent': 'space-between',
                    'padding': '12px 0',
                    'borderBottom': '1px solid rgba(0, 0, 0, 0.05)',
                    'position': 'relative',
                    '&::after': {
                      content: '""',
                      display:
                        drawerOpen && newEntryId === `${entry.id}-${index}`
                          ? 'block'
                          : 'none',
                      position: 'absolute',
                      top: '4px',
                      right: '-4px',
                      width: '8px',
                      height: '8px',
                      backgroundColor: '#FF4444',
                      borderRadius: '50%',
                      animation: `${dotFade} 1.5s ease-out forwards`,
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {IconComponent && (
                      <Box sx={{ marginRight: '12px' }}>
                        <IconComponent />
                      </Box>
                    )}
                    <Box>
                      <Typography variant="body2" fontWeight="bold">
                        {entry.id}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {entry.type.charAt(0).toUpperCase() +
                          entry.type.slice(1)}{' '}
                        warning - {entry.value}
                        {getUnitForType(entry.type)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    {formatTimestamp(entry.timestamp)}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px 24px',
                color: 'text.secondary',
              }}
            >
              <Typography variant="body1" textAlign="center">
                No RTL activity at the moment
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>

      {emergencyMessages.map((message, index) => (
        <Slide
          key={message.id}
          direction="left"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 1000, exit: 300 }}
          easing={{
            enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
            exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          <Box
            ref={index === emergencyMessages.length - 1 ? lastMessageRef : null}
            tabIndex={-1}
            sx={{
              marginRight: 2,
              marginTop: 1,
              zIndex: 1500,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'auto',
              marginBottom: 2.5,
              height: 'auto',
              backgroundColor: '#17b87c',
              borderRadius: 4,
              ...(message.highlighted && {
                '&:before, &:after': {
                  position: 'absolute',
                  content: '""',
                  top: '-3px',
                  right: '-3px',
                  bottom: '-3px',
                  left: '-3px',
                  border: 'solid 6px rgba(186, 31, 31, 0.5)',
                  borderRadius: 4,
                },
                '&:before': {
                  animation: `${ripple} 1s linear 0s infinite`,
                },
                '&:after': {
                  animation: `${ripple} 1s linear 0.2s infinite`,
                },
              }),
            }}
          >
            <EmergencyModal
              message={message.text}
              onClose={() => removeMessage(index, message.details.driver_id)}
              details={message.details}
            />
          </Box>
        </Slide>
      ))}
    </Box>
  );
};

export default NotificationManager;
