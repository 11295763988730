import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Marker, InfoWindow } from '@vis.gl/react-google-maps';
import { Typography } from '@mui/material';

interface DriverMarkerProps {
  position: google.maps.LatLngLiteral;
  mag: number;
  name: string;
  sealID: string;
  vehicleAlias: string;
  setMarkerRef: (marker: google.maps.Marker | null, key: string) => void;
}

const createCustomIcon = (mag: number) => {
  const scale = Math.pow(2, mag) / 2;
  const outerRadius = scale * 10;
  const innerRadius = scale * 5;

  const svg = `
    <svg width="${outerRadius * 2}" height="${outerRadius * 2}" viewBox="0 0 ${
    outerRadius * 2
  } ${outerRadius * 2}" xmlns="http://www.w3.org/2000/svg">
      <circle cx="${outerRadius}" cy="${outerRadius}" r="${outerRadius}" fill="#B4EE2B" fill-opacity="0.4" />
      <circle cx="${outerRadius}" cy="${outerRadius}" r="${innerRadius}" fill="#B4EE2B" />
      <g transform="translate(${outerRadius - 8}, ${
    outerRadius - 4
  }) scale(0.7)">
        <g filter="url(#filter0_d_2254_13380)">
          <path d="M7.3832 0.613284C6.38927 0.197441 5.39101 1.1957 5.80685 2.18963L13.8646 21.449L15.0742 21.449L18.3028 13.1092L26.6426 9.88065L26.6426 8.671L7.3832 0.613284Z" fill="black"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_2254_13380" x="0.891075" y="0.516479" width="30.5704" height="30.5703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4.81889"/>
          <feGaussianBlur stdDeviation="2.40944"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2254_13380"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2254_13380" result="shape"/>
        </filter>
      </defs>
    </svg>
  `;

  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: new google.maps.Size(outerRadius * 2, outerRadius * 2),
    anchor: new google.maps.Point(outerRadius, outerRadius),
  };
};

const DriverMarker: React.FC<DriverMarkerProps> = ({
  position,
  mag,
  name,
  sealID,
  vehicleAlias,
  setMarkerRef,
}) => {
  const [isInfoWindowVisible, setInfoWindowVisible] = useState(false);
  const markerRef = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isInfoWindowVisible) {
        const infoWindowElement = document.querySelector('.gm-style-iw-a');
        const markerElement = document.querySelector(`[title="${name}"]`);

        if (infoWindowElement && markerElement) {
          if (
            !infoWindowElement.contains(event.target as Node) &&
            !markerElement.contains(event.target as Node)
          ) {
            setInfoWindowVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isInfoWindowVisible, name]);

  const handleClick = useCallback((e: google.maps.MapMouseEvent) => {
    e.stop();
    setInfoWindowVisible(true);
  }, []);
  const ref = useCallback(
    (marker: google.maps.Marker) => {
      setMarkerRef(marker, sealID);
      markerRef.current = marker;
    },
    [setMarkerRef, sealID]
  );

  return (
    <>
      <Marker
        position={position}
        icon={createCustomIcon(mag)}
        title={name}
        onClick={handleClick}
        ref={ref}
      />
      {isInfoWindowVisible && (
        <InfoWindow
          anchor={markerRef.current}
          onCloseClick={() => setInfoWindowVisible(false)}
        >
          <div
            style={{
              width: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              gap: '0',
              padding: '0 24px 24px 24px',
            }}
          >
            <Typography
              align="center"
              style={{
                fontFamily: 'Open Sans',
                color: 'white',
                marginBottom: '10px',
                fontSize: '1.2rem',
              }}
            >
              {name}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                justifyContent: 'center',
              }}
            >
              <Typography
                align="center"
                style={{
                  fontFamily: 'Open Sans',
                  color: 'white',
                  fontSize: '0.8rem',
                }}
              >
                {sealID}
              </Typography>
              <Typography
                align="center"
                style={{
                  fontFamily: 'Open Sans',
                  color: 'white',
                  fontSize: '0.8rem',
                }}
              >
                {vehicleAlias}
              </Typography>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default DriverMarker;
