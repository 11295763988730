import {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import { Box, Typography, IconButton, Tooltip, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronDownIcon from '../../../../../assets/icons/chevron-down.svg';
import ChevronUpIcon from '../../../../../assets/icons/chevron-up.svg';
import RTLGreen from '../../../../../assets/icons/rtl-green-large.svg';
import RTLOrange from '../../../../../assets/icons/rtl-orange-large.svg';
import { RTLInfo } from '../../../../../interfaces/Driver/Response/companyDriverCards';
import RTLHumidity from '../../../../../assets/icons/rtl-humidity.svg';
import RTLLux from '../../../../../assets/icons/rtl-lux.svg';
import RTLTemp from '../../../../../assets/icons/rtl-temp.svg';
import RTLShock from '../../../../../assets/icons/rtl-shock.svg';
import WarningRed from '../../../../../assets/icons/warning-red.svg';
import RTLUndefined from '../../../../../assets/icons/rtl-undefined.svg';
import ArrowRight from '../../../../../assets/icons/arrow-right.svg';

interface Warning {
  id: string;
  timestamp: string;
  temperature?: string;
  humidity?: string;
  light?: string;
  shock?: string;
  seen: boolean;
}
interface RTLBoxProps {
  data: RTLInfo;
  rtlSerial: string;
  status: string;
  onToggle: (height: number, expanded: boolean) => void;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  currentExpandedBox: 'buttonBox' | string | null;
  id: string;
  loadTime: Date;
  warnings: Warning[];
  rtlClick?: (rtlInfo: RTLInfo) => void;
}

const RTLBox = forwardRef(function RTLBox(
  {
    data,
    rtlSerial,
    status,
    onToggle,
    loadTime,
    expanded,
    setExpanded,
    currentExpandedBox,
    id,
    warnings,
    rtlClick,
  }: RTLBoxProps,
  ref
) {
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate(); // Initialize useHistory
  const [lastUpdatedText, setLastUpdatedText] = useState<string>(
    'less than a minute ago'
  );

  useImperativeHandle(ref, () => ({
    getContentHeight: () => {
      return contentRef.current ? contentRef.current.scrollHeight : 0;
    },
  }));

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleViewFullReport = (event: React.MouseEvent) => {
    event.stopPropagation();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    navigate(`/rtl/${rtlSerial}`, {
      state: {
        rtlData: {
          id: data.id,
          rtl_id: rtlSerial,
          alias: `RTL ${rtlSerial}`,
        },
      },
    });
  };

  const handleWarningsReport = (event: React.MouseEvent) => {
    event.stopPropagation();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    navigate(`/rtl/recent-warnings/${rtlSerial}`, {
      state: {
        rtlData: {
          id: data.id,
          rtl_id: rtlSerial,
          alias: `RTL ${rtlSerial}`,
        },
      },
    });
  };

  useEffect(() => {
    if (currentExpandedBox === id && contentRef.current) {
      const height = expanded ? contentRef.current.scrollHeight + 24 : 44;
      onToggle(height, expanded);

      if (expanded && rtlClick) {
        rtlClick(data);
      }
    }
  }, [
    expanded,
    onToggle,
    currentExpandedBox,
    id,
    rtlClick,
    data,
    contentRef.current?.scrollHeight,
  ]);

  const getIcon = (status: string) => {
    switch (status) {
      case 'CONNECTED':
        return RTLGreen;
      case 'NO_CONNECTION':
        return RTLUndefined;
      case 'WARNING':
        return RTLOrange;
      case 'RECENT_WARNING':
        return RTLOrange;
      case 'ALERT':
        return WarningRed;
      case 'RECENT_ALERT':
        return RTLOrange;
      default:
        return RTLUndefined;
    }
  };

  const getWarningIcon = (warning: Warning) => {
    if (warning.temperature) return RTLTemp;
    if (warning.humidity) return RTLHumidity;
    if (warning.light) return RTLLux;
    if (warning.shock) return RTLShock;
    return RTLUndefined;
  };

  // Add this helper function inside the RTLBox component
  const formatWarningValue = (warning: Warning) => {
    if (warning.temperature) return `${warning.temperature}°C`;
    if (warning.humidity) return `${warning.humidity}%`;
    if (warning.light) return `${warning.light} Lux`;
    if (warning.shock) return `${warning.shock}g`;
    return '';
  };

  const formatRelativeTime = () => {
    const now = new Date();
    const diffInSeconds = Math.floor(
      (now.getTime() - loadTime.getTime()) / 1000
    );

    if (diffInSeconds < 60) {
      return 'less than a minute ago';
    }

    // Calculate minutes
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      // Make sure we're showing at least 1 minute if we're in this block
      const minutes = Math.max(1, diffInMinutes);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    }

    // Calculate hours
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    }

    // If more than 24 hours, show the time
    return loadTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };
  const hasUnseenAlerts = (warnings: Warning[]) => {
    return warnings.some((warning) => warning.seen === false);
  };

  useEffect(() => {
    setLastUpdatedText(formatRelativeTime());

    const timer = setInterval(() => {
      setLastUpdatedText(formatRelativeTime());
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#F5F5F5',
        borderRadius: '12px',
        padding: '16px',
        width: '100%',
        marginBottom: '6px',
        overflow: 'hidden',
        cursor: 'default',
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (rtlClick) {
          rtlClick(data);
        }
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0%',
            }}
          >
            RTL
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0%',
            }}
          >
            {rtlSerial}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <Tooltip title={`RTL Status: ${status}`}>
              <img
                src={getIcon(status)}
                alt="rtl tab icon"
                style={{ marginRight: '8px', height: '28px' }}
              />
            </Tooltip>
            {hasUnseenAlerts(warnings) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -4,
                  right: 4,
                  width: 8,
                  height: 8,
                  backgroundColor: '#DD3333',
                  borderRadius: '50%',
                  border: '1px solid white',
                }}
              />
            )}
          </Box>
          <IconButton
            onClick={handleToggle}
            size="small"
            sx={{ cursor: 'pointer' }}
          >
            <img
              src={expanded ? ChevronUpIcon : ChevronDownIcon}
              alt="chevron icon"
            />
          </IconButton>
        </Box>
      </Box>

      <Box
        ref={contentRef}
        sx={{
          maxHeight: expanded ? '500px' : '0px',
          opacity: expanded ? 1 : 0,
          transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: 'white',
            borderRadius: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: { md: 1, lg: 1 },
            }}
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0%',
              }}
            >
              Metrics
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0%',
                color: '#838383',

                textOverflow: 'ellipsis',
              }}
            >
              Updated {lastUpdatedText}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={1}>
            {/* Shock Box */}
            <Box
              sx={{
                'display': 'flex',
                'alignItems': 'center',
                'padding': '4px 4px',
                'borderRadius': '4px',
                'transition': 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={RTLShock}
                alt="lux icon"
                style={{ marginRight: '4px', height: '16px' }}
              />
              <Typography variant="body2">{data.shock} g</Typography>
            </Box>
            {/* Temperature Box */}
            <Box
              sx={{
                'display': 'flex',
                'alignItems': 'center',
                'padding': '4px 4px',
                'borderRadius': '4px',
                'transition': 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={RTLTemp}
                alt="temperature icon"
                style={{ marginRight: '4px', height: '16px' }}
              />
              <Typography variant="body2">{data.temperature}°C</Typography>
            </Box>

            {/* Humidity Box */}
            <Box
              sx={{
                'display': 'flex',
                'alignItems': 'center',
                'padding': '4px 4px',
                'borderRadius': '4px',
                'transition': 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={RTLHumidity}
                alt="humidity icon"
                style={{ marginRight: '4px', height: '16px' }}
              />
              <Typography variant="body2">{data.humidity}%</Typography>
            </Box>

            {/* Light Box */}
            <Box
              sx={{
                'display': 'flex',
                'alignItems': 'center',
                'padding': '4px 4px',
                'borderRadius': '4px',
                'transition': 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={RTLLux}
                alt="lux icon"
                style={{ marginRight: '4px', height: '16px' }}
              />
              <Typography variant="body2">{data.light} Lux</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: 'white',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0%',
              }}
            >
              History
            </Typography>
            <Button
              sx={{
                'cursor': 'pointer',
                'textTransform': 'none',
                'borderRadius': '8px',
                'backgroundColor': '#EEEEEE',
                'transition': 'background-color 0.5s ease',
                '&:hover': {
                  backgroundColor: '#DDDDDD',
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0%',
                  color: '#000000',
                }}
                onClick={handleWarningsReport}
              >
                View all
              </Typography>
            </Button>
          </Box>

          <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
            {warnings.length > 0 ? (
              warnings.map((warning) => (
                <Box
                  key={warning.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px',
                    borderBottom: '1px solid #EEEEEE',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ position: 'relative' }}>
                      <img
                        src={getWarningIcon(warning)}
                        alt="warning icon"
                        style={{ height: '16px', filter: 'brightness(0)' }}
                      />
                      {!warning.seen && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: -4,
                            left: -4,
                            width: 8,
                            height: 8,
                            backgroundColor: '#DD3333',
                            borderRadius: '50%',
                            border: '1px solid white',
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#DD3333',
                      }}
                    >
                      {formatWarningValue(warning)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      color: '#838383',
                    }}
                  >
                    {warning.timestamp}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  color: '#838383',
                  textAlign: 'center',
                  padding: '16px',
                }}
              >
                No recent warnings
              </Typography>
            )}
          </Box>
        </Box>
        {status === 'ALERT' ? (
          <Box>
            <Box
              sx={{
                'marginTop': '10px',
                'textAlign': 'center',
                'padding': '5px',
                'backgroundColor': '#DD3333',
                'color': 'white',
                'borderRadius': '6px',
                'cursor': 'pointer',
                'transition': 'background-color 0.5s ease',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'gap': '8px',
                '&:hover': {
                  backgroundColor: '#D63026',
                },
              }}
              onClick={handleViewFullReport}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0%',
                  color: 'white',
                }}
              >
                View Graph
              </Typography>
              <img
                src={ArrowRight}
                alt="arrow right icon"
                style={{ height: '16px' }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              'marginTop': '10px',
              'textAlign': 'center',
              'padding': '5px',
              'backgroundColor': '#EEEEEE',
              'borderRadius': '6px',
              'cursor': 'pointer',
              'transition': 'background-color 0.5s ease',
              '&:hover': {
                backgroundColor: '#DDDDDD',
              },
            }}
            onClick={handleViewFullReport}
          >
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0%',
              }}
            >
              View Full Report
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
});

RTLBox.displayName = 'RTLBox';

export default RTLBox;
