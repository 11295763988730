import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import mapHumidity from '../../../assets/icons/map-humidity.svg';
import mapLux from '../../../assets/icons/map-lux.svg';
import mapTemperature from '../../../assets/icons/map-temperature.svg';
import mapShock from '../../../assets/icons/map-shock.svg';
import mapTime from '../../../assets/icons/map-time.svg';

interface TooltipProps {
  rtlSerial: string;
  timestamp: string;
  shock?: string;
  temperature?: string;
  humidity?: string;
  lux?: string;
}

const MarkerTooltip: React.FC<TooltipProps> = ({
  rtlSerial,
  timestamp,
  shock,
  temperature,
  humidity,
  lux,
}) => {
  // Helper function to display dash for missing values
  const displayValue = (value: string | undefined): string => {
    return value ? value : '-';
  };

  // Helper functions for displaying values with units
  const displayTemperature = (value: string | undefined): string => {
    return value ? `${value}°C` : '-';
  };

  const displayHumidity = (value: string | undefined): string => {
    return value ? `${value}%` : '-';
  };

  const displayLux = (value: string | undefined): string => {
    return value ? `${value} lux` : '-';
  };

  // Format timestamp to separate date and time
  const formatDateTime = (
    isoString: string | undefined
  ): { date: string; time: string } => {
    if (!isoString) {
      return { date: '-', time: '-' };
    }

    try {
      const date = new Date(isoString);
      return {
        date: date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        time: date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
      };
    } catch {
      return { date: '-', time: '-' };
    }
  };

  const { date, time } = formatDateTime(timestamp);

  return (
    <div
      style={{
        backgroundColor: 'black',
        borderRadius: '4px',
        maxWidth: '138px',
        maxHeight: '176px',
        color: 'white',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        justifyContent={'center'}
      >
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0%',
          }}
        >
          RTL
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0%',
          }}
        >
          {displayValue(rtlSerial)}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={mapTime}
              alt="date"
              style={{ width: '16px', height: '16px' }}
            />
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0%',
                }}
              >
                {date}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0%',
                }}
              >
                {time}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={mapShock}
              alt="shock"
              style={{ width: '16px', height: '16px' }}
            />
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0%',
              }}
            >
              {displayValue(shock)}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={mapTemperature}
              alt="temperature"
              style={{ width: '16px', height: '16px' }}
            />
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0%',
              }}
            >
              {displayTemperature(temperature)}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={mapHumidity}
              alt="humidity"
              style={{ width: '16px', height: '16px' }}
            />
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0%',
              }}
            >
              {displayHumidity(humidity)}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={mapLux}
              alt="light"
              style={{ width: '16px', height: '16px' }}
            />
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0%',
              }}
            >
              {displayLux(lux)}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default MarkerTooltip;
