import {
  Box,
  Button,
  Dialog,
  Grid2,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { useTranslation } from 'react-i18next';

const MenuItemStyled = styled(MenuItem)`
  border-radius: 6px;
  width: 150px;
  &.Mui-selected {
    background-color: #eeeeee;
    color: #000;
  }
`;

enum RangeSelector {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
}

interface CalendarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onApplyDates: (from: Date, to: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ open, setOpen, onApplyDates }) => {
  const [selected, setSelected] = useState<{
    from: Date | undefined;
    to?: Date | undefined;
  }>({ from: undefined, to: undefined });

  const { t } = useTranslation();

  const [selectedRange, setSelectedRange] = useState<RangeSelector | null>(
    null
  );

  const handleRangeSelect = (range: RangeSelector) => {
    setSelectedRange(range);

    const today = new Date();
    if (range === RangeSelector.Today) {
      setSelected({ from: today, to: today });
    } else if (range === RangeSelector.Yesterday) {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      setSelected({ from: yesterday, to: yesterday });
    } else if (range === RangeSelector.ThisWeek) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      setSelected({ from: startOfWeek, to: today });
    } else if (range === RangeSelector.LastWeek) {
      const startOfLastWeek = new Date(today);
      startOfLastWeek.setDate(today.getDate() - today.getDay() - 7);
      const endOfLastWeek = new Date(startOfLastWeek);
      endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
      setSelected({ from: startOfLastWeek, to: endOfLastWeek });
    } else if (range === RangeSelector.ThisMonth) {
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      setSelected({ from: startOfMonth, to: today });
    } else if (range === RangeSelector.LastMonth) {
      const startOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      setSelected({ from: startOfLastMonth, to: endOfLastMonth });
    } else if (range === RangeSelector.ThisYear) {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      setSelected({ from: startOfYear, to: today });
    } else if (range === RangeSelector.LastYear) {
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
      const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
      setSelected({ from: startOfLastYear, to: endOfLastYear });
    }
  };

  const handleOnDayClick = () => {
    setSelectedRange(null);
  };

  const handleApplyDates = () => {
    setOpen(false);
    onApplyDates(selected.from as Date, selected.to as Date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '891px',
          width: '891px',
          border: '1px solid #E5E5E5',
          borderRadius: '16px',
        },
      }}
    >
      <Grid2
        container
        sx={{
          display: 'flex',
          padding: '0px 0px 0px 16px',
        }}
      >
        <Grid2
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid rgba(131, 131, 131, 0.4)',
            padding: '0 16px 16px 0',
            gap: '8px',
          }}
        >
          <MenuItemStyled
            sx={{
              marginTop: '16px',
            }}
            selected={selectedRange === RangeSelector.Today}
            onClick={() => handleRangeSelect(RangeSelector.Today)}
          >
            {t('Today')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.Yesterday}
            onClick={() => handleRangeSelect(RangeSelector.Yesterday)}
          >
            {t('Yesterday')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.ThisWeek}
            onClick={() => handleRangeSelect(RangeSelector.ThisWeek)}
          >
            {t('This Week')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.LastWeek}
            onClick={() => handleRangeSelect(RangeSelector.LastWeek)}
          >
            {t('Last Week')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.ThisMonth}
            onClick={() => handleRangeSelect(RangeSelector.ThisMonth)}
          >
            {t('This Month')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.LastMonth}
            onClick={() => handleRangeSelect(RangeSelector.LastMonth)}
          >
            {t('Last Month')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.ThisYear}
            onClick={() => handleRangeSelect(RangeSelector.ThisYear)}
          >
            {t('This Year')}
          </MenuItemStyled>
          <MenuItemStyled
            selected={selectedRange === RangeSelector.LastYear}
            onClick={() => handleRangeSelect(RangeSelector.LastYear)}
          >
            {t('Last Year')}
          </MenuItemStyled>
        </Grid2>
        <Grid2
          container
          sx={{
            flexDirection: 'column',
          }}
        >
          <Grid2
            sx={{
              padding: '16px',
            }}
          >
            <DayPicker
              mode="range"
              onDayClick={handleOnDayClick}
              numberOfMonths={2}
              showOutsideDays
              selected={selected}
              onSelect={setSelected}
              required
            />
          </Grid2>
          <Grid2
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '16px',
              padding: '16px 16px 16px 16px',
              width: '100%',
              borderTop: '1px solid rgba(131, 131, 131, 0.4)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                color: '#000000',
                padding: '8px 16px',
              }}
            >
              <Typography>
                {selected.from
                  ? selected.from.toLocaleDateString('en-US', {
                      month: 'short',
                      day: '2-digit',
                      year: 'numeric',
                    })
                  : 'Start Date'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>-</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                color: '#000000',
                padding: '8px 16px',
              }}
            >
              <Typography>
                {selected.to
                  ? selected.to.toLocaleDateString('en-US', {
                      month: 'short',
                      day: '2-digit',
                      year: 'numeric',
                    })
                  : 'End Date'}
              </Typography>
            </Box>
            <Box flexGrow={1} />
            <Button
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                color: '#000000',
                minWidth: '100px',
                textTransform: 'none',
              }}
            >
              <Typography>{t('Cancel')}</Typography>
            </Button>
            <Button
              onClick={handleApplyDates}
              disabled={!selected.from || !selected.to}
              sx={{
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                minWidth: '100px',
                color: '#fff',
                backgroundColor:
                  !selected.from || !selected.to ? '#aaa' : '#000',
                textTransform: 'none',
              }}
            >
              <Typography>{t('Apply')}</Typography>
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Dialog>
  );
};

export default Calendar;
