import React, { useEffect, useState } from 'react';
import { Marker, InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { RtlLocation } from '../../../interfaces/Map/mapMarkers';
import CustomPolyline from './CustomPolyline';
import MarkerTooltip from './MarkerToolTip';
import RtlLatest from '../../../assets/icons/map-rtl-latest.svg';

interface HistoricalMarkersProps {
  historicalMarkers: RtlLocation[];
  rtlSerial: string;
}

const HistoricalMarkers: React.FC<HistoricalMarkersProps> = ({
  historicalMarkers,
  rtlSerial,
}) => {
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
  const map = useMap();
  const [markers, setMarkers] = useState<RtlLocation[]>([]);
  const [coordinates, setCoordinates] = useState<
    Array<{ lat: number; lng: number }>
  >([]);

  useEffect(() => {
    if (!map) return;

    const listener = map.addListener('click', () => {
      setSelectedMarker(null);
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map]);

  useEffect(() => {
    if (!historicalMarkers?.length) return;

    const sorted = [...historicalMarkers].sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );

    const validMarkers = sorted.filter(
      (marker) =>
        marker.latitude &&
        marker.longitude &&
        !isNaN(parseFloat(marker.latitude)) &&
        !isNaN(parseFloat(marker.longitude))
    );

    const coords = validMarkers.map((marker) => ({
      lat: parseFloat(marker.latitude),
      lng: parseFloat(marker.longitude),
    }));

    setMarkers(sorted);
    setCoordinates(coords);
  }, [historicalMarkers]);

  return (
    <>
      {markers.length > 0 ? (
        <>
          {coordinates.length > 0 && (
            <CustomPolyline
              path={coordinates}
              color="#DD3333"
              opacity={0.9}
              weight={2}
            />
          )}

          {markers
            .filter(
              (marker) =>
                marker.latitude &&
                marker.longitude &&
                !isNaN(parseFloat(marker.latitude)) &&
                !isNaN(parseFloat(marker.longitude))
            )
            .map((marker, index) => (
              <React.Fragment key={`${marker.timestamp}-${index}`}>
                <Marker
                  position={{
                    lat: parseFloat(marker.latitude),
                    lng: parseFloat(marker.longitude),
                  }}
                  onClick={() =>
                    setSelectedMarker(
                      selectedMarker === marker.timestamp
                        ? null
                        : marker.timestamp
                    )
                  }
                  icon={
                    index === 0 // Most recent marker (since sorted newest first)
                      ? {
                          url: RtlLatest,
                          scaledSize: new google.maps.Size(53, 53),
                          anchor: new google.maps.Point(26, 26),
                          origin: new google.maps.Point(0, 0),
                        }
                      : {
                          path: google.maps.SymbolPath.CIRCLE,
                          scale: 6,
                          fillColor: '#DD3333',
                          fillOpacity: 1,
                          strokeColor: '#FFFFFF',
                          strokeWeight: 0,
                        }
                  }
                />
                {selectedMarker === marker.timestamp && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(marker.latitude),
                      lng: parseFloat(marker.longitude),
                    }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <MarkerTooltip
                      rtlSerial={rtlSerial}
                      timestamp={marker.timestamp}
                      shock={marker.shock}
                      temperature={marker.temp}
                      humidity={marker.humidity}
                      lux={marker.light}
                    />
                  </InfoWindow>
                )}
              </React.Fragment>
            ))}
        </>
      ) : null}
    </>
  );
};

export default HistoricalMarkers;
