import React, { useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

interface CustomPolylineProps {
  path: google.maps.LatLngLiteral[];
  color?: string;
  opacity?: number;
  weight?: number;
}

const CustomPolyline: React.FC<CustomPolylineProps> = ({
  path,
  color = '#FF4444',
  opacity = 0.6,
  weight = 2,
}) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const polyline = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: opacity,
      strokeWeight: weight,
    });

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, path, color, opacity, weight]);

  return null;
};

export default CustomPolyline;
