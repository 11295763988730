import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import { GetCompanySealsRequest } from '../../../interfaces/Seals/Request/getCompanySeals';
import { GetCompanySealsResponse } from '../../../interfaces/Seals/Response/getCompanySeals';
import useDebounce from '../../../utils/useDebounce';

import { useCookies } from 'react-cookie';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as UnassignIcon } from '../../../assets/icons/circle-xmark.svg';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';
import { ReactComponent as CradleIcon } from '../../../assets/icons/up-from-dotted-line.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons/route.svg';
import { ReactComponent as BanSolid } from '../../../assets/icons/ban-solid.svg';
import { CompanyVehiclesResponse } from '../../../interfaces/Vehicles/Response/companyVehicles';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import AssignSealToVehicleModal from './Components/AssignSealToVehicleModal';
import Locking from '../../../components/Locking/Locking';
import ConfirmSeal from './Components/ConfirmSeal';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnassignSealModal from './Components/UnassignSealModal';
import UnlockCradleModal from './Components/UnlockCradleModal';
import {
  getCompanySeals,
  assignSealToVehicle,
  unassignSealFromVehicle,
  addCommand,
  deactivateSeal,
} from '../../../services/Api/apiSeal';
import { getCompanyVehicles } from '../../../services/Api/apiVehicle';
import { CompanyVehiclesRequest } from '../../../interfaces/Vehicles/Request/companyVehicles';
import DeactivateSealModal from './Components/DeactivateSealModal';

function Seals() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [seals, setSeals] = useState<GetCompanySealsResponse[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] =
    useState<GetCompanySealsResponse | null>();
  const { t } = useTranslation();

  const columnFieldMapping = {
    'iMSeal ID': 'serial_number',
    'Driver': 'driver_full_name',
    'Vehicle': 'vehicle_alias',
    'Status': 'status',
    'Associated RTLs': 'associated_rtls',
    'Battery': 'battery_percentage',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['12%', '16%', '16%', '35%'];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Assign to Vehicle',
      icon: <TruckIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenAssignToVehicle(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
      },
      disabled:
        row.vehicle_id !== '' ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
    },
    {
      label: 'Unassign iMSeal',
      icon: <UnassignIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenUnassignSeal(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
      },
      disabled:
        row.vehicle_id !== '' ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedRow(row as unknown as GetCompanySealsResponse);
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
      disabled: row.status !== 'IN_USE',
    },
    {
      label: 'Edit/Define Route',
      icon: <RouteIcon style={{ width: '20px', marginRight: '8px' }} />,
      onClick: () => navigate(`/seals/${row.vehicle_id}`),
      disabled:
        !row.vehicle_id ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
    },
    {
      label:
        row.lock_state === 'LEFT_UNLOCKED'
          ? 'Lock Left'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Left',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(true);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled:
        row.lock_state === 'PROCESSING' ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
      isLock: true,
    },
    {
      label:
        row.lock_state === 'RIGHT_UNLOCKED'
          ? 'Lock Right'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Right',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(false);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled:
        row.lock_state === 'PROCESSING' ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
      isLock: true,
    },
    {
      label:
        row.lock_state === 'CRADLE_UNLOCKED'
          ? 'Lock Cradle'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Cradle',
      icon: <CradleIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenUnlockCradle(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
        setSelectedSeal(row.serial_number as string);
      },
      disabled:
        row.lock_state === 'PROCESSING' ||
        row.status === 'DEACTIVATED' ||
        row.status !== 'IN_USE',
    },
    {
      label: 'Deactivate iMSeal',
      icon: <BanSolid style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenDeactivateSeal(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
        setSelectedSeal(row.serial_number as string);
      },
      disabled: row.status !== 'IN_USE',
    },
  ];

  const handlePlusIconClick = () => {
    setOpenAddNew(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const fetchSeals = async () => {
      const payload: GetCompanySealsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      try {
        const data = await getCompanySeals(payload);
        const formattedSeals = formatRTLsForDisplay(data.seals);
        setSeals(formattedSeals);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchSeals);
        setSnackbarOpen(true);
      }
    };

    fetchSeals();
  }, [debouncedSearchValue, cookies.user.company_id]);

  const refetchInfo = async () => {
    const fetchSeals = async () => {
      const payload: GetCompanySealsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: page,
        refetch: true,
      };
      try {
        const data = await getCompanySeals(payload);
        const formattedSeals = formatRTLsForDisplay(data.seals);
        setSeals(formattedSeals);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => refetchInfo);
        setSnackbarOpen(true);
      }
    };
    const fetchVehicles = async () => {
      const payload: CompanyVehiclesRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        exclude_seals: true,
        page: -1,
        refetch: true,
      };
      try {
        const response = await getCompanyVehicles(payload);
        setVehicles(response.vehicles);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => refetchInfo);
        setSnackbarOpen(true);
      }
    };

    fetchVehicles();
    fetchSeals();
  };

  const [openAddNew, setOpenAddNew] = useState(false);

  // Methods pertaining to assign to vehicle seal

  const [openAssignToVehicle, setOpenAssignToVehicle] = useState(false);
  const [vehicles, setVehicles] = useState<CompanyVehiclesResponse[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState({
    vehicle: ' ',
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      const payload: CompanyVehiclesRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        exclude_seals: true,
        page: -1,
        refetch: true,
      };
      try {
        const response = await getCompanyVehicles(payload);
        setVehicles(response.vehicles);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchVehicles);
        setSnackbarOpen(true);
      }
    };
    fetchVehicles();
  }, [cookies.user.company_id]);

  const handleAssignToVehicle = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedRow?.id as string,
        vehicle_id: selectedVehicle.vehicle,
      };

      await assignSealToVehicle(payload);

      refetchInfo();
      setSelectedVehicle({ vehicle: ' ' });
      setOpenAssignToVehicle(false);
      setSnackbarMessage('Seal Assigned to Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Methods pertaining to unassign seal

  const [openUnassignSeal, setOpenUnassignSeal] = useState(false);

  const handleUnassignSeal = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedRow?.id as string,
        vehicle_id: selectedRow?.vehicle_id as string,
      };

      await unassignSealFromVehicle(payload);

      refetchInfo();
      setSelectedVehicle({ vehicle: ' ' });
      setOpenUnassignSeal(false);
      setSnackbarMessage('Seal Unassigned from Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState('');

  const [openUnlockCradle, setOpenUnlockCradle] = useState(false);

  //   const handleUnlockCradle = async () => {
  //     try {
  //       const payload = {
  //         actioned_by:
  //           cookies.user.first_name +
  //           ' ' +
  //           cookies.user.last_name +
  //           ' - ' +
  //           cookies.role,
  //         company_id: cookies.user.company_id,
  //         seal_serial_number: selectedRow?.serial_number as string,
  //         command_code:
  //           selectedRow?.lock_state === 'CRADLE_UNLOCKED' ? 'LK' : 'UC',
  //       };

  //       const response = await addCommand(payload);

  //       setOpenUnlockCradle(false);

  //       if (response.statusCode === 400) {
  //         setSnackbarMessage(response.message);
  //         setSnackbarSeverity('error');
  //         setSnackbarOpen(true);
  //       } else {
  //         setSnackbarMessage(
  //           t(response.data.message) +
  //             ' - ' +
  //             t('Expected time to complete in seconds') +
  //             ': ' +
  //             Math.round(response.data.expectedPingResponseTime)
  //         );
  //         setSnackbarSeverity('success');
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       setSnackbarMessage((error as Error).message);
  //       setSnackbarSeverity('error');
  //       setSnackbarOpen(true);
  //     }
  //   };

  const handleUnlockCradle = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_serial_number: selectedRow?.serial_number as string,
        command_code:
          selectedRow?.lock_state === 'CRADLE_UNLOCKED' ? 'LK' : 'UC',
      };

      const response = await addCommand(payload);
      setOpenUnlockCradle(false);

      setSnackbarMessage(
        t(response.message) +
          ' - ' +
          t('Expected time to complete in seconds') +
          ': ' +
          Math.round(response.expectedPingResponseTime)
      );
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error: unknown) {
      const errorMessage = (error as Error).message;
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setOpenUnlockCradle(false);
    }
  };

  const [openDeactivateSeal, setOpenDeactivateSeal] = useState(false);

  const handleDeactivateSeal = async () => {
    try {
      const payload = {
        user_id: cookies.user.id,
        seal_id: selectedRow?.id as string,
      };

      await deactivateSeal(payload);

      refetchInfo();
      setOpenDeactivateSeal(false);
      setSnackbarMessage('Seal Deactivated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = async () => {
    const payload: GetCompanySealsRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getCompanySeals(payload);
      const formattedNewSeals = formatRTLsForDisplay(data.seals);

      setSeals((prevSeals) => {
        const updatedSeals = prevSeals.filter(
          (prevSeal) =>
            !formattedNewSeals.some((newSeal) => newSeal.id === prevSeal.id)
        );
        return [...updatedSeals, ...formattedNewSeals];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  const formatRTLsForDisplay = (seals: GetCompanySealsResponse[]) => {
    return seals.map((seal) => {
      const rtlDisplay =
        seal.rtl && seal.rtl.length > 0
          ? seal.rtl.map((rtl) => rtl.rtl_alias || rtl.rtl_serial).join(', ')
          : '';

      return {
        ...seal,
        associated_rtls: rtlDisplay,
      };
    });
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('iMSeal Locks')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          searchValue={searchValue}
          columns={columns}
          data={seals}
          hasMore={hasMore}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
        />
      </Grid2>

      <AssignSealToVehicleModal
        open={openAssignToVehicle}
        onClose={() => setOpenAssignToVehicle(false)}
        sealDetails={selectedRow || {}}
        vehicles={vehicles}
        formValues={selectedVehicle}
        setFormValues={setSelectedVehicle}
        onSubmit={handleAssignToVehicle}
      />

      <UnassignSealModal
        open={openUnassignSeal}
        onClose={() => setOpenUnassignSeal(false)}
        onSubmit={handleUnassignSeal}
      />

      <ConfirmSeal
        open={openAddNew}
        onClose={() => setOpenAddNew(false)}
        refetchInfo={refetchInfo}
      />

      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal}
        company_id={cookies.user.company_id}
      />

      <UnlockCradleModal
        open={openUnlockCradle}
        current_state={selectedRow?.lock_state !== 'CRADLE_UNLOCKED'}
        onClose={() => setOpenUnlockCradle(false)}
        serial_number={selectedSeal}
        onSubmit={handleUnlockCradle}
      />

      <DeactivateSealModal
        open={openDeactivateSeal}
        onClose={() => setOpenDeactivateSeal(false)}
        onSubmit={handleDeactivateSeal}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Seals;
