import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  TooltipItem,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Box, Typography } from '@mui/material';
import ChartShimmer from './ChartShimmer';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  annotationPlugin
);

interface ChartProps {
  data: Chart_Data | null;
  activeMetric: 'temperature' | 'humidity' | 'light' | 'shock';
  profileSettings?: {
    temperature_upper_range: number;
    temperature_lower_range: number;
    humidity_upper_range: number;
    humidity_lower_range: number;
    light_upper_range: number;
    light_lower_range: number;
    shock_upper_range: number;
    shock_lower_range: number;
  };
  isLoading?: boolean;
}

interface HourlyData {
  hour: string;
  date?: string;
  Temperature: string;
  Humidity: string;
  Light: string;
  Shock: string;
  Tilt_x: string | undefined;
  Tilt_y: string | undefined;
  Tilt_z: string | undefined;
}

interface Chart_Data {
  HourlyData: HourlyData[];
}

interface LineAnnotation {
  type: 'line';
  yMin: number;
  yMax: number;
  borderColor: string;
  borderWidth: number;
  borderDash: number[];
  label?: {
    content: string;
    position: 'start' | 'end' | 'center';
    display: boolean;
    color?: string;
    backgroundColor?: string;
  };
}

const getYAxisTitle = (
  activeMetric: 'temperature' | 'humidity' | 'light' | 'shock'
) => {
  switch (activeMetric) {
    case 'temperature':
      return 'Temperature (°C)';
    case 'humidity':
      return 'Humidity (%)';
    case 'light':
      return 'Light Level (Lux)';
    case 'shock':
      return 'Shock (G)';
    default:
      return '';
  }
};

const isEmptyData = (data: Chart_Data | null): boolean => {
  return !data || !data.HourlyData || data.HourlyData.length === 0;
};

const getOptions = (
  activeMetric: 'temperature' | 'humidity' | 'light' | 'shock',
  chartData: Chart_Data,
  profileSettings?: {
    temperature_upper_range: number;
    temperature_lower_range: number;
    humidity_upper_range: number;
    humidity_lower_range: number;
    light_upper_range: number;
    light_lower_range: number;
    shock_upper_range: number;
    shock_lower_range: number;
  }
) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems: TooltipItem<'line'>[]) => {
            if (!tooltipItems || tooltipItems.length === 0) return '';

            const dataIndex = tooltipItems[0].dataIndex;

            if (dataIndex >= chartData.HourlyData.length) {
              return '';
            }

            const hourlyData = chartData.HourlyData[dataIndex];

            if (hourlyData.date) {
              return `${hourlyData.date} ${hourlyData.hour}`;
            }

            const timeString = hourlyData.hour;
            const currentDate = new Date().toLocaleDateString();

            return timeString ? `${currentDate} ${timeString}` : currentDate;
          },
          label: (context: TooltipItem<'line'>) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;

            if (label === 'Temperature') {
              return `${label}: ${value}°C`;
            } else if (label === 'Humidity') {
              return `${label}: ${value}%`;
            } else if (label === 'Light') {
              return `${label}: ${value} Lux`;
            } else if (label === 'Shock') {
              return `${label}: ${value}`;
            }

            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          boxWidth: 20,
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          color: '#C7C7C7',
        },
      },
      annotation: {
        annotations: {},
      },
    },
    scales: {
      x: {
        grid: {
          color: '#C7C7C7',
          drawBorder: false,
        },
        ticks: {
          color: '#C7C7C7',
        },
        border: {
          display: false,
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: getYAxisTitle(activeMetric),
          color: '#C7C7C7',
        },
        grid: {
          color: '#C7C7C7',
          drawBorder: false,
        },
        ticks: {
          color: '#C7C7C7',
        },
        border: {
          display: false,
        },
      },
    },
  };

  const lowerRefLine: Partial<LineAnnotation> = {
    type: 'line',
    borderColor: 'rgba(255, 99, 132, 0.75)',
    borderWidth: 2,
    borderDash: [5, 5],
  };

  const upperRefLine: Partial<LineAnnotation> = {
    type: 'line',
    borderColor: 'rgba(255, 0, 0, 0.75)',
    borderWidth: 2,
    borderDash: [5, 5],
  };

  const annotations: Record<string, Partial<LineAnnotation>> = {};

  if (activeMetric === 'temperature') {
    const lowerRange = profileSettings?.temperature_lower_range ?? 15;
    const upperRange = profileSettings?.temperature_upper_range ?? 32;

    annotations.tempMin = {
      ...lowerRefLine,
      yMin: lowerRange,
      yMax: lowerRange,
      label: {
        content: `Min Temp: ${lowerRange}°C`,
        position: 'start',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 99, 132, 0.75)',
      },
    };

    annotations.tempMax = {
      ...upperRefLine,
      yMin: upperRange,
      yMax: upperRange,
      label: {
        content: `Max Temp: ${upperRange}°C`,
        position: 'end',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 0, 0, 0.75)',
      },
    };
  } else if (activeMetric === 'humidity') {
    const lowerRange = profileSettings?.humidity_lower_range ?? 20;
    const upperRange = profileSettings?.humidity_upper_range ?? 80;

    annotations.humidityMin = {
      ...lowerRefLine,
      yMin: lowerRange,
      yMax: lowerRange,
      label: {
        content: `Min Humidity: ${lowerRange}%`,
        position: 'start',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 99, 132, 0.75)',
      },
    };

    annotations.humidityMax = {
      ...upperRefLine,
      yMin: upperRange,
      yMax: upperRange,
      label: {
        content: `Max Humidity: ${upperRange}%`,
        position: 'end',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 0, 0, 0.75)',
      },
    };
  } else if (activeMetric === 'light') {
    const lowerRange = profileSettings?.light_lower_range ?? 100;
    const upperRange = profileSettings?.light_upper_range ?? 1000;

    annotations.lightMin = {
      ...lowerRefLine,
      yMin: lowerRange,
      yMax: lowerRange,
      label: {
        content: `Min Light: ${lowerRange} Lux`,
        position: 'start',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 99, 132, 0.75)',
      },
    };

    annotations.lightMax = {
      ...upperRefLine,
      yMin: upperRange,
      yMax: upperRange,
      label: {
        content: `Max Light: ${upperRange} Lux`,
        position: 'end',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 0, 0, 0.75)',
      },
    };
  } else if (activeMetric === 'shock') {
    const upperRange = profileSettings?.shock_upper_range ?? 1;

    annotations.shockThreshold = {
      ...upperRefLine,
      yMin: upperRange,
      yMax: upperRange,
      label: {
        content: `Shock Threshold: ${upperRange}`,
        position: 'start',
        display: true,
        color: 'white',
        backgroundColor: 'rgba(255, 0, 0, 0.75)',
      },
    };
  }

  (
    options.plugins.annotation as {
      annotations: Record<string, Partial<LineAnnotation>>;
    }
  ).annotations = annotations;

  return options;
};

const RTLDataChart: React.FC<ChartProps> = ({
  data,
  activeMetric,
  profileSettings,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <ChartShimmer height="400px" />;
  }

  if (isEmptyData(data)) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          border: '1px dashed #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ maxWidth: '80%' }}
        >
          No data available for {activeMetric}. Please select a different metric
          or a different date range.
        </Typography>
      </Box>
    );
  }

  const chartData = data!;

  try {
    const allDatasets = [
      {
        label: 'Temperature',
        data: chartData.HourlyData.map((item) => parseFloat(item.Temperature)),
        borderColor: '#007AFF',
        backgroundColor: '#007AFF',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Humidity',
        data: chartData.HourlyData.map((item) => parseFloat(item.Humidity)),
        borderColor: '#86BE02',
        backgroundColor: '#86BE02',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Light',
        data: chartData.HourlyData.map((item) => parseFloat(item.Light)),
        borderColor: '#DD3333',
        backgroundColor: '#DD3333',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Shock',
        data: chartData.HourlyData.map((item) => parseFloat(item.Shock || '0')),
        borderColor: '#000000',
        backgroundColor: '#000000',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Tilt X',
        data: chartData.HourlyData.map((item) => parseFloat(item.Tilt_x || '0')),
        borderColor: '#FF5733',
        backgroundColor: '#FF5733',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Tilt Y',
        data: chartData.HourlyData.map((item) => parseFloat(item.Tilt_y || '0')),
        borderColor: '#33FF57',
        backgroundColor: '#33FF57',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Tilt Z',
        data: chartData.HourlyData.map((item) => parseFloat(item.Tilt_z || '0')),
        borderColor: '#3357FF',
        backgroundColor: '#3357FF',
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 2,
      },
    ];

    const filteredDataset =
      activeMetric === 'shock'
        ? allDatasets.filter(
            (dataset) =>
              dataset.label === 'Shock' ||
              dataset.label === 'Tilt X' ||
              dataset.label === 'Tilt Y' ||
              dataset.label === 'Tilt Z'
          )
        : allDatasets.find(
            (dataset) => dataset.label.toLowerCase() === activeMetric
          );

          const chartDataConfig = {
            labels: chartData.HourlyData.map((item) => item.hour),
            datasets: (Array.isArray(filteredDataset) ? filteredDataset : [filteredDataset])
              .filter(Boolean)
          } as ChartData<'line', number[], string>;

    const options = getOptions(activeMetric, chartData, profileSettings);

    return (
      <div
        style={{
          width: '100%',
          height: '400px',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <Line options={options} data={chartDataConfig} />
      </div>
    );
  } catch (error) {
    console.error('Error rendering chart:', error);
    return (
      <Box
        sx={{
          width: '100%',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          border: '1px dashed #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ maxWidth: '80%' }}
        >
          {t(
            'Error displaying chart for {{activeMetric}}. Please try again or select a different metric.',
            { activeMetric }
          )}
        </Typography>
      </Box>
    );
  }
};

export default RTLDataChart;