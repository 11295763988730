import { Box, Grid2, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import RTLDataChart from './Components/RTLDataChart';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-chart.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import Calendar from '../../../components/Calendar/Calendar';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import {
  fetchRtlGraphData,
  formatDateForApi,
  processRtlDataForChart,
} from '../../../services/Api/apiGraphData';
import { useSSE } from '../../../../src/components/SSE/SSEHook';
import env from 'react-dotenv';

type HumitureData = {
  timestamp: number;
  data: Array<{
    'time': string;
    'temp 1': number;
    'temp 2': number;
    'humidity 1': number;
    'humidity 2': number;
  }>;
  type: 'humiture';
  device_id: string;
};

type SensorSetData = {
  timestamp: number;
  data: Array<{
    time: string;
    shock: number;
    light: number;
    tilt_x: number | undefined;
    tilt_y: number | undefined;
    tilt_z: number | undefined;
  }>;
  type: 'sensor_set_1';
  device_id: string;
};

type RtlDataPoint = {
  time?: string;
  date?: string;
  hour?: string;
  temperature?: number;
  humidity?: number;
  light?: number;
  shock?: number;
};

type ProcessedChartData = {
  HourlyData: RtlDataPoint[];
};

function ViewDataPage() {
  const RTL_SERVICE_URL = env.REACT_APP_RTL_SERVICE_URL;

  const location = useLocation();
  const { rtlData } = location.state || {};
  const { t } = useTranslation();
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [activeMetric, setActiveMetric] = useState<
    'temperature' | 'humidity' | 'light' | 'shock'
  >('temperature');
  const activeMetricRef = useRef(activeMetric);
  useEffect(() => {
    activeMetricRef.current = activeMetric;
  }, [activeMetric]);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<{
    from: Date | undefined;
    to?: Date | undefined;
  }>({ from: new Date(), to: new Date() });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chartData, setChartData] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profileSettings, setProfileSettings] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [cookies] = useCookies(['user', 'super_user', 'token']);
  

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [isLiveUpdatesEnabled, setIsLiveUpdatesEnabled] = useState(false);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Replace the existing isDateRangeToday function with this updated version
  const isDateRangeToday = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const to = selectedDate.to || selectedDate.from;
    if (!selectedDate.from || !to) return true; // Default to true if no dates selected

    const toDate = new Date(to);
    toDate.setHours(0, 0, 0, 0);

    // Check if the date range includes today (end date is today or later)
    return toDate.getTime() >= today.getTime();
  }, [selectedDate]);

  // The handleApplyDates function remains the same as it already uses isDateRangeToday indirectly

  const fetchGraphData = useCallback(async () => {
    if (!rtlData?.id) {
      console.error('No RTL ID available');
      return;
    }

    setIsLoading(true);
    try {
      const endDate = new Date(selectedDate.to || new Date());
      endDate.setDate(endDate.getDate() + 1);
      let userId = '';
      if (cookies.user) {
        const userData = cookies.user;
        if (userData.id) {
          userId = userData.id;
        }
      }

      const response = await fetchRtlGraphData({
        rtl_id: rtlData.id,
        user_id: userId,
        start_time: formatDateForApi(selectedDate.from || new Date()),
        end_time: formatDateForApi(endDate),
      });

      if (!response.data.data.data || response.data.data.data.length === 0) {
        setChartData({ HourlyData: [] });
      } else {
        const processedData = processRtlDataForChart(
          response.data.data.data,
          activeMetric
        );
        setChartData(processedData);
      }

      if (response.data.profile) {
        setProfileSettings({
          temperature_upper_range:
            response.data.profile.temperature_upper_range,
          temperature_lower_range:
            response.data.profile.temperature_lower_range,
          humidity_upper_range: response.data.profile.humidity_upper_range,
          humidity_lower_range: response.data.profile.humidity_lower_range,
          light_upper_range: response.data.profile.light_upper_range,
          light_lower_range: response.data.profile.light_lower_range,
          shock_upper_range: response.data.profile.shock_upper_range,
          shock_lower_range: response.data.profile.shock_lower_range,
        });
      }

      setLastUpdatedTime(getCurrentTime());
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching RTL graph data:', error);
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setChartData({ HourlyData: [] });
    } finally {
      setTimeout(() => setIsLoading(false), 800);
    }
  }, [rtlData?.id, selectedDate.from, selectedDate.to, activeMetric]);

  useEffect(() => {
    if (
      rtlData?.id &&
      (!dataFetched || (selectedDate.from && selectedDate.to))
    ) {
      fetchGraphData();
    }
  }, [rtlData, fetchGraphData, dataFetched, selectedDate]);

  const handleMetricClick = (
    metric: 'temperature' | 'humidity' | 'light' | 'shock'
  ) => {
    setActiveMetric(metric);
  };

  const handleSSEMessage = useCallback(
    (newData: {
      timestamp: number;
      data: object[];
      type: string;
      device_id: string;
    }) => {
      try {
        let parsedData: HumitureData | SensorSetData;

        if (typeof newData === 'string') {
          try {
            parsedData = JSON.parse((newData as string).replace(/'/g, '"'));
          } catch (parseError) {
            console.error('Error parsing SSE string data:', parseError);
            throw parseError;
          }
        } else {
          parsedData = newData as HumitureData | SensorSetData;
        }

        if (!isLiveUpdatesEnabled) {
          return;
        }

        setChartData((prevData: ProcessedChartData) => {
          const previousData = prevData?.HourlyData ?? [];

          try {
            const sortedNewData = [...parsedData.data].sort(
              (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
            );

            const formattedDataPoints: RtlDataPoint[] = sortedNewData.map(
              (dataPoint) => {
                const [date, time] = dataPoint.time.split(' ');
                if (parsedData.type === 'humiture') {
                  return {
                    date,
                    hour: time,
                    Temperature:
                      Math.max(
                        (dataPoint as HumitureData['data'][0])['temp 1'] ?? 0,
                        (dataPoint as HumitureData['data'][0])['temp 2'] ?? 0
                      ) / 10,
                    Humidity: Math.max(
                      (dataPoint as HumitureData['data'][0])['humidity 1'] ?? 0,
                      (dataPoint as HumitureData['data'][0])['humidity 2'] ?? 0
                    ),
                  };
                } else {
                  return {
                    date,
                    hour: time,
                    Light: (dataPoint as SensorSetData['data'][0]).light ?? 0,
                    Shock: (dataPoint as SensorSetData['data'][0]).shock ?? 0,
                    Tilt_x: (dataPoint as SensorSetData['data'][0]).tilt_x ?? 0,
                    Tilt_y: (dataPoint as SensorSetData['data'][0]).tilt_y ?? 0,
                    Tilt_z: (dataPoint as SensorSetData['data'][0]).tilt_z ?? 0,
                  };
                }
              }
            );

            const currentMetric = activeMetricRef.current;

            if (
              currentMetric === 'temperature' &&
              !formattedDataPoints.some(
                (dataPoint) => 'Temperature' in dataPoint
              )
            ) {
              return prevData;
            }
            if (
              currentMetric === 'humidity' &&
              !formattedDataPoints.some((dataPoint) => 'Humidity' in dataPoint)
            ) {
              return prevData;
            }
            if (
              currentMetric === 'light' &&
              !formattedDataPoints.some((dataPoint) => 'Light' in dataPoint)
            ) {
              return prevData;
            }
            if (
              currentMetric === 'shock' &&
              !formattedDataPoints.some((dataPoint) => 'Shock' in dataPoint)
            ) {
              return prevData;
            }
            if (
              currentMetric === 'shock' &&
              !formattedDataPoints.some((dataPoint) => 'Tilt_x' in dataPoint)
            ) {
              return prevData;
            }
            if (
              currentMetric === 'shock' &&
              !formattedDataPoints.some((dataPoint) => 'Tilt_y' in dataPoint)
            ) {
              return prevData;
            }
            if (
              currentMetric === 'shock' &&
              !formattedDataPoints.some((dataPoint) => 'Tilt_z' in dataPoint)
            ) {
              return prevData;
            }

            const mergedData: RtlDataPoint[] = [
              ...previousData.filter(
                (dataPoint): dataPoint is RtlDataPoint =>
                  'date' in dataPoint && 'hour' in dataPoint
              ),
              ...formattedDataPoints,
            ];

            return { HourlyData: mergedData };
          } catch (error) {
            console.error('Error processing data points:', error);
            return prevData;
          }
        });

        setLastUpdatedTime(getCurrentTime());
      } catch (error) {
        console.error('Error parsing SSE data:', error);
        setSnackbarMessage('Error processing real-time data');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    },
    [isLiveUpdatesEnabled]
  );

  useSSE({
    url: `${RTL_SERVICE_URL}/sse?device_ids=${rtlData?.rtl_id}&channel=data`,
    onMessage: handleSSEMessage,
    onError: (error) => {
      console.error('SSE Connection error:', error);
      // setSnackbarMessage('Real-time data connection lost');
      // setSnackbarSeverity('error');
      // setSnackbarOpen(true);
    },
  });

  const handleDownload = () => {
    try {
      if (
        !chartData ||
        !chartData.HourlyData ||
        chartData.HourlyData.length === 0
      ) {
        setSnackbarMessage('No data available to download');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const canvas = document.querySelector('canvas');
      if (!canvas) {
        console.error('Chart canvas not found');
        return;
      }

      const link = document.createElement('a');
      link.download = `${rtlData?.rtl_id || 'chart'}_${activeMetric}_${
        new Date().toISOString().split('T')[0]
      }.png`;

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.click();
            URL.revokeObjectURL(url);
          }
        },
        'image/png',
        1.0
      );
    } catch (error) {
      console.error('Error downloading chart:', error);
      setSnackbarMessage('Error downloading chart');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleApplyDates = (from: Date, to: Date) => {
    if (
      selectedDate.from?.toDateString() !== from.toDateString() ||
      selectedDate.to?.toDateString() !== to.toDateString()
    ) {
      setSelectedDate({ from, to });

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const toDate = new Date(to);
      toDate.setHours(0, 0, 0, 0);

      setIsLiveUpdatesEnabled(toDate.getTime() >= today.getTime());
    }
  };

  useEffect(() => {
    const shouldBeLive = isDateRangeToday();
    setIsLiveUpdatesEnabled(shouldBeLive);
  }, [selectedDate, isDateRangeToday]);

  const getDateRangeText = () => {
    if (!selectedDate.from) return 'Today';

    const formatDate = (date: Date) => {
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    };

    if (
      !selectedDate.to ||
      selectedDate.from.toDateString() === selectedDate.to.toDateString()
    ) {
      return formatDate(selectedDate.from);
    }

    return `${formatDate(selectedDate.from)} - ${formatDate(selectedDate.to)}`;
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{
        width: '100%',
        padding: '20px',
      }}
    >
      {/* Button to just set if data is real time or not */}

      <Grid2
        container
        display={'flex'}
        direction={'column'}
        columnSpacing={2}
        rowSpacing={1.5}
        sx={{
          maxWidth: '100%',
          width: '100%',
        }}
      >
        <Grid2
          container
          display={'flex'}
          alignItems={'baseline'}
          paddingBottom={'12px'}
        >
          <Typography variant="h4" fontSize="32px" fontWeight={500}>
            {rtlData?.alias || rtlData?.rtl_id || 'RTL Device'}
          </Typography>
          <Typography
            style={{
              fontSize: '12px',
              color: '#86BE02',
              marginLeft: '12px',
            }}
          >
            {t('Last updated at')} {lastUpdatedTime}
          </Typography>
        </Grid2>
      </Grid2>

      {/* Graph Area */}
      <Grid2
        container
        display={'flex'}
        sx={{
          minWidth: '100%',
          padding: '50px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '50px',
          flex: '1 0 0',
          alignSelf: 'stretch',
          borderRadius: '16px',
          backgroundColor: '#FFF',
        }}
      >
        {/* Content Area */}
        <Grid2
          container
          display={'flex'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            gap: '24px',
            alignSelf: 'stretch',
            flexWrap: 'wrap',
            borderRadius: '8px',
          }}
        >
          {/* Activity Report, Temperature, Humidity, Light, Shock */}
          <Grid2
            container
            display={'flex'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              gap: '24px',
              flex: '1 0 0',
              flexWrap: 'wrap',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{
                alignSelf: 'stretch',
                color: 'var(--Primary-Black, #000)',
                fontFeatureSettings: "'ss01' on, 'cv01' on",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '32px',
              }}
            >
              {t('Activity Report')}
            </Typography>
            <Typography
              sx={{
                'alignSelf': 'stretch',
                'color': activeMetric === 'temperature' ? '#000' : '#C7C7C7',
                'fontFeatureSettings': "'ss01' on, 'cv01' on",
                'fontFamily': '"Open Sans", sans-serif',
                'fontSize': '24px',
                'fontStyle': 'normal',
                'fontWeight': activeMetric === 'temperature' ? 600 : 400,
                'lineHeight': '32px',
                'cursor': 'pointer',
                '&:hover': {
                  color: '#A0A0A0',
                },
              }}
              onClick={() => handleMetricClick('temperature')}
            >
              {t('Temperature')}
            </Typography>
            <Typography
              sx={{
                'alignSelf': 'stretch',
                'color': activeMetric === 'humidity' ? '#000' : '#C7C7C7',
                'fontFeatureSettings': "'ss01' on, 'cv01' on",
                'fontFamily': '"Open Sans", sans-serif',
                'fontSize': '24px',
                'fontStyle': 'normal',
                'fontWeight': activeMetric === 'humidity' ? 600 : 400,
                'lineHeight': '32px',
                'cursor': 'pointer',
                '&:hover': {
                  color: '#A0A0A0',
                },
              }}
              onClick={() => handleMetricClick('humidity')}
            >
              {t('Humidity')}
            </Typography>
            <Typography
              sx={{
                'alignSelf': 'stretch',
                'color': activeMetric === 'light' ? '#000' : '#C7C7C7',
                'fontFeatureSettings': "'ss01' on, 'cv01' on",
                'fontFamily': '"Open Sans", sans-serif',
                'fontSize': '24px',
                'fontStyle': 'normal',
                'fontWeight': activeMetric === 'light' ? 600 : 400,
                'lineHeight': '32px',
                'cursor': 'pointer',
                '&:hover': {
                  color: '#A0A0A0',
                },
              }}
              onClick={() => handleMetricClick('light')}
            >
              {t('Light')}
            </Typography>
            <Typography
              sx={{
                'alignSelf': 'stretch',
                'color': activeMetric === 'shock' ? '#000' : '#C7C7C7',
                'fontFeatureSettings': "'ss01' on, 'cv01' on",
                'fontFamily': '"Open Sans", sans-serif',
                'fontSize': '24px',
                'fontStyle': 'normal',
                'fontWeight': activeMetric === 'shock' ? 600 : 400,
                'lineHeight': '32px',
                'cursor': 'pointer',
                '&:hover': {
                  color: '#A0A0A0',
                },
              }}
              onClick={() => handleMetricClick('shock')}
            >
              {t('Shock')}
            </Typography>
          </Grid2>

          {/* Date picker and download */}
          <Grid2
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Grid2
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                gap: '8px',
                flexWrap: 'wrap',
                borderRadius: '8px',
              }}
            >
              <Box
                onClick={() => setOpenCalendar(true)}
                component="button"
                sx={{
                  'display': 'flex',
                  'padding': '8px 16px',
                  'height': '40px',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'gap': '8px',
                  'borderRadius': '12px',
                  'background': '#EEE',
                  'border': 'none',
                  'cursor': 'pointer',
                  '&:hover': {
                    background: '#ddd',
                  },
                }}
              >
                <Typography
                  sx={{
                    alignSelf: 'stretch',
                    color: '#000',
                    fontFeatureSettings: "'ss01' on, 'cv01' on",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '28px',
                  }}
                >
                  {getDateRangeText()}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    width: '20px',
                    height: '20px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <ChevronDown style={{ width: '20px', height: '20px' }} />
                </Box>
              </Box>
              <Box
                onClick={handleDownload}
                component="button"
                sx={{
                  'display': 'flex',
                  'width': '40px',
                  'height': '40px',
                  'padding': '8px',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'gap': '8px',
                  'borderRadius': '12px',
                  'background': '#EEE',
                  'border': 'none',
                  'cursor': 'pointer',
                  '&:hover': {
                    background: '#ddd',
                  },
                }}
              >
                <DownloadIcon style={{ width: '20px', height: '20px' }} />
              </Box>
            </Grid2>
          </Grid2>
        </Grid2>

        {/* Chart Area */}
        <Grid2
          container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <RTLDataChart
            data={chartData}
            activeMetric={activeMetric}
            profileSettings={profileSettings}
            isLoading={isLoading}
          />
        </Grid2>
        {/* End Chart Area */}
      </Grid2>

      <Calendar
        open={openCalendar}
        setOpen={setOpenCalendar}
        onApplyDates={handleApplyDates}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={undefined}
      />
    </Grid2>
  );
}

export default ViewDataPage;
