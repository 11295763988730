import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DriverCards from './Components/DriverCards/DriverCards';
import MapComponent from '../../../components/Map/MapComponent';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import {
  MapMarkerResponse,
  RtlLocation,
} from '../../../interfaces/Map/mapMarkers';
import { getClientMapMarkers } from '../../../services/Api/apiDashboard';

function Dashboard() {
  const isSmallViewport = useMediaQuery('(max-width:1500px)');

  const drawerWidth = isSmallViewport ? '350px' : '425px';
  const [cookies] = useCookies(['user']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );
  const [selectedDriverRtl, setSelectedDriverRtl] = useState<string | null>(
    null
  );
  const [rtlHistoryMarkers, setRtlHistoryMarkers] = useState<RtlLocation[]>([]);
  // Add new state for the selected RTL serial
  const [selectedRtlSerial, setSelectedRtlSerial] = useState<string>('');

  const [mapMarkers, setMapMarkers] = useState<MapMarkerResponse>({
    seal: [],
    client: [],
    rtl: [],
    rtl_locations: [],
  });

  useEffect(() => {
    const fetchMapMarkers = async () => {
      const payload = { company_id: cookies.user.company_id };
      try {
        const response = await getClientMapMarkers(payload);
        setMapMarkers(response.data);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchMapMarkers);
        setSnackbarOpen(true);
      }
    };
    fetchMapMarkers();
  }, [cookies]);

  useEffect(() => {
    if (selectedDriverRtl && mapMarkers.rtl_locations) {
      const selectedRtlGroup = mapMarkers.rtl_locations.find(
        (group) => group.rtl_id === selectedDriverRtl
      );

      if (selectedRtlGroup) {
        setRtlHistoryMarkers(selectedRtlGroup.locations || []);

        // Find the RTL serial for the selected RTL ID
        const matchingRtl = mapMarkers.rtl.find(
          (rtl) => rtl.id === selectedDriverRtl
        );

        if (matchingRtl) {
          setSelectedRtlSerial(matchingRtl.rtl_serial);
        }
      } else {
        setRtlHistoryMarkers([]);
        setSelectedRtlSerial('');
      }
    } else {
      setRtlHistoryMarkers([]);
      setSelectedRtlSerial('');
    }
  }, [selectedDriverRtl, mapMarkers.rtl_locations, mapMarkers.rtl]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <MapComponent
          clientMarkers={mapMarkers.client}
          sealMarkers={mapMarkers.seal}
          rtlMarkers={mapMarkers.rtl}
          rtlHistoryMarkers={rtlHistoryMarkers}
          rtlSerial={selectedRtlSerial || mapMarkers.rtl[0]?.rtl_serial || ''}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: drawerWidth,
          height: '100vh',
          zIndex: 1,
          overflowY: 'auto',
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        }}
      >
        <DriverCards setSelectedDriverRtl={setSelectedDriverRtl} />
      </Box>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Box>
  );
}

export default Dashboard;
