import React, { useEffect, useState } from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import env from 'react-dotenv';
import { getLocation } from '../../utils/locationUtil';
import './MapComponent.css';
import {
  Client,
  Rtl,
  RtlLocation,
  Seal,
} from '../../interfaces/Map/mapMarkers';
import ClusteredDriverMarkers from './MapComponents/ClusteredDriverMarkers';
import ClusteredClientMarkers from './MapComponents/ClusteredClientMarkers';
import { ReactComponent as LocationIcon } from '../../assets/icons/location-crosshair.svg';
import { ReactComponent as LocationIconFilled } from '../../assets/icons/location-crosshair-filled.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as LockIconFilled } from '../../assets/icons/lock-filled.svg';
import { ReactComponent as RtlIcon } from '../../assets/icons/rtl-tab.svg';
import { ReactComponent as RtlIconFilled } from '../../assets/icons/rtl-filled.svg';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import ClusteredRtlMarkers from './MapComponents/ClusteredRtlMarkers';
// import HistoricalMarkers from './MapComponents/HistoricalMarkers';
import HistoricalMarkers from './MapComponents/HistoricalMarkers';

const MAP_KEY = env.REACT_APP_MAPS_API_KEY;
const customStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#deff8f',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

interface MapComponentProps {
  clientMarkers: Client[];
  sealMarkers: Seal[];
  rtlMarkers: Rtl[];
  rtlHistoryMarkers: RtlLocation[];
  rtlSerial: string;
}

const MapComponent: React.FC<MapComponentProps> = ({
  clientMarkers,
  sealMarkers,
  rtlMarkers,
  rtlHistoryMarkers,
  rtlSerial,
}) => {
  const [center, setCenter] = useState(getLocation());
  const [prevCenter, setPrevCenter] = useState(center);
  const [showSeals, setShowSeals] = useState(true);
  const [showClientLocations, setShowClientLocations] = useState(true);
  const [showRtlLocations, setShowRtlLocations] = useState(true);

  const isSmallViewport = useMediaQuery('(max-width:1500px)');

  const buttonPosition = isSmallViewport
    ? { left: '385px' }
    : { left: '460px' };

  const AddContext = () => {
    const map = useMap();

    useEffect(() => {
      if (map && center !== prevCenter) {
        map.panTo(center);
        setPrevCenter(center);
      }
    }, [map, center, prevCenter]);

    return null;
  };

  useEffect(() => {
    const handleLocationChange = () => {
      const newLocation = getLocation();
      setCenter(newLocation);
    };
    window.addEventListener('locationChange', handleLocationChange);

    return () => {
      window.removeEventListener('locationChange', handleLocationChange);
    };
  }, []);

  return (
    <APIProvider apiKey={MAP_KEY}>
      <Box
        sx={{
          position: 'absolute',
          top: '85%',
          ...buttonPosition,
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Tooltip
          title={
            showSeals ? 'Hide iMSeals on the map' : 'Show iMSeals on the map'
          }
        >
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '16px',
              backgroundColor: '#FFFFFF',
            }}
            onClick={() => setShowSeals(!showSeals)}
          >
            {showSeals ? (
              <LockIcon width={20} height={20} />
            ) : (
              <LockIconFilled width={20} height={20} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            showClientLocations
              ? 'Hide company locations on the map'
              : 'Show company locations on the map'
          }
        >
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '16px',
              backgroundColor: '#FFFFFF',
            }}
            onClick={() => setShowClientLocations(!showClientLocations)}
          >
            {showClientLocations ? (
              <LocationIcon width={20} height={20} />
            ) : (
              <LocationIconFilled width={20} height={20} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            showRtlLocations
              ? 'Hide RTL locations on the map'
              : 'Show RTL locations on the map'
          }
        >
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '16px',
              backgroundColor: '#FFFFFF',
            }}
            onClick={() => setShowRtlLocations(!showRtlLocations)}
          >
            {showRtlLocations ? (
              <RtlIcon width={20} height={20} />
            ) : (
              <RtlIconFilled width={20} height={20} />
            )}
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title={
            showHistorical
              ? 'Hide historical locations on the map'
              : 'Show historical locations on the map'
          }
        >
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '16px',
              backgroundColor: '#FFFFFF',
            }}
            onClick={() => setShowHistorical(!showHistorical)}
          >
            {showHistorical ? (
              <HistoryIcon width={20} height={20} />
            ) : (
              <HistoryIconFilled width={20} height={20} />
            )}
          </IconButton>
        </Tooltip> */}
      </Box>
      <Map
        id="map"
        defaultCenter={center}
        defaultZoom={15}
        reuseMaps={true}
        minZoom={2.5}
        styles={customStyles}
        mapTypeControl={false}
        disableDefaultUI={true}
        gestureHandling={'greedy'}
      >
        <AddContext />
        {showSeals && (
          <ClusteredDriverMarkers key="seals" sealMarkers={sealMarkers} />
        )}
        {showClientLocations && (
          <ClusteredClientMarkers key="clients" clientMarkers={clientMarkers} />
        )}
        {showRtlLocations && (
          <ClusteredRtlMarkers key="rtl" rtlMarkers={rtlMarkers} />
        )}
        {rtlHistoryMarkers && rtlHistoryMarkers.length > 0 && (
          <HistoricalMarkers
            key={'historical'}
            historicalMarkers={rtlHistoryMarkers}
            rtlSerial={rtlSerial}
          />
        )}
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
