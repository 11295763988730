import { ThemeProvider } from '@emotion/react';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import modalTheme2 from '../../../../theme/modalTheme2';

interface CreateRTLToleranceProfileModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: unknown) => void;
}

const CreateRTLToleranceProfileModal: React.FC<
  CreateRTLToleranceProfileModalProps
> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    temperatureMin: '',
    temperatureMax: '',
    temperatureToleranceBelow: '',
    temperatureToleranceAbove: '',
    humidityMin: '',
    humidityMax: '',
    humidityToleranceBelow: '',
    humidityToleranceAbove: '',
    lightMin: '',
    lightMax: '',
    lightToleranceAbove: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  return (
    <ThemeProvider theme={modalTheme2}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Create RTL Tolerance Profile')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            "Define the acceptable range for each sensor. Optional: Add a tolerance range to receive early warnings when values start to drift, but aren't yet considered critical. Leave tolerance inputs blank if you want alerts for any values outside the acceptable range."
          )}
        </Typography>
        <DialogContent>
          {/* Temperature Range */}
          <Box display="flex" alignItems="center" gap={2}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Temperature Range (°C)')}*
              </Typography>
              <TextField
                name="temperatureMin"
                placeholder="#°"
                fullWidth
                value={formValues.temperatureMin}
                onChange={handleChange}
              />
            </Box>
            <Box
              sx={{
                marginTop: '12px',
                display: 'flex',
                width: '16px',
                height: '44px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {t('to')}
              </Typography>
            </Box>

            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: 'transparent',
                }}
              >
                Temperature Max (°C)*
              </Typography>
              <TextField
                name="temperatureMax"
                placeholder="#°"
                fullWidth
                value={formValues.temperatureMax}
                onChange={handleChange}
              />
            </Box>

            {/* Optional Tolerances */}
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Tolerance Below')} (°C)
              </Typography>
              <TextField
                name="temperatureToleranceBelow"
                placeholder="#°"
                fullWidth
                value={formValues.temperatureToleranceBelow}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Tolerance Above')} (°C)
              </Typography>
              <TextField
                name="temperatureToleranceAbove"
                placeholder="#°"
                fullWidth
                value={formValues.temperatureToleranceAbove}
                onChange={handleChange}
              />
            </Box>
          </Box>

          {/* Humidity Range */}
          <Box display="flex" alignItems="center" gap={2}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Humidity Range (%)')}*
              </Typography>
              <TextField
                name="humidityMin"
                placeholder="#%"
                fullWidth
                value={formValues.humidityMin}
                onChange={handleChange}
              />
            </Box>
            <Box
              sx={{
                marginTop: '12px',
                display: 'flex',
                width: '16px',
                height: '44px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {t('to')}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: 'transparent',
                }}
              >
                {t('Humidity Range (%)')}*
              </Typography>
              <TextField
                name="humidityMax"
                placeholder="#%"
                fullWidth
                value={formValues.humidityMax}
                onChange={handleChange}
              />
            </Box>

            {/* Optional Tolerances */}
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Tolerance Below')} (%)
              </Typography>
              <TextField
                name="humidityToleranceBelow"
                placeholder="#%"
                fullWidth
                value={formValues.humidityToleranceBelow}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Tolerance Above')} (%)
              </Typography>
              <TextField
                name="humidityToleranceAbove"
                placeholder="#%"
                fullWidth
                value={formValues.humidityToleranceAbove}
                onChange={handleChange}
              />
            </Box>
          </Box>

          {/* Light Range */}
          <Box display="flex" alignItems="center" gap={2}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Light Range (Lux)')}*
              </Typography>
              <TextField
                name="lightMin"
                placeholder="# Lux"
                fullWidth
                value={formValues.lightMin}
                onChange={handleChange}
              />
            </Box>
            <Box
              sx={{
                marginTop: '12px',
                display: 'flex',
                width: '16px',
                height: '44px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {t('to')}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: 'transparent',
                }}
              >
                {t('Light Range (Lux)')}*
              </Typography>
              <TextField
                name="lightMax"
                placeholder="# Lux"
                fullWidth
                value={formValues.lightMax}
                onChange={handleChange}
              />
            </Box>

            {/* Optional Tolerances */}
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                {t('Tolerance Above')} (Lux)
              </Typography>
              <TextField
                name="lightToleranceAbove"
                placeholder="# Lux"
                fullWidth
                value={formValues.lightToleranceAbove}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={false}
            onClick={handleSubmit}
            color="primary"
          >
            {t('Create New Profile')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CreateRTLToleranceProfileModal;
