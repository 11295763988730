import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import { useTranslation } from 'react-i18next';

interface CompleteTripModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (routeId: string, tripId: string) => void;
  routeId: string;
  tripId: string;
}

const CompleteTripModal: React.FC<CompleteTripModalProps> = ({
  open,
  onClose,
  onSubmit,
  routeId,
  tripId,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit(routeId, tripId);
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open, routeId, tripId]);

  const handleSubmit = () => {
    onSubmit(routeId, tripId);
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Complete Trip')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'Are you sure you want to mark this trip as complete? This action cannot be undone.'
          )}
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            {t('Complete Trip')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CompleteTripModal;
