import React from 'react';
import { TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/icons/magnifying-glass.svg';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
    search: string;
    setSearch: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ search, setSearch }) => {
    const { t } = useTranslation();

    return (
        <TextField
            placeholder={t('Search')}
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            slotProps={{
                input: {
                    startAdornment: (
                        <SearchIcon
                            style={{
                                height: '18px',
                                width: '18px',
                                fill: '#C7C7C7',
                                paddingRight: '10px',
                            }}
                        ></SearchIcon>
                    ),
                    style: {
                        height: '32px',
                    },
                },
                inputLabel: {
                    style: {
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none',
                    },
                },
            }}
            sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                'width': 'calc(100% - 8px)',
                'height': '32px',
                'justifyContent': 'space-between',
                '& .MuiOutlinedInput-root': {
                    'borderRadius': '8px',
                    'borderColor': '#1C1C1C',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '& input::placeholder': {
                        fontFamily: 'Open Sans',
                        color: '#C7C7C7',
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        opacity: 1,
                    },
                    '& input': {
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                    },
                },
            }}
        />
    );
};

export default SearchBar;