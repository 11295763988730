import React, { createContext, useContext, useState } from 'react';
import { RTLInfo } from '../interfaces/Driver/Response/companyDriverCards';

interface RtlDevicesContextType {
  rtlDevices: RTLInfo[];
  setRtlDevices: React.Dispatch<React.SetStateAction<RTLInfo[]>>;
}

const RtlDevicesContext = createContext<RtlDevicesContextType | undefined>(
  undefined
);

export const RtlDevicesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [rtlDevices, setRtlDevices] = useState<RTLInfo[]>([]);

  return (
    <RtlDevicesContext.Provider value={{ rtlDevices, setRtlDevices }}>
      {children}
    </RtlDevicesContext.Provider>
  );
};

export const useRtlDevices = () => {
  const context = useContext(RtlDevicesContext);
  if (context === undefined) {
    throw new Error('useRtlDevices must be used within a RtlDevicesProvider');
  }
  return context;
};
