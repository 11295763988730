import {
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Box,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as FullBatteryIcon } from '../../assets/icons/battery_percentage.svg';
import { ReactComponent as HalfBatteryIcon } from '../../assets/icons/battery-half-solid.svg';
import { ReactComponent as QuarterBatteryIcon } from '../../assets/icons/battery-quarter-solid.svg';
import { ReactComponent as ThreeQuarterBatteryIcon } from '../../assets/icons/battery-three-quarters-solid.svg';
import { ReactComponent as RtlActivityLogTemperature } from '../../assets/icons/rtl-temp.svg';
import { ReactComponent as RtlActivityLogHumidity } from '../../assets/icons/rtl-humidity.svg';
import { ReactComponent as RtlActivityLogLux } from '../../assets/icons/rtl-lux.svg';
import { ReactComponent as RtlActivityLogShock } from '../../assets/icons/rtl-shock.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter-icon.svg';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material';
import NoDataState from './NoDataState';
import { getValueStyle } from '../../utils/getStyles';

interface TableComponentProps {
  title: string;
  searchValue: string;
  columns: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{ [key: string]: any }>;
  hasMore: boolean;
  columnFieldMapping: { [key: string]: string };
  columnWidths: string[];
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPageChange: () => void;
  setCurrentPage: (page: number) => void;
  onPlusIconClick?: () => void;
  onLocationClick?: (latitude: string, longitude: string) => void;
  selectedType: string;
  onTypeChange: (type: string) => void;
  onGraphClick?: (type: string, value: string) => void;
}

interface WarningTypeOption {
  value: string;
  label: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const WARNING_TYPES: WarningTypeOption[] = [
  {
    value: 'All',
    label: 'All',
    icon: FilterIcon,
  },
  {
    value: 'Temperature',
    label: 'Temperature',
    icon: RtlActivityLogTemperature,
  },
  { value: 'Humidity', label: 'Humidity', icon: RtlActivityLogHumidity },
  { value: 'Light', label: 'Light', icon: RtlActivityLogLux },
  { value: 'Shock', label: 'Shock', icon: RtlActivityLogShock },
];

const RTLHistoryTableComponent: React.FC<TableComponentProps> = ({
  title,
  searchValue,
  columns,
  data,
  hasMore,
  columnFieldMapping,
  columnWidths,
  onSearchValueChange,
  onPageChange,
  setCurrentPage,
  onPlusIconClick,
  onLocationClick,
  selectedType,
  onTypeChange,
  onGraphClick,
}) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();
  const [totalHeight, setTotalHeight] = useState<number>(
    window.innerHeight - 300
  );

  // Get column tooltips based on column name
  const getColumnTooltip = (columnName: string) => {
    switch (columnName) {
      case 'Last Shock':
        return t('This value is the last time a shock alert occurred');
      default:
        return null;
    }
  };

  const handleIconClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, [data]);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (column: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        const aValue = a[columnFieldMapping[sortConfig.key]];
        const bValue = b[columnFieldMapping[sortConfig.key]];

        if (aValue === null || aValue === '-')
          return sortConfig.direction === 'asc' ? 1 : -1;
        if (bValue === null || bValue === '-')
          return sortConfig.direction === 'asc' ? -1 : 1;

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig, columnFieldMapping]);

  // Handle View on Map click in Location column
  const handleLocationClick = (row: { [key: string]: unknown }) => {
    if (onLocationClick && row.lat && row.lng) {
      onLocationClick(row.lat as string, row.lng as string);
    }
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore]
  );

  const handleGraphClick = (row: { [key: string]: unknown }) => {
    if (onGraphClick && row.type && row.value) {
      onGraphClick(row.type as string, row.value as string);
    }
  };

  interface BatteryIconProps {
    battery_percentage: number | null;
  }

  const BatteryIcon: React.FC<BatteryIconProps> = ({ battery_percentage }) => {
    if (battery_percentage === null || battery_percentage === undefined) {
      return null;
    }

    let icon, color;
    if (battery_percentage > 75) {
      icon = <FullBatteryIcon />;
    } else if (battery_percentage > 50) {
      icon = <ThreeQuarterBatteryIcon />;
    } else if (battery_percentage > 25) {
      icon = <HalfBatteryIcon />;
    } else {
      icon = <QuarterBatteryIcon />;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', color }}>{icon}</div>
    );
  };

  useEffect(() => {
    const options = {
      root: containerRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver(handleObserver, options);

    if (bottomRef.current) {
      observerRef.current.observe(bottomRef.current);
    }

    return () => {
      if (observerRef.current && bottomRef.current) {
        observerRef.current.unobserve(bottomRef.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    setCurrentPage(page);
    onPageChange();
  }, [page]);

  useEffect(() => {
    if (observerRef.current && bottomRef.current) {
      observerRef.current.disconnect();
      observerRef.current.observe(bottomRef.current);
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setTotalHeight(window.innerHeight - 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid2
      container
      display="flex"
      direction="column"
      columnSpacing={2}
      rowSpacing={1.5}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2
        container
        display="flex"
        alignItems="baseline"
        paddingBottom="12px"
      >
        <Typography variant="h4" fontSize="32px" fontWeight={500}>
          {t(title)}
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display="flex"
        alignItems="center"
        sx={{
          height: '46px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '80%',
        }}
      >
        <Grid2
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
          }}
        >
          <Select
            value={selectedType}
            onChange={(e) => onTypeChange(e.target.value as string)}
            variant="standard"
            disableUnderline
            IconComponent={() => null}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor:
                    selected !== 'All' ? '#E5E5E5' : 'transparent',
                  borderRadius: '12px',
                  padding: '6px',
                }}
              >
                {WARNING_TYPES.find((type) => type.value === selected)?.icon &&
                  React.createElement(
                    WARNING_TYPES.find((type) => type.value === selected)!
                      .icon!,
                    {
                      style: {
                        width: '20px',
                        height: '20px',
                        filter: 'brightness(0)',
                      },
                    }
                  )}
              </Box>
            )}
            sx={{
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                paddingY: 0,
                color: '#838383',
                minWidth: '120px',
                paddingRight: '0px !important',
              },
            }}
          >
            {WARNING_TYPES.map((type) => (
              <MenuItem
                key={type.value}
                value={type.value}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 1,
                  minWidth: '120px',
                  px: 2,
                }}
              >
                {type.icon &&
                  React.createElement(type.icon, {
                    style: {
                      width: '20px',
                      height: '20px',
                      filter: 'brightness(0)',
                    },
                  })}
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#838383',
                    marginLeft: 1,
                  }}
                >
                  {t(type.label)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid2>

        {onPlusIconClick && (
          <PlusIcon
            onClick={onPlusIconClick}
            cursor="pointer"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '10px',
              padding: '8px 4px',
            }}
          />
        )}

        <Grid2
          container
          display="flex"
          alignItems="center"
          sx={{
            height: '100%',
            flex: 1,
            borderLeft: '1px solid rgba(131, 131, 131, 0.4)',
            paddingX: 2,
          }}
        >
          <SearchIcon
            onClick={handleIconClick}
            cursor="text"
            style={{
              fill: '#838383',
              width: '13px',
              height: '13px',
              marginRight: '8px',
            }}
          />
          <TextField
            inputRef={textFieldRef}
            sx={{
              'flex': 1,
              'padding': '0px',
              '& .MuiOutlinedInput-root': {
                'padding': '0px',
                '& fieldset': {
                  border: 'none',
                },
              },
              '& .MuiInputBase-input': {
                padding: '0px',
              },
              '& input::placeholder': {
                fontSize: '15px',
              },
            }}
            value={searchValue}
            onChange={onSearchValueChange}
            placeholder={t('Search')}
            variant="outlined"
          />
        </Grid2>
      </Grid2>

      <Grid2>
        {data.length === 0 && timePassed ? (
          <NoDataState />
        ) : (
          <TableContainer
            component={Paper}
            ref={containerRef}
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              height: totalHeight,
              overflowY: 'scroll',
            }}
          >
            <Table
              stickyHeader
              sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: columnWidths[index],
                        cursor: 'pointer',
                        borderBottom: 'none',
                        backgroundColor: '#F5F5F5',
                        color: 'rgba(0, 0, 0, 0.4)',
                        fontWeight: 400,
                        fontSize: '12px',
                        paddingTop: '0px',
                        paddingBottom: '6px',
                      }}
                      onClick={() => handleSort(column)}
                    >
                      {getColumnTooltip(column) ? (
                        <Tooltip
                          title={getColumnTooltip(column)}
                          arrow
                          placement="top"
                        >
                          <Typography
                            component="span"
                            sx={{
                              color: 'rgba(0, 0, 0, 0.4)',
                              fontWeight: 400,
                              fontSize: '12px',
                            }}
                          >
                            {t(column)}
                          </Typography>
                        </Tooltip>
                      ) : (
                        t(column)
                      )}
                      {sortConfig && sortConfig.key === column ? (
                        sortConfig.direction === 'asc' ? (
                          <ArrowUpIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        ) : (
                          <ArrowDownIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      width: 'auto',
                      borderBottom: 'none',
                      backgroundColor: '#F5F5F5',
                    }}
                  />
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        rowIndex % 2 === 1 ? '#FBFBFB' : '#FFFFFF',
                    }}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          width: columnWidths[colIndex],
                          borderBottom: 'none',
                          padding: '8px 16px',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          color:
                            column === 'RTL Alias' &&
                            row[columnFieldMapping['Status']] === 'Warning'
                              ? '#BA1F1F'
                              : 'inherit',
                        }}
                        sx={{
                          borderRadius:
                            colIndex === 0 ? '16px 0 0 16px' : 'none',
                        }}
                      >
                        {column === 'Location' ? (
                          <Typography
                            onClick={() => handleLocationClick(row)}
                            sx={{
                              alignSelf: 'stretch',
                              color: '#34C759',
                              fontFeatureSettings: "'ss01' on, 'cv01' on",
                              fontFamily: 'Open Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '20px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t('View on Map')}
                          </Typography>
                        ) : column === 'Graph' ? (
                          <Typography
                            onClick={() => handleGraphClick(row)}
                            sx={{
                              alignSelf: 'stretch',
                              //   color: '#34C759',
                              fontFeatureSettings: "'ss01' on, 'cv01' on",
                              fontFamily: 'Open Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '20px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t('View Graph')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === null ||
                          row[columnFieldMapping[column]] === '' ? (
                          '-'
                        ) : (column === 'Status' &&
                            row[columnFieldMapping[column]] === 'IN_USE') ||
                          row[columnFieldMapping[column]] === 'ACTIVE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Active')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'PAUSED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Paused')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'CONNECTED' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Connected')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'NO_CONNECTION' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('No Connection')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'WARNING' ? (
                          <Typography color="#BA1F1F" fontSize={'0.875rem'}>
                            • {t('Warning')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'RECENT_WARNING' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Recent Warning')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'CANCELLED' ? (
                          <Typography color="#DD3333" fontSize={'0.875rem'}>
                            • {t('Cancelled')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'SUSPENDED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Suspended')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'DEACTIVATED' ? (
                          <Typography color="#FF0000" fontSize={'0.875rem'}>
                            • {t('Deactivated')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'REGISTERED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Awaiting Activation')}
                          </Typography>
                        ) : column === 'Battery' ? (
                          <BatteryIcon
                            battery_percentage={row[columnFieldMapping[column]]}
                          />
                        ) : column === 'Temperature' ||
                          column === 'Humidity' ||
                          column === 'Light' ? (
                          <div
                            style={{
                              display: 'flex',
                              padding: '2px 8px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: '1 0 0',
                              borderRadius: '10px',
                              backgroundColor: getValueStyle(
                                row[`${columnFieldMapping[column]}_display`]
                              ).backgroundColor,
                            }}
                          >
                            <Typography
                              style={{
                                color: getValueStyle(
                                  row[`${columnFieldMapping[column]}_display`]
                                ).textColor,
                                fontSize: '14px',
                              }}
                            >
                              {column === 'Temperature'
                                ? `${row[columnFieldMapping[column]]}°C`
                                : column === 'Humidity'
                                ? `${row[columnFieldMapping[column]]}%`
                                : `${row[columnFieldMapping[column]]} lux`}
                            </Typography>
                          </div>
                        ) : (
                          row[columnFieldMapping[column]]
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: 'none',
                        borderRadius: '0 16px 16px 0',
                        padding: '8px',
                      }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div ref={bottomRef} />
          </TableContainer>
        )}
      </Grid2>
    </Grid2>
  );
};

export default RTLHistoryTableComponent;
