import { AxiosError } from 'axios';
import axiosInstance from '../../utils/axiosInstance';

export interface RtlDevice {
  id: string;
  rtl_id: string;
  alias: string;
  status: string;
  vehicle: string;
  driver: string;
  associated_seal: string;
  lat: string;
  lng: string;
  settings_profile: string;
  settings_profile_id: string;
  temperature: string;
  temperature_display: string;
  humidity: string;
  humidity_display: string;
  light: string;
  light_display: string;
  shock: string;
  latest_shock_time: string;
  shock_display: string;
}

export interface Profile {
  id: string;
  alias: string;
}

export interface Vehicle {
  id: string;
  vehicle_alias: string;
  vehicle_registration: string;
}

export interface RtlServiceResponse {
  statusCode: number;
  data: {
    data: RtlDevice[];
    profiles: Profile[];
    vehicles: Vehicle[];
  };
  message: string;
  error: string | null;
}

export interface AssignVehicleResponse {
  statusCode: number;
  message: string;
  error: string | null;
}

export interface AssignVehicleRequest {
  rtl_id: string;
  vehicle_id: string;
}

export interface RenameRtlRequest {
  rtl_id: string;
  new_alias: string;
}

export interface RtlDetailResponse {
  statusCode: number;
  data: {
    id: string;
    company_id: string;
    rtl_status: string;
    rtl_serial: string;
    rtl_alias: string;
    vehicle_id: string;
    settings_profile_id: string;
    date_created: string;
    date_updated: string;
  };
  message: string;
  error: string | null;
}

export interface RtlListRequest {
  company_id: string;
  search_term?: string;
}

interface RTLHistoryItem {
  id: string;
  timestamp: string;
  type: string;
  value: string;
  latitude: string;
  longitude: string;
  driver: string;
}

interface RTLHistoryResponse {
  statusCode: number;
  data: {
    history: RTLHistoryItem[];
  };
  message: string;
  error: null | string;
}

export const fetchRtlServiceList = async (
  params: RtlListRequest
): Promise<RtlServiceResponse> => {
  try {
    const response = await axiosInstance.post<RtlServiceResponse>(
      '/rtl-service/list',
      params
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw {
      status: axiosError.response?.status || 500,
      message: axiosError.message || 'Error fetching RTL service data',
    };
  }
};

export const assignRtlToVehicle = async (
  payload: AssignVehicleRequest
): Promise<AssignVehicleResponse> => {
  try {
    const response = await axiosInstance.patch<AssignVehicleResponse>(
      '/rtl-service/assign-to-vehicle',
      payload
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw {
      status: axiosError.response?.status || 500,
      message: axiosError.message || 'Error assigning RTL to vehicle',
    };
  }
};

export const renameRtl = async (
  payload: RenameRtlRequest
): Promise<RtlDetailResponse> => {
  try {
    const response = await axiosInstance.patch<RtlDetailResponse>(
      '/rtl-service/rename',
      payload
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw {
      status: axiosError.response?.status || 500,
      message: axiosError.message || 'Error renaming RTL device',
    };
  }
};

export const fetchRTLHistory = async (
  rtlId: string,
  userId: string
): Promise<RTLHistoryResponse> => {
  try {
    const response = await axiosInstance.post('/rtl-service/rtl-history', {
      rtl_id: rtlId,
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching RTL history:', error);
    throw new Error('Failed to fetch RTL history');
  }
};
