import {
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as EllipsVertIcon } from '../../assets/icons/ellipsis-vertical.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as FullBatteryIcon } from '../../assets/icons/battery_percentage.svg';
import { ReactComponent as HalfBatteryIcon } from '../../assets/icons/battery-half-solid.svg';
import { ReactComponent as QuarterBatteryIcon } from '../../assets/icons/battery-quarter-solid.svg';
import { ReactComponent as ThreeQuarterBatteryIcon } from '../../assets/icons/battery-three-quarters-solid.svg';
import { useTranslation } from 'react-i18next';

import NoDataState from './NoDataState';
import { getValueStyle } from '../../utils/getStyles';

interface TableComponentProps {
  title: string;
  searchValue: string;
  columns: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{ [key: string]: any }>;
  hasMore: boolean;
  columnFieldMapping: { [key: string]: string };
  columnWidths: string[];
  actions: (row: { [key: string]: unknown }) => Array<{
    label: string;
    icon: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    isLock?: boolean;
  }>;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPageChange: () => void;
  setCurrentPage: (page: number) => void;
  onPlusIconClick?: () => void;
  onLocationClick?: (latitude: string, longitude: string) => void;
}

const TableComponent: React.FC<TableComponentProps> = ({
  title,
  searchValue,
  columns,
  data,
  hasMore,
  columnFieldMapping,
  columnWidths,
  actions,
  onSearchValueChange,
  onPageChange,
  setCurrentPage,
  onPlusIconClick,
  onLocationClick,
}) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: unknown;
  }>(null);
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();
  const [totalHeight, setTotalHeight] = useState<number>(
    window.innerHeight - 300
  );

  // Get column tooltips based on column name
  const getColumnTooltip = (columnName: string) => {
    switch (columnName) {
      case 'Last Shock':
        return t('This value is the last time a shock alert occurred');
      default:
        return null;
    }
  };

  const handleIconClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, [data]);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (column: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        const aValue = a[columnFieldMapping[sortConfig.key]];
        const bValue = b[columnFieldMapping[sortConfig.key]];

        if (aValue === null || aValue === '-')
          return sortConfig.direction === 'asc' ? 1 : -1;
        if (bValue === null || bValue === '-')
          return sortConfig.direction === 'asc' ? -1 : 1;

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig, columnFieldMapping]);

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: { [key: string]: unknown }
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  // Handle View on Map click in Location column
  const handleLocationClick = (row: { [key: string]: unknown }) => {
    if (onLocationClick && row.lat && row.lng) {
      onLocationClick(row.lat as string, row.lng as string);
    }
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore]
  );

  interface BatteryIconProps {
    battery_percentage: number | null;
  }

  const BatteryIcon: React.FC<BatteryIconProps> = ({ battery_percentage }) => {
    if (battery_percentage === null || battery_percentage === undefined) {
      return null;
    }

    let icon, color;
    if (battery_percentage > 75) {
      icon = <FullBatteryIcon />;
    } else if (battery_percentage > 50) {
      icon = <ThreeQuarterBatteryIcon />;
    } else if (battery_percentage > 25) {
      icon = <HalfBatteryIcon />;
    } else {
      icon = <QuarterBatteryIcon />;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', color }}>{icon}</div>
    );
  };

  useEffect(() => {
    const options = {
      root: containerRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver(handleObserver, options);

    if (bottomRef.current) {
      observerRef.current.observe(bottomRef.current);
    }

    return () => {
      if (observerRef.current && bottomRef.current) {
        observerRef.current.unobserve(bottomRef.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    setCurrentPage(page);
    onPageChange();
  }, [page]);

  useEffect(() => {
    if (observerRef.current && bottomRef.current) {
      observerRef.current.disconnect();
      observerRef.current.observe(bottomRef.current);
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setTotalHeight(window.innerHeight - 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid2
      container
      display={'flex'}
      direction={'column'}
      columnSpacing={2}
      rowSpacing={1.5}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2
        container
        display={'flex'}
        alignItems={'baseline'}
        paddingBottom={'12px'}
      >
        <Typography variant="h4" fontSize="32px" fontWeight={500}>
          {t(title)}
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: '46px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '100%',
        }}
      >
        {onPlusIconClick && (
          <PlusIcon
            onClick={onPlusIconClick}
            cursor={'pointer'}
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '10px',
              padding: '8px 4px',
            }}
          />
        )}
        <Grid2
          container
          display={'flex'}
          alignItems={'center'}
          sx={{
            height: '100%',
            width: '100%',
            borderLeft: onPlusIconClick
              ? '1px solid rgba(131, 131, 131, 0.4)'
              : 'none',
            marginLeft: '10px',
          }}
        >
          <Grid2
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginLeft: '10px',
            }}
          >
            <SearchIcon
              onClick={handleIconClick}
              cursor={'text'}
              style={{
                fill: '#838383',
                width: '13px',
                height: '13px',
                marginRight: '8px',
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              width: '80%',
            }}
          >
            <TextField
              inputRef={textFieldRef}
              sx={{
                'width': '100%',
                'padding': '0px',
                '& .MuiOutlinedInput-root': {
                  'padding': '0px',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
                '& input::placeholder': {
                  fontSize: '15px',
                },
              }}
              value={searchValue}
              onChange={onSearchValueChange}
              placeholder={t('Search')}
              variant="outlined"
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2>
        {data.length === 0 && timePassed ? (
          <NoDataState />
        ) : (
          <TableContainer
            component={Paper}
            ref={containerRef}
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              height: totalHeight,
              overflowY: 'scroll',
            }}
          >
            <Table
              stickyHeader
              sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: columnWidths[index],
                        cursor: 'pointer',
                        borderBottom: 'none',
                        backgroundColor: '#F5F5F5',
                        color: 'rgba(0, 0, 0, 0.4)',
                        fontWeight: 400,
                        fontSize: '12px',
                        paddingTop: '0px',
                        paddingBottom: '6px',
                      }}
                      onClick={() => handleSort(column)}
                    >
                      {getColumnTooltip(column) ? (
                        <Tooltip
                          title={getColumnTooltip(column)}
                          arrow
                          placement="top"
                        >
                          <Typography
                            component="span"
                            sx={{
                              color: 'rgba(0, 0, 0, 0.4)',
                              fontWeight: 400,
                              fontSize: '12px',
                            }}
                          >
                            {t(column)}
                          </Typography>
                        </Tooltip>
                      ) : (
                        t(column)
                      )}
                      {sortConfig && sortConfig.key === column ? (
                        sortConfig.direction === 'asc' ? (
                          <ArrowUpIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        ) : (
                          <ArrowDownIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      width: 'auto',
                      borderBottom: 'none',
                      backgroundColor: '#F5F5F5',
                    }}
                  />
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        rowIndex % 2 === 1 ? '#FBFBFB' : '#FFFFFF',
                    }}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          width: columnWidths[colIndex],
                          borderBottom: 'none',
                          padding: '8px 16px',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          color:
                            column === 'RTL Alias' &&
                            row[columnFieldMapping['Status']] === 'Warning'
                              ? '#BA1F1F'
                              : 'inherit',
                        }}
                        sx={{
                          borderRadius:
                            colIndex === 0 ? '16px 0 0 16px' : 'none',
                        }}
                      >
                        {column === 'Location' ? (
                          <Typography
                            onClick={() => handleLocationClick(row)}
                            sx={{
                              alignSelf: 'stretch',
                              color: '#34C759',
                              fontFeatureSettings: "'ss01' on, 'cv01' on",
                              fontFamily: 'Open Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '20px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t('View on Map')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === null ||
                          row[columnFieldMapping[column]] === '' ? (
                          '-'
                        ) : (column === 'Status' &&
                            row[columnFieldMapping[column]] === 'IN_USE') ||
                          row[columnFieldMapping[column]] === 'ACTIVE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Active')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'PAUSED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Paused')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'CONNECTED' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Connected')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'NO_CONNECTION' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('No Connection')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'WARNING' ? (
                          <Typography color="#BA1F1F" fontSize={'0.875rem'}>
                            • {t('Warning')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'RECENT_WARNING' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Recent Warning')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'CANCELLED' ? (
                          <Typography color="#DD3333" fontSize={'0.875rem'}>
                            • {t('Cancelled')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'SUSPENDED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Suspended')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'DEACTIVATED' ? (
                          <Typography color="#FF0000" fontSize={'0.875rem'}>
                            • {t('Deactivated')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'REGISTERED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Awaiting Activation')}
                          </Typography>
                        ) : column === 'Battery' ? (
                          <BatteryIcon
                            battery_percentage={row[columnFieldMapping[column]]}
                          />
                        ) : column === 'Temperature' ||
                          column === 'Humidity' ||
                          column === 'Light' ? (
                          <div
                            style={{
                              display: 'flex',
                              padding: '2px 8px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: '1 0 0',
                              borderRadius: '10px',
                              backgroundColor: getValueStyle(
                                row[`${columnFieldMapping[column]}_display`]
                              ).backgroundColor,
                            }}
                          >
                            <Typography
                              style={{
                                color: getValueStyle(
                                  row[`${columnFieldMapping[column]}_display`]
                                ).textColor,
                                fontSize: '14px',
                              }}
                            >
                              {column === 'Temperature'
                                ? `${row[columnFieldMapping[column]]}°C`
                                : column === 'Humidity'
                                ? `${row[columnFieldMapping[column]]}%`
                                : `${row[columnFieldMapping[column]]} lux`}
                            </Typography>
                          </div>
                        ) : (
                          row[columnFieldMapping[column]]
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: 'none',
                        borderRadius: '0 16px 16px 0',
                        padding: '8px',
                      }}
                    >
                      <IconButton
                        onClick={(event) => handleActionClick(event, row)}
                        disabled={actions(row).every(
                          (action) => action.disabled
                        )}
                      >
                        <EllipsVertIcon
                          style={{
                            fill: actions(row).every(
                              (action) => action.disabled
                            )
                              ? '#D1D1D1'
                              : '#000000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleClose}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: '20px',
                            width: '220px',
                            padding: '8px',
                          },
                        }}
                      >
                        {actions(row).map(
                          (action, index) =>
                            (!action.disabled || action.isLock) && (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  action.onClick();
                                  handleClose();
                                }}
                                disabled={action.disabled}
                                sx={{
                                  'fontSize': '14px',
                                  'padding': action.isLock
                                    ? '6px 20px'
                                    : '12px 20px',
                                  'backgroundColor': action.isLock
                                    ? '#000000'
                                    : 'inherit',
                                  'borderRadius': action.isLock
                                    ? '8px'
                                    : 'inherit',
                                  'textTransform': action.isLock
                                    ? 'none'
                                    : 'inherit',
                                  'height': action.isLock ? '28px' : '44px',
                                  'color': action.isLock
                                    ? '#ffffff'
                                    : 'inherit',
                                  'margin': action.isLock
                                    ? '14px 8px'
                                    : 'inherit',
                                  'radius': '8px',
                                  '&:hover': {
                                    backgroundColor: action.isLock
                                      ? '#4d4d4d'
                                      : '#f0f0f0',
                                  },
                                }}
                              >
                                {action.icon}
                                <Typography
                                  sx={{
                                    color: action.isLock
                                      ? '#ffffff'
                                      : 'inherit',
                                    marginLeft: action.isLock
                                      ? '6px'
                                      : 'inherit',
                                    fontSize: action.isLock
                                      ? '0.8rem'
                                      : 'inherit',
                                  }}
                                >
                                  {t(action.label)}
                                </Typography>
                              </MenuItem>
                            )
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div ref={bottomRef} />
          </TableContainer>
        )}
      </Grid2>
    </Grid2>
  );
};

export default TableComponent;
