import axios, { AxiosError } from 'axios';
import env from 'react-dotenv';
import {
  UserLogInRequest,
  UserLogoutRequest,
  superUserIntoClientRequest,
} from '../../interfaces/UserInfo/userTypes';
import axiosInstance from '../../utils/axiosInstance';

const API_BASE_URL = env.REACT_APP_API_BASE_URL;

export async function Login(loginDetails: UserLogInRequest) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/login/login-webapp`,
      loginDetails
    );
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
}

export const Logout = async (data: UserLogoutRequest) => {
  try {
    const response = await axiosInstance.post(`/login/logout-webapp`, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const superUserIntoClient = async (data: superUserIntoClientRequest) => {
  try {
    const response = await axiosInstance.post(
      `/login/super-user-login-as-client`,
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const superUserLogoutOfClient = async (
  data: superUserIntoClientRequest
) => {
  try {
    const response = await axiosInstance.post(
      `/login/super-user-logout-of-client`,
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const sendOtpToEmail = async (email: string) => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/otp/send-otp-email`,
      { email }
    );

    if (response.data.statusCode === 201) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
        error: null,
      };
    }

    return {
      success: false,
      data: null,
      message: response.data.message,
      error: response.data.error,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const { statusCode, message, error: responseError } = error.response.data;

      return {
        success: false,
        data: null,
        message: message || 'Email not found in the system',
        error: responseError || 'Bad Request',
        statusCode: statusCode || 400,
      };
    }

    return {
      success: false,
      data: null,
      message: (error as Error).message,
      error: (error as Error).message,
      statusCode: 500,
    };
  }
};

export const verifyOtp = async (email: string, otp: string) => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/otp/verify-otp-email`,
      { email, otp }
    );

    if (response.data.statusCode === 201) {
      const { tempOtpToken } = response.data.data;
      return {
        success: true,
        tempOtpToken,
        data: response.data.data,
        message: response.data.message,
        error: null,
      };
    }

    return {
      success: false,
      data: null,
      message: response.data.message,
      error: response.data.error,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const { statusCode, message, error: responseError } = error.response.data;

      return {
        success: false,
        data: null,
        message: message,
        error: responseError,
        statusCode: statusCode || 400,
      };
    }

    return {
      success: false,
      data: null,
      message: (error as Error).message,
      error: (error as Error).message,
      statusCode: 500,
    };
  }
};

export const validatePasswordToken = async (token: string) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/otp/validate-token`,
      { token },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.statusCode === 201) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    }

    return {
      success: false,
      message: response.data.message,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to validate token',
      };
    } else {
      throw error;
    }
  }
};

export const resetPassword = async (
  email: string,
  password: string,
  token: string,
  tempToken?: string
) => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/user/reset-password`,
      { email, password, tempToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.statusCode === 201) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
        error: null,
      };
    }

    return {
      success: false,
      data: null,
      message: response.data.message,
      error: response.data.error,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const { statusCode, message, error: responseError } = error.response.data;

      return {
        success: false,
        data: null,
        message: message || 'Failed to reset password',
        error: responseError || 'Bad Request',
        statusCode: statusCode || 400,
      };
    }

    return {
      success: false,
      data: null,
      message: (error as Error).message,
      error: (error as Error).message,
      statusCode: 500,
    };
  }
};
