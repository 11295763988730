import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import LaptopIcon from '../../../assets/icons/laptop.svg';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { validateEmail } from '../../../utils/validationUtils';
import { useTranslation } from 'react-i18next';
import { verifyOtp, sendOtpToEmail } from '../../../services/Api/userService';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState('');
  const { t } = useTranslation();

  const isFormValid = otp.length === 6;
  const isEmailFormValid = !emailError && email !== '';

  const handleNavigateToSignIn = () => {
    navigate('/login');
  };

  const handleNavigateToCreatePassword = async () => {
    if (!isFormValid) return;

    setLoading(true);

    const response = await verifyOtp(email, otp);

    if (response.success) {
      const token = response.data?.tempOtpToken;
      navigate('/reset-password', { state: { email, token } });
    } else {
      setOtpError(response.message || 'OTP verification failed');
    }

    setLoading(false);
  };

  const handleResetPasswordClick = async () => {
    if (!isEmailFormValid) return;

    setLoading(true);

    const response = await sendOtpToEmail(email);

    if (response.success) {
      setShowOtpField(true);
      setOtpError('');
    } else {
      console.error('Error sending OTP:', response.message);
      setOtpError(response.message);
    }
    setLoading(false);
  };

  const handleResendOtpClick = async () => {
    if (!isEmailFormValid) return;

    setLoading(true);
    setOtpError('');

    const response = await sendOtpToEmail(email);

    if (!response.success) {
      setOtpError(response.message);
    }

    setLoading(false);
  };

  const handleOtpChange = (newValue: string) => {
    setOtp(newValue);
    setOtpError('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
    setOtpError('');
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          width: '21.5vw',
          height: '63.03vh',
          borderRadius: '16.224px',
          border: '2px solid',
          borderColor: 'var(--Primary-Lime, #B4EE2B)',
          backgroundColor: 'var(--white-100, #FFF)',
          padding: '4.69vh 3.70vw 6.56vh 3.70vw',
          flexDirection: 'column',
          alignItems: 'flex-end',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            paddingTop: '3.75vh',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.88vh',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '5.22vw',
              height: '9.24vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4.62vh',
            }}
          >
            <img
              src={LaptopIcon}
              style={{ width: '100%', height: '100%' }}
              alt="Laptop Not Found Icon"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              paddingTop: '2.25vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '1.85vh',
              alignSelf: 'stretch',
            }}
          >
            <Typography
              sx={{
                alignSelf: 'stretch',
                fontFamily: 'Open Sans',
                fontSize: '1.67vw',
                fontWeight: 600,
                lineHeight: '3.7vh',
                textAlign: 'center',
                color: '#000000',
              }}
            >
              {t('Forgot Password')}
            </Typography>

            <Typography
              sx={{
                alignSelf: 'stretch',
                fontFamily: 'Open Sans',
                fontSize: '0.73vw',
                fontWeight: 400,
                lineHeight: '1.85vh',
                textAlign: 'center',
                color: '#838383',
              }}
            >
              {t(
                'An OTP to reset your password will be sent to your email address'
              )}
              .
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              height: '1.88vh',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
            }}
          />
        </Box>

        {showOtpField && (
          <>
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '0.94vw',
                fontWeight: 600,
                lineHeight: '2.22vh',
                textAlign: 'center',
                color: '#000000',
                alignSelf: 'stretch',
                marginBottom: '1.46vw',
              }}
            >
              Enter 6-digit OTP
            </Typography>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '1.06vw',
            alignSelf: 'stretch',
          }}
        >
          {!showOtpField ? (
            <>
              <TextField
                placeholder={t('Email')}
                fullWidth
                value={email}
                onChange={handleEmailChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleResetPasswordClick();
                  }
                }}
                error={Boolean(
                  emailError || otpError === t('Email not found in the system')
                )}
                helperText={
                  emailError
                    ? t('Must be a valid email')
                    : otpError === t('Email not found in the system')
                    ? t('Email not found in the system')
                    : ''
                }
                slotProps={{
                  formHelperText: {
                    sx: {
                      textAlign: 'left',
                      marginLeft: '0px',
                      fontSize: '0.73vw',
                    },
                  },
                }}
                sx={{
                  'backgroundColor': '#FFFFFF',
                  'borderRadius': '0.42vw',
                  'marginBottom': '1.30vh',
                  '& .MuiOutlinedInput-root': {
                    'borderColor': '#C7C7C7',
                    '& fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#C7C7C7',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '0.73vw',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C7C7C7',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#C7C7C7',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#C7C7C7',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                }}
              />
            </>
          ) : (
            <>
              <MuiOtpInput
                value={otp}
                length={6}
                onChange={handleOtpChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isFormValid) {
                    handleNavigateToCreatePassword();
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    'borderRadius': '8.11px',
                    'border': `1.01px solid ${otpError ? 'red' : '#C7C7C7'}`,
                    'width': '2.35vw',
                    'height': '4.13vh',
                    '& fieldset': {
                      display: 'none',
                    },
                    '&.Mui-focused': {
                      borderColor: otpError ? 'red' : '#B4EE2B',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'Open Sans',
                    fontSize: '0.85vw',
                    fontWeight: 400,
                    lineHeight: '2.22vh',
                    textAlign: 'center',
                    paddingTop: '0.94vh',
                    paddingRight: '0.74vw',
                    paddingBottom: '0.94vh',
                    paddingLeft: '0.74vw',
                  },
                }}
              />
            </>
          )}
        </Box>

        <Typography
          sx={{
            color:
              otpError && otpError !== t('Email not found in the system')
                ? 'red'
                : 'transparent',
            fontSize: '0.73vw',
            marginTop: '0px',
            textAlign: 'center',
            alignSelf: 'stretch',
          }}
        >
          {otpError && otpError !== t('Email not found in the system')
            ? otpError
            : 'PLACEHOLDER'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            paddingTop: '1.7vh',
            flexDirection: 'column',
            alignItems: 'flex-end',
            flexShrink: 0,
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.64vw',
              alignSelf: 'stretch',
            }}
          >
            {!showOtpField ? (
              <Button
                sx={{
                  backgroundColor: isEmailFormValid ? '#000000' : '#EEEEEE',
                  color: isEmailFormValid ? '#FFFFFF' : '#838383',
                  padding: '0.84vw 1.26vw',
                  borderRadius: '0.84vw',
                  width: '100%',
                  fontSize: '0.94vw',
                  lineHeight: '1.46vw',
                  fontFamily: "'Open Sans', sans-serif",
                  textAlign: 'center',
                  textTransform: 'none',
                  display: 'inline-block',
                  position: 'relative',
                  cursor: isEmailFormValid ? 'pointer' : 'not-allowed',
                }}
                disabled={!isEmailFormValid}
                onClick={handleResetPasswordClick}
              >
                {t('Send Email')}
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
                  color: isFormValid ? '#FFFFFF' : '#838383',
                  padding: '0.84vw 1.26vw',
                  borderRadius: '0.84vw',
                  width: '100%',
                  fontSize: '0.94vw',
                  lineHeight: '1.46vw',
                  fontFamily: "'Open Sans', sans-serif",
                  textAlign: 'center',
                  textTransform: 'none',
                  display: 'inline-block',
                  position: 'relative',
                  cursor: isFormValid ? 'pointer' : 'not-allowed',
                }}
                onClick={handleNavigateToCreatePassword}
                disabled={!isFormValid}
              >
                {t('Confirm OTP')}
              </Button>
            )}
          </Box>
        </Box>

        {!showOtpField ? (
          <Typography
            sx={{
              alignSelf: 'stretch',
              fontSize: '0.73vw',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '1.04vw',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              marginTop: '0.64vw',
            }}
          >
            {t('Go back to')}{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '0.73vw',
                color: '#86BE02',
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                lineHeight: '1.04vw',
              }}
              onClick={handleNavigateToSignIn}
            >
              {t('Sign In')}
            </Typography>
          </Typography>
        ) : (
          <Typography
            sx={{
              alignSelf: 'stretch',
              fontSize: '0.73vw',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '1.04vw',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              cursor: 'pointer',
              marginTop: '0.64vw',
            }}
            onClick={handleResendOtpClick}
          >
            {t('Resend OTP')}
          </Typography>
        )}
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            sx={{ color: '#B4EE2B', width: '6vw', height: '6vw' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ForgotPasswordComponent;
