import React, { useState, FC, useEffect } from 'react';
import { Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { useLocation, useParams } from 'react-router-dom';
import useDebounce from '../../../utils/useDebounce';
import RTLHistoryTableComponent from '../../../components/Table/RTLHistoryTable';
import { ReactComponent as RtlActivityLogTemperature } from '../../../assets/icons/rtl-temp.svg';
import { ReactComponent as RtlActivityLogHumidity } from '../../../assets/icons/rtl-humidity.svg';
import { ReactComponent as RtlActivityLogLux } from '../../../assets/icons/rtl-lux.svg';
import { ReactComponent as RtlActivityLogShock } from '../../../assets/icons/rtl-shock.svg';
// import RTLHumidity from '../../../../../assets/icons/rtl-humidity.svg';
// import RTLLux from '../../../../../assets/icons/rtl-lux.svg';
// import RTLTemp from '../../../../../assets/icons/rtl-temp.svg';
// import RTLShock from '../../../../../assets/icons/rtl-shock.svg';
import { Box } from '@mui/material';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { fetchRTLHistory } from '../../../services/Api/apiRTL';

interface RecentWarningsPageProps {
  rtlId?: string;
}

interface WarningData {
  id: string;
  timestamp: string;
  type: string;
  value: string;
  location: string;
  driver?: string;
  lat?: string;
  lng?: string;
}

// const mockWarnings: WarningData[] = [
//   {
//     id: '1',
//     timestamp: '2024-03-05 10:30:00',
//     type: 'Temperature',
//     value: '22',
//     location: 'Warehouse A',
//     driver: 'John Doe',
//   },
//   {
//     id: '2',
//     timestamp: '2024-03-05 09:45:00',
//     type: 'Humidity',
//     value: '55',
//     location: 'Warehouse B',
//     driver: 'Jane Doe',
//   },
//   {
//     id: '3',
//     timestamp: '2024-03-05 08:15:00',
//     type: 'Shock',
//     value: '1',
//     location: 'Warehouse C',
//     driver: 'Steve Smith',
//   },
//   {
//     id: '4',
//     timestamp: '2024-03-05 07:00:00',
//     type: 'Light',
//     value: '1000',
//     location: 'Warehouse D',
//   },
// ];

const RecentWarningsPage: FC<RecentWarningsPageProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const rtlData = location.state?.rtlData;

  const { id } = useParams();
  const [selectedType, setSelectedType] = useState<string>('All');
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [cookies] = useCookies(['user', 'super_user', 'token']);
  
  const [warningsData, setWarningsData] = useState<WarningData[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
    setCurrentPage(1);
  };
  const handleLocationClick = (latitude: string, longitude: string) => {
    setLocation(
      parseFloat(latitude as string),
      parseFloat(longitude as string)
    );
    navigate('/');
  };

  interface WarningDataWithIcon {
    id: string;
    timestamp: string;
    type: React.ReactNode;
    value: string;
    location?: string;
    driver?: string;
    lat?: string;
    lng?: string;
  }

  const fetchWarnings = async () => {
    try {
      const rtlId = rtlData?.id;
      if (!rtlId) {
        throw new Error('No RTL ID provided');
      }
      let userId = '';
      if (cookies.user) {
        const userData = cookies.user;
        if (userData.id) {
          userId = userData.id;
        }
      }

      const response = await fetchRTLHistory(rtlId, userId);

      // Extract the history array from the nested response
      const warningHistory = response?.data?.history;

      if (!Array.isArray(warningHistory)) {
        console.error('Invalid response format:', response);
        throw new Error('Invalid response format from server');
      }

      const filteredData: WarningDataWithIcon[] = warningHistory
        .filter((warning) => {
          const matchesSearch = Object.values(warning).some((value) =>
            String(value)
              .toLowerCase()
              .includes(debouncedSearchValue.toLowerCase())
          );
          const matchesType =
            selectedType === 'All' || warning.type === selectedType;
          return matchesSearch && matchesType;
        })
        .map((warning) => ({
          ...warning,
          timestamp: formatTimestamp(warning.timestamp),
          location: 'View on Map', // Add default location text
          lat: warning.latitude, // Map latitude to lat
          lng: warning.longitude, // Map longitude to lng
          type: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {getIconForType(warning.type) &&
                React.createElement(getIconForType(warning.type)!, {
                  style: {
                    width: '20px',
                    height: '20px',
                    filter: 'brightness(0)',
                  },
                })}
            </Box>
          ),
          value: `${warning.value}${getUnitForType(warning.type)}`,
        }));

      setWarningsData(filteredData as WarningData[]);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => fetchWarnings);
      setSnackbarOpen(true);
    }
  };

  // Update the useEffect to not depend on rtlData.id
  useEffect(() => {
    fetchWarnings();
  }, [debouncedSearchValue, selectedType]);

  useEffect(() => {
    if (rtlData?.id) {
      fetchWarnings();
    }
  }, [rtlData, debouncedSearchValue]);

  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInMinutes = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60)
    );

    // Format time in HH:MM
    const timeString = date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    if (diffInMinutes < 1) {
      return `Just now - ${timeString}`;
    } else if (diffInMinutes === 1) {
      return `1 minute ago - ${timeString}`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago - ${timeString}`;
    } else if (diffInMinutes < 1440) {
      // less than 24 hours
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago - ${timeString}`;
    } else {
      return timeString;
    }
  };

  const getUnitForType = (type: string): string => {
    switch (type.toLowerCase()) {
      case 'temperature':
        return '°C';
      case 'humidity':
        return '%';
      case 'light':
        return ' lux';
      case 'shock':
        return 'g';
      default:
        return '';
    }
  };

  const getIconForType = (type: string) => {
    switch (type.toLowerCase()) {
      case 'temperature':
        return RtlActivityLogTemperature;
      case 'humidity':
        return RtlActivityLogHumidity;
      case 'light':
        return RtlActivityLogLux;
      case 'shock':
        return RtlActivityLogShock;
      default:
        return () => null;
    }
  };

  // Table configuration
  // Update the table configuration
  const columns = ['Type', 'Value', 'Time', 'Location', 'Graph', 'Driver'];
  const columnFieldMapping = {
    Type: 'type',
    Value: 'value',
    Time: 'timestamp',
    Location: 'location',
    Graph: 'graph',
    Driver: 'driver',
  };
  const columnWidths = ['5%', '5%', '10%', '10%', '55%', '10%'];

  const handleGraphClick = () => {
    const rtlId = id || rtlData?.id;
    if (rtlId) {
      navigate(`/rtl/${rtlId}`, {
        state: {
          rtlData: {
            id: rtlData?.id,
            rtl_id: rtlId,
            alias: `RTL ${rtlId}`,
          },
        },
      });
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{
        'width': '100%',
        'height': '100%',
        'padding': '20px',
        '& .MuiTableRow-root': {
          minHeight: '52px',
          height: '52px',
        },
      }}
    >
      <Grid2 sx={{ width: '100%' }}>
        <RTLHistoryTableComponent
          title={t('Recent Warnings')}
          onSearchValueChange={handleSearchChange}
          onPageChange={() => {}}
          setCurrentPage={setCurrentPage}
          searchValue={searchValue}
          columns={columns}
          data={warningsData}
          hasMore={false}
          columnFieldMapping={columnFieldMapping}
          columnWidths={columnWidths}
          selectedType={selectedType}
          onTypeChange={handleTypeChange}
          onLocationClick={handleLocationClick}
          onGraphClick={handleGraphClick}
        />
      </Grid2>

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
};

export default RecentWarningsPage;
