import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import Navbar from './components/Navbar/Navbar';
import AdminNavbar from './components/Navbar/AdminNavbar';
import Vehicles from './pages/Customer/Vehicles/Vehicles';
import Dashboard from './pages/Customer/Dashboard/Dashboard';
import Drivers from './pages/Customer/Drivers/Drivers';
import Operators from './pages/Customer/Operators/Operators';
import Seals from './pages/Customer/Seals/Seals';
import Locations from './pages/Customer/Locations/Locations';
import History from './pages/Customer/History/History';
import RoutesPage from './pages/Customer/Routes/Routes';
import ViewDataPage from './pages/Customer/RTL/ViewData';
import RecentWarningsPage from './pages/Customer/RTL/RecentWarnings';
import RTLToleranceProfiles from './pages/Customer/RTL/RTLToleranceProfiles';
import Users from './pages/Customer/Users/Users';
import Settings from './pages/Customer/Settings/Settings';
import Support from './pages/Support/Support';
import Login from './pages/Login/Login';
import AdminDashboard from './pages/Admin/Dashboard/AdminDashboard';
import AdminClient from './pages/Admin/Client/AdminClient';
import AdminSeal from './pages/Admin/Seal/AdminSeal';
import PrivateRoute from './PrivateRoute';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import NotFound from './pages/404/NotFound';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import CreateNewPassword from './pages/CreateNewPassword/CreateNewPassword';
import CreateFirstPassword from './pages/CreateNewPassword/CreateFirstPassword';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import Footer from './components/Footer/Footer';
import NotificationManager from './components/EmergencyNotifications/NotificationManager';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import { Logout } from './services/Api/userService';
import ClientAccessIndicator from './components/ClientAccessIndicator/ClientAccessIndicator';
import Rtls from './pages/Customer/RTL/Rtl';
import { RtlDevicesProvider } from './context/RtlDevicesContext';

const BackgroundContainer = styled(Box)`
  display: flex;
`;

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <RtlDevicesProvider>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute element={Dashboard} />} />
              <Route
                path="/vehicles"
                element={<PrivateRoute element={Vehicles} />}
              />
              <Route
                path="/drivers"
                element={<PrivateRoute element={Drivers} />}
              />
              <Route
                path="/operators"
                element={<PrivateRoute element={Operators} />}
              />
              <Route path="/seals" element={<PrivateRoute element={Seals} />} />
              <Route path="/rtl" element={<PrivateRoute element={Rtls} />} />
              <Route
                path="/locations"
                element={<PrivateRoute element={Locations} />}
              />
              <Route
                path="/vehicles/:id"
                element={<PrivateRoute element={RoutesPage} />}
              />
              <Route
                path="/seals/:id"
                element={<PrivateRoute element={RoutesPage} />}
              />
              <Route
                path="/rtl/recent-warnings/:id"
                element={<PrivateRoute element={RecentWarningsPage} />}
              />
              <Route
                path="/rtl/:id"
                element={<PrivateRoute element={ViewDataPage} />}
              />

              <Route
                path="/rtl/Tolerance-Profiles"
                element={<PrivateRoute element={RTLToleranceProfiles} />}
              />
              <Route path="/users" element={<PrivateRoute element={Users} />} />
              <Route
                path="/settings"
                element={<PrivateRoute element={Settings} />}
              />
              <Route
                path="/admin"
                element={<PrivateRoute element={AdminDashboard} adminOnly />}
              />
              <Route
                path="/admin/clients"
                element={<PrivateRoute element={AdminClient} adminOnly />}
              />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
              <Route path="/support" element={<Support />} />
              <Route
                path="/admin/seals"
                element={<PrivateRoute element={AdminSeal} adminOnly />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<CreateNewPassword />} />
              <Route
                path="/create-password"
                element={<CreateFirstPassword />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </RtlDevicesProvider>
    </I18nextProvider>
  );
}

function Layout() {
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies(['user', 'super_user', 'token']);
  const isAdmin = location.pathname.includes('/admin');

  const isLoginPage = location.pathname === '/login';
  const isDashboardPage = location.pathname === '/';
  const isForgotPassword = location.pathname === '/forgot-password';
  const isResetPassword = location.pathname === '/reset-password';
  const isCreatePassword = location.pathname === '/create-password';
  const isPrivacyPolicy = location.pathname === '/privacy-policy';

  const isNotFoundPage =
    location.pathname === '*' ||
    (![
      '/',
      '/login',
      '/vehicles',
      '/drivers',
      '/operators',
      '/seals',
      '/rtl',
      '/locations',
      '/history',
      '/users',
      '/settings',
      '/admin',
      '/termsandconditions',
      '/support',
      '/admin/clients',
      '/admin/seals',
      '/forgot-password',
    ].includes(location.pathname) &&
      !location.pathname.includes('/vehicles/') &&
      !location.pathname.includes('/seals/') &&
      !location.pathname.includes('/rtl/'));

  const originalCookie = useState(cookies);
  const [prevCookies, setPrevCookies] = useState(originalCookie);

  useEffect(() => {
    if (
      cookies.token &&
      prevCookies !== cookies &&
      prevCookies.token !== undefined
    ) {
      if (
        prevCookies.user.email !== cookies.user.email ||
        prevCookies.token !== cookies.token ||
        prevCookies.super_user !== cookies.super_user ||
        prevCookies.role !== cookies.role
      ) {
        Logout({
          email: prevCookies.user.email,
          token: prevCookies.token,
        });
        removeCookie('user', { path: '/' });
        removeCookie('super_user', { path: '/' });
        removeCookie('role', { path: '/' });
        removeCookie('token', { path: '/' });
        removeCookie('settings', { path: '/' });
        setPrevCookies(cookies);
      }
    } else {
      setPrevCookies(cookies);
    }
  }, [location, cookies]);

  return (
    <BackgroundContainer
      sx={{
        backgroundColor: '#F5F5F5',
      }}
    >
      {!isLoginPage &&
        !isNotFoundPage &&
        !isForgotPassword &&
        !isAdmin &&
        !isPrivacyPolicy &&
        !isResetPassword &&
        !isCreatePassword && <Navbar />}
      {!isLoginPage &&
        !isNotFoundPage &&
        !isForgotPassword &&
        !isAdmin &&
        !isPrivacyPolicy &&
        !isResetPassword & !isCreatePassword && <NotificationManager />}
      {!isLoginPage &&
        !isNotFoundPage &&
        !isForgotPassword &&
        !isPrivacyPolicy &&
        isAdmin &&
        !isResetPassword &&
        !isCreatePassword && <AdminNavbar />}
      {!isAdmin && cookies.super_user && <ClientAccessIndicator />}
      <Box
        component="main"
        flex={1}
        sx={{
          height: '100vh',
        }}
      >
        {!isLoginPage &&
          !isDashboardPage &&
          !isNotFoundPage &&
          !isForgotPassword &&
          !isPrivacyPolicy &&
          !isResetPassword &&
          !isCreatePassword && <Breadcrumbs />}
        <Routes>
          <Route path="/" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/vehicles"
            element={<PrivateRoute element={Vehicles} />}
          />
          <Route path="/drivers" element={<PrivateRoute element={Drivers} />} />
          <Route
            path="/operators"
            element={<PrivateRoute element={Operators} />}
          />
          <Route path="/seals" element={<PrivateRoute element={Seals} />} />
          <Route path="/rtl" element={<PrivateRoute element={Rtls} />} />
          <Route
            path="/locations"
            element={<PrivateRoute element={Locations} />}
          />
          <Route path="/history" element={<PrivateRoute element={History} />} />
          <Route
            path="/vehicles/:id"
            element={<PrivateRoute element={RoutesPage} />}
          />
          <Route
            path="/seals/:id"
            element={<PrivateRoute element={RoutesPage} />}
          />
          <Route
            path="/rtl/recent-warnings/:id"
            element={<PrivateRoute element={RecentWarningsPage} />}
          />
          <Route
            path="/rtl/:id"
            element={<PrivateRoute element={ViewDataPage} />}
          />
          <Route
            path="/rtl/tolerance-Profiles"
            element={<PrivateRoute element={RTLToleranceProfiles} />}
          />
          <Route path="/users" element={<PrivateRoute element={Users} />} />
          <Route
            path="/settings"
            element={<PrivateRoute element={Settings} />}
          />
          <Route
            path="/admin"
            element={<PrivateRoute element={AdminDashboard} adminOnly />}
          />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/support" element={<Support />} />

          <Route
            path="/admin/clients"
            element={<PrivateRoute element={AdminClient} adminOnly />}
          />
          <Route
            path="/admin/seals"
            element={<PrivateRoute element={AdminSeal} adminOnly />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<CreateNewPassword />} />
          <Route path="/create-password" element={<CreateFirstPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!isLoginPage &&
          !isDashboardPage &&
          !isNotFoundPage &&
          !isForgotPassword &&
          !isPrivacyPolicy &&
          !isResetPassword &&
          !isCreatePassword && <Footer />}
      </Box>
    </BackgroundContainer>
  );
}

export default App;
