import React, { useEffect, useState } from 'react';
import { Grid2 } from '@mui/material';
import TableComponent from '../../../components/Table/TableComponent';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../utils/useDebounce';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as ChartIcon } from '../../../assets/icons/chart.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import EditRtlModal from './Components/EditRtlModal';
import AssignToVehicleModal from './Components/AssignToVehicleModal';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import {
  assignRtlToVehicle,
  fetchRtlServiceList,
  Profile,
  renameRtl,
  RtlDevice,
  RtlListRequest,
  Vehicle,
} from '../../../services/Api/apiRTL';
import { setLocation } from '../../../utils/locationUtil';
import { ReactComponent as WarningIcon } from '../../../assets/icons/new-warning-icon.svg';

function Rtls() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cookies] = useCookies(['user', 'role']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [rtlData, setRtlData] = useState<RtlDevice[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);

  // States for vehicle assignment
  const [selectedRTL, setSelectedRTL] = useState<RtlDevice | null>(null);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [assignToVehicleFormValues, setAssignToVehicleFormValues] = useState<{
    vehicle: string;
  }>({ vehicle: '' });

  // States for RTL renaming
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [rtlToRename, setRtlToRename] = useState<RtlDevice | null>(null);
  const [renameFormValues, setRenameFormValues] = useState<{ RTL_ID: string }>({
    RTL_ID: '',
  });

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  // Modal close handlers
  const closeAssignModal = () => setOpenAssignModal(false);
  const closeRenameModal = () => setOpenRenameModal(false);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  // Handle location click
  const handleLocationClick = (latitude: string, longitude: string) => {
    setLocation(
      parseFloat(latitude as string),
      parseFloat(longitude as string)
    );
    navigate('/');
  };

  // Fetch RTL data
  const fetchData = async () => {
    if (!cookies.user?.company_id) {
      return;
    }

    try {
      const userId = cookies.user.company_id;

      const requestParams: RtlListRequest = {
        company_id: userId,
        search_term: debouncedSearchValue || undefined,
      };

      const response = await fetchRtlServiceList(requestParams);

      setRtlData(response.data.data);
      setProfiles(response.data.profiles);
      setVehicles(response.data.vehicles);
    } catch (error: unknown) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => fetchData);
      setSnackbarOpen(true);
    }
  };

  // Fetch data when search term or user changes
  useEffect(() => {
    fetchData();
  }, [debouncedSearchValue, cookies.user]);

  // Handle assigning RTL to vehicle
  const handleAssignRtlToVehicle = async () => {
    if (!selectedRTL || !assignToVehicleFormValues.vehicle) {
      return;
    }

    try {
      const selectedVehicle = vehicles.find(
        (vehicle) =>
          vehicle.vehicle_registration === assignToVehicleFormValues.vehicle
      );

      if (!selectedVehicle) {
        throw new Error('Selected vehicle not found');
      }

      await assignRtlToVehicle({
        rtl_id: selectedRTL.id,
        vehicle_id: selectedVehicle.id,
      });

      setSnackbarMessage(t('RTL successfully assigned to vehicle'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      closeAssignModal();

      fetchData();
    } catch (error: unknown) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Open rename modal with the selected RTL
  const openRenameModalForRtl = (rtl: RtlDevice) => {
    setRtlToRename(rtl);
    setRenameFormValues({ RTL_ID: rtl.alias || rtl.rtl_id });
    setOpenRenameModal(true);
  };

  // Handle RTL rename submission
  const handleRenameSubmit = async () => {
    if (!rtlToRename || !renameFormValues.RTL_ID.trim()) {
      return;
    }

    try {
      await renameRtl({
        rtl_id: rtlToRename.id,
        new_alias: renameFormValues.RTL_ID.trim(),
      });

      setSnackbarMessage(t('RTL successfully renamed'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      closeRenameModal();

      fetchData();
    } catch (error: unknown) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // -------- TABLE CONFIGURATION --------
  // Column mapping for the table
  const columnFieldMapping = {
    'RTL Alias': 'alias',
    'Status': 'status',
    'Location': 'location',
    'Temperature': 'temperature',
    'Humidity': 'humidity',
    'Light': 'light',
    'Last Shock': 'latest_shock_time',
  };

  const columns = Object.keys(columnFieldMapping);
  const columnWidths = Array(columns.length).fill(`${100 / columns.length}%`);

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const rtlDevice = row as unknown as RtlDevice;
        setSelectedRTL(rtlDevice);
        if (row.lat && row.lng) {
          handleLocationClick(row.lat as string, row.lng as string);
        }
      },
      disabled: false,
    },
    {
      label: 'View Data',
      icon: <ChartIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const rtlDevice = row as unknown as RtlDevice;
        navigate(`/rtl/${rtlDevice.rtl_id}`, { state: { rtlData: rtlDevice } });
      },
      disabled: row.status === 'DEACTIVATED',
    },
    {
      label: 'Rename RTL',
      icon: <EditIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const rtlDevice = row as unknown as RtlDevice;
        openRenameModalForRtl(rtlDevice);
      },
      disabled: false,
    },
    {
      label: 'Assign to Vehicle',
      icon: <TruckIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const rtlDevice = row as unknown as RtlDevice;
        setSelectedRTL(rtlDevice);
        setOpenAssignModal(true);
      },
    },
    {
      label: 'View Warning Logs',
      icon: (
        // WIP needs to be changed
        <WarningIcon
          style={{
            width: '18px',
            marginRight: '8px',
            filter: 'grayscale(100%)',
          }}
        />
      ),
      onClick: () => {
        const rtlDevice = row as unknown as RtlDevice;
        navigate(`/rtl/recent-warnings/${rtlDevice.rtl_id}`, {
          state: { rtlData: rtlDevice },
        });
      },
    },
  ];

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2 sx={{ width: '100%' }}>
        <TableComponent
          title={t('RTL List')}
          onSearchValueChange={handleSearchChange}
          onPageChange={() => {}}
          setCurrentPage={setCurrentPage}
          searchValue={searchValue}
          columns={columns}
          data={rtlData}
          hasMore={false}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onLocationClick={handleLocationClick}
        />
      </Grid2>

      <EditRtlModal
        open={openRenameModal}
        onClose={closeRenameModal}
        formValues={renameFormValues}
        setFormValues={setRenameFormValues}
        onSubmit={handleRenameSubmit}
        rtlDevice={rtlToRename}
      />

      <AssignToVehicleModal
        open={openAssignModal}
        onClose={closeAssignModal}
        RtlDetails={selectedRTL || {}}
        vehicles={vehicles}
        onSubmit={handleAssignRtlToVehicle}
        formValues={assignToVehicleFormValues}
        setFormValues={setAssignToVehicleFormValues}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Rtls;
