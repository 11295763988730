import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RtlDevice } from '../../../../services/Api/apiRTL';

interface EditRTLModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formValues: { RTL_ID: string };
  setFormValues: React.Dispatch<React.SetStateAction<{ RTL_ID: string }>>;
  rtlDevice?: RtlDevice | null;
}

const EditRtlModal: React.FC<EditRTLModalProps> = ({
  open,
  onClose,
  onSubmit,
  formValues,
  setFormValues,
  rtlDevice,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({ RTL_ID: '' });
    }
  }, [open, setFormValues]);

  const { t } = useTranslation();

  const isFormValid = formValues.RTL_ID.trim() !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Rename RTL')}</DialogTitle>
        <Typography
          variant="subtitle1"
          sx={{ paddingLeft: 3, paddingRight: 3 }}
        >
          {t(
            'Create an Alias or ‘nickname’ for RTL {{RTL_Serialnumber}} to make it easier to identify.',
            { RTL_Serialnumber: rtlDevice?.rtl_id || '' }
          )}
        </Typography>
        <DialogContent>
          <Typography variant="subtitle2">{t('Alias')}*</Typography>
          <TextField
            placeholder={t('Enter Alias')}
            type="text"
            value={formValues.RTL_ID}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                RTL_ID: e.target.value,
              })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {t('Create Alias')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditRtlModal;
