import { AxiosError } from 'axios';
import axiosInstance from '../../utils/axiosInstance';

export interface RtlGraphRequest {
  rtl_id: string;
  start_time: string;
  end_time: string;
  user_id: string;
}

export interface RtlDataPoint {
  'time': string;
  'shock'?: number;
  'light'?: number;
  'humidity 1'?: number;
  'humidity 2'?: number;
  'temp 1'?: number;
  'temp 2'?: number;
  'tilt_x'?: number;
  'tilt_y'?: number;
  'tilt_z'?: number;
}

export interface RtlProfileSettings {
  id: string;
  alias: string;
  company_id: string;
  temperature_upper_range: number;
  temperature_lower_range: number;
  temperature_upper_tolerance: number;
  temperature_lower_tolerance: number;
  humidity_upper_range: number;
  humidity_lower_range: number;
  humidity_upper_tolerance: number;
  humidity_lower_tolerance: number;
  light_upper_range: number;
  light_lower_range: number;
  light_upper_tolerance: number | null;
  light_lower_tolerance: number | null;
  shock_upper_range: number;
  shock_lower_range: number;
  shock_upper_tolerance: number | null;
  shock_lower_tolerance: number | null;
  date_created: string;
  date_updated: string;
}

export interface RtlGraphResponse {
  statusCode: number;
  data: {
    id: string;
    device_serial: string;
    data: {
      DeviceID: string;
      data: RtlDataPoint[];
    };
    profile: RtlProfileSettings;
  };
  message: string;
  error: null | string;
}

export const fetchRtlGraphData = async (
  params: RtlGraphRequest
): Promise<RtlGraphResponse> => {
  try {
    const response = await axiosInstance.post<RtlGraphResponse>(
      '/rtl-service/rtl-graph',
      params
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw {
      status: axiosError.response?.status || 500,
      message: axiosError.message || 'Error fetching RTL graph data',
    };
  }
};

export const formatDateForApi = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const processRtlDataForChart = (
  dataPoints: RtlDataPoint[],
  metric: 'temperature' | 'humidity' | 'light' | 'shock'
): unknown => {
  const filteredDataPoints = dataPoints.filter((point) => {
    if (metric === 'temperature') {
      return point['temp 1'] !== undefined;
    }
    if (metric === 'humidity') {
      return point['humidity 1'] !== undefined;
    }
    if (metric === 'light') {
      return point.light !== undefined;
    }
    if (metric === 'shock') {
      return (
        point.shock !== undefined ||
        point.tilt_x !== undefined ||
        point.tilt_y !== undefined ||
        point.tilt_z !== undefined
      );
    }
    return false;
  });

  const processedData = {
    HourlyData: filteredDataPoints.map((point) => {
      let date = '';
      let time = '';

      if (point.time) {
        const timeParts = point.time.split(' ');
        if (timeParts.length === 2) {
          date = timeParts[0];
          time = timeParts[1];
        } else {
          time = point.time;
        }
      }

      const processedPoint: { [key: string]: string | number } = {
        date: date,
        hour: time,
      };

      if (point['temp 1'] !== undefined) {
        processedPoint.Temperature = point['temp 1'];
      }
      if (point['humidity 1'] !== undefined) {
        processedPoint.Humidity = point['humidity 1'];
      }
      if (point.light !== undefined) {
        processedPoint.Light = point.light;
      }
      if (point.shock !== undefined) {
        processedPoint.Shock = point.shock;
      }
      if (metric === 'shock') {
        if (point.tilt_x !== undefined) {
          processedPoint.Tilt_x = point.tilt_x;
        }
        if (point.tilt_y !== undefined) {
          processedPoint.Tilt_y = point.tilt_y;
        }
        if (point.tilt_z !== undefined) {
          processedPoint.Tilt_z = point.tilt_z;
        }
      }

      return processedPoint;
    }),
  };

  return processedData;
};
