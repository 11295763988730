import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid2,
  Typography,
  Button,
  Divider,
  Tooltip,
} from '@mui/material';
import {
  CompanyDriverCardsResponse,
  RTLInfo,
} from '../../../interfaces/Driver/Response/companyDriverCards';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons/route.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CircleIcon } from '../../../assets/icons/circle.svg';
import { useTranslation } from 'react-i18next';
import { getDriverId } from '../../../utils/expandDriverCard';
import RTLBox from './Components/DriverCards/RTLDropDown';
import ButtonBox from './Components/DriverCards/IMSealLockButtons';
import RTLGreen from '../../../../src/assets/icons/rtl-green-large.svg';
import RTLOrange from '../../../../src/assets/icons/rtl-orange-large.svg';
import WarningRed from '../../../../src/assets/icons/warning-red.svg';
import RTLUndefined from '../../../../src/assets/icons/rtl-undefined.svg';

interface DriverCardListProps {
  drivers: CompanyDriverCardsResponse[];
  expandedCardId: string;
  handleCardClick: (id: string, latitude: string, longitude: string) => void;
  handleRtlClick?: (rtlInfo: RTLInfo) => void;
  handleUnlockLeftClick: (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => void;
  handleUnlockRightClick: (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => void;
  handleEditRouteClick: (event: React.MouseEvent, id: string) => void;
  setLeftLock: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentLockState: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSeal: React.Dispatch<React.SetStateAction<string>>;
  setOpenLocking: React.Dispatch<React.SetStateAction<boolean>>;
  isSmallViewport: boolean;
}

const DriverCardList: React.FC<DriverCardListProps> = ({
  drivers,
  expandedCardId,
  handleCardClick,
  handleUnlockLeftClick,
  handleUnlockRightClick,
  handleEditRouteClick,
  isSmallViewport,
  handleRtlClick,
}) => {
  const { t } = useTranslation();
  const buttonFontSize = isSmallViewport ? '0.6rem' : '0.7rem';
  const iconWidth = isSmallViewport ? '12px' : '20px';
  const addressOverflow = isSmallViewport ? '170px' : '240px';
  const lockIconWidth = isSmallViewport ? '14px' : '18px';
  // const summaryOverflow = isSmallViewport ? '130px' : '200px';
  const routeInfoSize = isSmallViewport ? '13px' : '14px';
  const addressSize = isSmallViewport ? '13px' : '14px';
  const addressMargin = isSmallViewport ? '2px' : '10px';
  const iconBorderSize = isSmallViewport ? '38px' : '48px';
  const [componentLoadTime] = useState<Date>(new Date());
  const [rtlBoxHeight, setRtlBoxHeight] = useState(44);
  const [buttonBoxHeight, setButtonBoxHeight] = useState(44);
  const [expandedBox, setExpandedBox] = useState<'buttonBox' | string | null>(
    null
  );

  const handleRtlBoxToggle = (
    id: string,
    height: number,
    expanded: boolean
  ) => {
    if (expandedBox === id) {
      setRtlBoxHeight(expanded ? height : 44);
    }
    setExpandedBox(expanded ? id : null);
  };

  const handleButtonBoxToggle = (height: number, expanded: boolean) => {
    setButtonBoxHeight(expanded ? height : 44);
    setExpandedBox(expanded ? 'buttonBox' : null);
  };
  useEffect(() => {
    if (expandedCardId === '' || expandedBox === null) {
      setRtlBoxHeight(44);
      setButtonBoxHeight(44);
      setExpandedBox(null);
    } else if (expandedBox === 'buttonBox') {
      setRtlBoxHeight(44);
    } else if (expandedBox !== null) {
      setButtonBoxHeight(44);
    }
  }, [expandedBox, expandedCardId]);

  useEffect(() => {
    setRtlBoxHeight(44);
    setButtonBoxHeight(44);
    setExpandedBox(null);
  }, [expandedCardId]);

  const hasUnseenAlerts = (rtlInfo: RTLInfo[]) => {
    return rtlInfo.some((info) =>
      info.alerts?.some((alert) => alert.seen === false)
    );
  };

  const getBorderColor = (driverId: string) => {
    const driver = drivers.find((d) => d.id === driverId);

    if (!driver?.rtl_info || driver.rtl_info.length === 0) {
      return undefined;
    }

    const isExpanded = driverId === expandedCardId;
    const borderWidth = isExpanded ? '2px' : '1px';

    const hasAlert = driver.rtl_info.some((info) =>
      ['ALERT'].includes(info.status)
    );
    const hasWarning = driver.rtl_info.some((info) =>
      ['WARNING', 'RECENT_WARNING', 'RECENT_ALERT'].includes(info.status)
    );
    const hasNoConnection = driver.rtl_info.some((info) =>
      ['NO_CONNECTION'].includes(info.status)
    );
    const allConnected = driver.rtl_info.every(
      (info) => info.status === 'CONNECTED'
    );

    if (getDriverId() !== '' && isExpanded) {
      return '2px solid #DD3333';
    }

    if (hasAlert) {
      return `${borderWidth} solid #DD3333`;
    } else if (hasWarning) {
      return `${borderWidth} solid #FF9500`;
    } else if ((allConnected || hasNoConnection) && isExpanded) {
      return '2px solid #B4EE2B';
    }

    return undefined;
  };

  const getMostSevereStatus = (rtlInfo: { status: string }[]) => {
    if (rtlInfo.some((info) => ['ALERT'].includes(info.status))) {
      return 'ALERT';
    }
    if (
      rtlInfo.some((info) =>
        ['WARNING', 'RECENT_WARNING', 'RECENT_ALERT'].includes(info.status)
      )
    ) {
      return 'WARNING';
    }
    if (rtlInfo.every((info) => info.status === 'CONNECTED')) {
      return 'CONNECTED';
    }
    return 'NO_CONNECTION';
  };

  const getIcon = (status: string) => {
    switch (status) {
      case 'CONNECTED':
        return RTLGreen;
      case 'NO_CONNECTION':
        return RTLUndefined;
      case 'WARNING':
        return RTLOrange;
      case 'RECENT_WARNING':
        return RTLOrange;
      case 'ALERT':
        return WarningRed;
      case 'RECENT_ALERT':
        return RTLOrange;
      default:
        return RTLUndefined;
    }
  };

  return (
    <Grid2 container direction={'column'} display={'flex'} spacing={1.2}>
      {/* adjusting height of the driver card based on WHAT IS SHOWN */}
      {/* important to note, the height of the driver card is calculated based on the number of RTLs and the unlock button component being rendered is based on if a seal_id is '' or not*/}
      {/* the base height of the card is the base 338px and the height of the driver details component is 178px */}
      {/* depending on what box is expanded or open in state - will calculate the height of the parent container */}

      {drivers.map((driver) => {
        const rtlBoxCount = driver.rtl_info?.length || 0;
        const buttonBox = buttonBoxHeight + 12;
        const totalRtlHeight =
          (rtlBoxCount - 1) * 44 +
          (expandedBox && expandedBox !== 'buttonBox' ? rtlBoxHeight : 44);
        const driverDetails = driver.name !== '' ? 172 : 0;
        const calculatedHeight =
          expandedCardId === driver.id
            ? `calc(338px + ${
                driver.seal_id !== '' ? buttonBox : 0
              }px + ${totalRtlHeight}px + (${rtlBoxCount} * 12px + ${driverDetails}px))`
            : '80px';

        return (
          <Grid2 key={driver.id}>
            <Box
              onClick={() =>
                handleCardClick(driver.id, driver.latitude, driver.longitude)
              }
              sx={{
                'height': calculatedHeight,
                'transition': 'height 0.5s ease-in-out',
                'cursor': 'pointer',
                'backgroundColor': '#FFFFFF',
                'borderRadius': '16px',
                'border': getBorderColor(driver.id) || '2px solid transparent',
                'overflow': 'hidden',
                '@keyframes blink-border': {
                  '0%': { border: '2px solid #BA1F1F' },
                  '50%': { border: '2px solid transparent' },
                  '100%': { border: '2px solid #BA1F1F' },
                },
              }}
            >
              <Grid2
                container
                display={'flex'}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'space-between'}
                columnSpacing={1}
                margin={'0px 16px'}
                height={'100%'}
                sx={{
                  transition: 'all 0.5s ease',
                }}
              >
                <Grid2 container size={10}>
                  <Grid2
                    container
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    marginLeft={'10px'}
                  >
                    <Grid2>
                      {/* we are checking to see if the driver name is '' to determine if the route has a driver assigned to it */}
                      {/* we then check to see if a rtl or iMseal is displayed as the FIRST item in the list and display that as the 'heading' */}
                      {/* If an RTL is displayed as the first item in the list, we display the RTL serial number as the heading SAME IF IMSEAL IS FIRST */}
                      {/* based on data provided if driver.seal_id is '' there is no imSeal to display so we cannot send the seal serial to be displayed on the frontend */}
                      <Typography
                        noWrap
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {driver.name === ''
                          ? driver.seal_id !== ''
                            ? driver.serial_number
                            : driver.rtl_info?.[0]?.serial
                          : driver.vehicle_registration ||
                            driver.vehicle_alias ||
                            ''}
                      </Typography>
                    </Grid2>
                  </Grid2>
                  <Grid2
                    container
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                    width={'100%'}
                    marginLeft={'10px'}
                  >
                    <Grid2>
                      <Typography
                        sx={{
                          color: '#232323',
                          fontSize: '0.8rem',
                        }}
                      >
                        {driver.name}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2
                  container
                  size={0.2}
                  display={'flex'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                  wrap="nowrap"
                >
                  {expandedCardId !== driver.id && (
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                      {driver.seal_id !== '' && (
                        <Grid2>
                          <Tooltip
                            title={driver.lock_status ? 'Locked' : 'Unlocked'}
                          >
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              sx={{
                                backgroundColor: driver.lock_status
                                  ? '#30D1581A'
                                  : '#DD333333',
                                borderRadius: '12px',
                                minWidth: iconBorderSize,
                                minHeight: iconBorderSize,
                              }}
                            >
                              <LockIcon
                                style={{
                                  width: lockIconWidth,
                                  fill: driver.lock_status
                                    ? '#34C759'
                                    : '#BA1F1F',
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Grid2>
                      )}
                      {driver.rtl_info && driver.rtl_info.length > 0 && (
                        <Grid2>
                          <Tooltip
                            title={
                              driver.rtl_info.length === 1
                                ? `RTL Status: ${driver.rtl_info[0]?.status}`
                                : `RTL Status: Multiple (${getMostSevereStatus(
                                    driver.rtl_info
                                  )})`
                            }
                          >
                            <Box
                              sx={{
                                position: 'relative', // Add relative positioning here
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={getIcon(
                                  getMostSevereStatus(driver.rtl_info)
                                )}
                                alt="rtl icon"
                                style={{
                                  width: iconBorderSize,
                                }}
                              />
                              {hasUnseenAlerts(driver.rtl_info) && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: -4,
                                    right: -4,
                                    width: 8,
                                    height: 8,
                                    backgroundColor: '#DD3333',
                                    borderRadius: '50%',
                                    border: '1px solid white',
                                    zIndex: 1,
                                  }}
                                />
                              )}
                            </Box>
                          </Tooltip>
                        </Grid2>
                      )}
                    </Box>
                  )}
                </Grid2>
                {driver.id && expandedCardId === driver.id && (
                  <Grid2
                    container
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    marginTop={'15px'}
                  >
                    {driver.seal_id !== '' && (
                      <Grid2
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <ButtonBox
                          serialNumber={driver.serial_number}
                          lockState={driver.lock_state}
                          onToggle={handleButtonBoxToggle}
                          handleUnlockLeftClick={handleUnlockLeftClick}
                          handleUnlockRightClick={handleUnlockRightClick}
                          buttonFontSize={buttonFontSize}
                          iconWidth={iconWidth}
                          expanded={expandedBox === 'buttonBox'}
                          setExpanded={(expanded) =>
                            setExpandedBox(expanded ? 'buttonBox' : null)
                          }
                          currentExpandedBox={expandedBox}
                          battery={driver.battery}
                        />
                      </Grid2>
                    )}

                    {driver.rtl_info && driver.rtl_info?.length > 0 ? (
                      driver.rtl_info.map((info) => (
                        <Grid2
                          container
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          key={info.id}
                          sx={{ cursor: 'pointer' }}
                        >
                          <RTLBox
                            loadTime={componentLoadTime}
                            rtlSerial={info.serial}
                            rtlClick={() =>
                              handleRtlClick && handleRtlClick(info)
                            }
                            data={info}
                            status={info.status}
                            onToggle={(height, expanded) =>
                              handleRtlBoxToggle(info.id, height, expanded)
                            }
                            expanded={expandedBox === info.id}
                            setExpanded={(expanded) =>
                              setExpandedBox(expanded ? info.id : null)
                            }
                            currentExpandedBox={expandedBox}
                            id={info.id}
                            warnings={info.alerts.map((alert) => ({
                              ...alert,
                              seen: alert.seen ?? false,
                            }))}
                          />
                        </Grid2>
                      ))
                    ) : (
                      <Grid2
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="0"
                      />
                    )}
                    {/* THIS IS CONDISH TO DISPLAY DRIVER CARDS IF DRIVER EXISTS */}
                    {driver.name !== '' && (
                      <Grid2
                        container
                        display={'flex'}
                        alignItems={'center'}
                        width={'100%'}
                        spacing={1}
                        sx={{
                          padding: '16px 16px',
                          backgroundColor: '#F5F5F5',
                          borderRadius: '16px',
                        }}
                      >
                        <Grid2 size={12}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#838383',
                            }}
                          >
                            {t('Driver Contact Number')}
                          </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                          <Typography
                            sx={{
                              fontSize: '18px',
                            }}
                          >
                            {driver.contact_number}
                          </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                          <Divider />
                        </Grid2>
                        <Grid2 size={6}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#838383',
                            }}
                          >
                            {t('Number Plate')}
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={6}
                          display={'flex'}
                          flexDirection={'row-reverse'}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                            }}
                          >
                            {driver.vehicle_registration}
                          </Typography>
                        </Grid2>
                        <Grid2 size={6}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#838383',
                            }}
                          >
                            {t('Email')}
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={6}
                          display={'flex'}
                          flexDirection={'row-reverse'}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                            }}
                          >
                            {driver.email}
                          </Typography>
                        </Grid2>
                        <Grid2 size={6}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#838383',
                            }}
                          >
                            {t('Vehicle Alias')}
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={6}
                          display={'flex'}
                          flexDirection={'row-reverse'}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                            }}
                          >
                            {driver.vehicle_alias}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    )}
                    <Grid2
                      container
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      marginTop={'6px'}
                      sx={{
                        padding: '16px 16px',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '16px',
                      }}
                    >
                      <Grid2 container>
                        <Grid2>
                          <RouteIcon
                            style={{
                              height: iconWidth,
                            }}
                          />
                        </Grid2>
                        <Grid2>
                          <Typography
                            sx={{
                              fontSize: routeInfoSize,
                            }}
                          >
                            {t('Route Information')}
                          </Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2>
                        <Button
                          fullWidth
                          onClick={(event) =>
                            handleEditRouteClick(event, driver.vehicle_id)
                          }
                          sx={{
                            backgroundColor: '#EEEEEE',
                            borderRadius: '8px',
                            textTransform: 'none',
                            padding: '4px 8px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: buttonFontSize,
                              color: '#000000',
                            }}
                          >
                            {t('Edit Route')}
                          </Typography>
                        </Button>
                      </Grid2>

                      <Grid2
                        container
                        display={'flex'}
                        marginTop={'20px'}
                        marginLeft={addressMargin}
                        maxWidth={'100%'}
                      >
                        <Grid2 container display={'flex'} size={12}>
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              backgroundColor:
                                driver.three_trip[0] === null
                                  ? '#E5E5E5'
                                  : '#B4EE2B',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {driver.three_trip[0] === null ? (
                              <CircleIcon
                                style={{
                                  fill: '#FFFFFF',
                                  height: '8px',
                                }}
                              />
                            ) : (
                              <CheckIcon
                                style={{
                                  fill: '#FFFFFF',
                                  height: '16px',
                                }}
                              />
                            )}
                          </Box>
                          <Typography
                            sx={{
                              fontSize: addressSize,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: addressOverflow,
                            }}
                          >
                            {driver.three_trip[0] === null
                              ? 'N/A'
                              : driver.three_trip[0]}
                          </Typography>
                        </Grid2>
                        <Grid2>
                          <Box
                            sx={{
                              width: '2px',
                              height: '14px',
                              backgroundColor:
                                driver.three_trip[0] === null
                                  ? '#C7C7C7'
                                  : '#B4EE2B',
                              margin: '4px 10px',
                            }}
                          ></Box>
                        </Grid2>
                        <Grid2 container display={'flex'} size={12}>
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              backgroundColor:
                                driver.three_trip[1] === null
                                  ? '#E5E5E5'
                                  : '#B4EE2B',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircleIcon
                              style={{
                                fill: '#FFFFFF',
                                height: '8px',
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              fontSize: addressSize,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: addressOverflow,
                            }}
                          >
                            {driver.three_trip[1] === null
                              ? 'N/A'
                              : driver.three_trip[1]}
                          </Typography>
                        </Grid2>
                        <Grid2>
                          <Box
                            sx={{
                              width: '2px',
                              height: '14px',
                              backgroundColor: '#C7C7C7',
                              margin: '4px 10px',
                            }}
                          ></Box>
                        </Grid2>
                        <Grid2 container display={'flex'} size={12}>
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              backgroundColor: '#E5E5E5',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircleIcon
                              style={{
                                fill: '#FFFFFF',
                                height: '8px',
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              fontSize: addressSize,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: addressOverflow,
                            }}
                          >
                            {driver.three_trip[2] === null
                              ? 'N/A'
                              : driver.three_trip[2]}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                )}
              </Grid2>
            </Box>
          </Grid2>
        );
      })}
    </Grid2>
  );
};

export default DriverCardList;
