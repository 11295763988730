import { useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Box, Typography, IconButton, Button, Tooltip } from '@mui/material';
import React from 'react';
import ChevronDownIcon from '../../../../../assets/icons/chevron-down.svg';
import ChevronUpIcon from '../../../../../assets/icons/chevron-up.svg';
import LockGreen from '../../../../../assets/icons/locked-green.svg';
import LockRed from '../../../../../assets/icons/unlock-red.svg';
import LockWarning from '../../../../../assets/icons/lock-warning.svg';
import BatteryLow from '../../../../../assets/icons/battery-quarter-solid.svg';
import BatteryFull from '../../../../../assets/icons/battery_percentage.svg';
import BatteryThreeQuarters from '../../../../../assets/icons/battery-three-quarters-solid.svg';
import BatteryHalf from '../../../../../assets/icons/battery-half-solid.svg';
import NoSignal from '../../../../../assets/icons/no-signal.svg';
import UnlockIcon from '../../../../../assets/icons/unlock.svg';
import LockIcon from '../../../../../assets/icons/lock.svg';

interface ButtonBoxProps {
  serialNumber: string;
  lockState: string;
  onToggle: (height: number, expanded: boolean) => void;
  handleUnlockLeftClick: (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => void;
  handleUnlockRightClick: (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => void;
  buttonFontSize: string;
  iconWidth: string;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  currentExpandedBox: 'buttonBox' | string | null;
  battery: string;
}

const ButtonBox = forwardRef(function ButtonBox(
  {
    serialNumber,
    battery,
    lockState,
    onToggle,
    handleUnlockLeftClick,
    handleUnlockRightClick,
    buttonFontSize,
    expanded,
    setExpanded,
    currentExpandedBox,
  }: ButtonBoxProps,
  ref
) {
  const contentRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    getContentHeight: () => {
      return contentRef.current ? contentRef.current.scrollHeight : 0;
    },
  }));

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const batteryPercentage = (battery: string) => {
    const batteryLevel = parseInt(battery, 10);

    switch (true) {
      case batteryLevel >= 0 && batteryLevel <= 20:
        return BatteryLow;
      case batteryLevel > 20 && batteryLevel <= 40:
        return BatteryHalf;
      case batteryLevel > 40 && batteryLevel <= 60:
        return BatteryThreeQuarters;
      case batteryLevel > 60 && batteryLevel <= 100:
        return BatteryFull;
      default:
        return NoSignal;
    }
  };

  const getLockIcon = (status: string) => {
    switch (status) {
      case 'LOCKED':
        return LockGreen;
      case 'LEFT_UNLOCKED':
      case 'RIGHT_UNLOCKED':
      case 'CRADLE_UNLOCKED':
        return LockRed;
      case 'PROCESSING':
        return LockWarning;
      default:
        return LockGreen;
    }
  };

  useEffect(() => {
    if (currentExpandedBox === 'buttonBox' && contentRef.current) {
      onToggle(expanded ? contentRef.current.scrollHeight + 44 : 44, expanded);
    }
  }, [expanded, onToggle, currentExpandedBox]);

  return (
    <Box
      sx={{
        backgroundColor: '#F5F5F5',
        borderRadius: '12px',
        padding: '16px',
        width: '100%',
        marginBottom: '6px',
        overflow: 'hidden',
        cursor: 'default', // Default cursor for the entire box
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0%',
            }}
          >
            iMSeal
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0%',
            }}
          >
            {serialNumber}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={`Battery: ${battery}%`}>
            <img
              src={batteryPercentage(battery)}
              alt="battery tab icon"
              style={{ marginRight: '8px' }}
            />
          </Tooltip>
          <Tooltip title={`Lock State: ${lockState}`}>
            <img
              src={getLockIcon(lockState)}
              alt="lock tab icon"
              style={{ marginRight: '8px', height: '28px' }}
            />
          </Tooltip>
          <IconButton
            onClick={handleToggle}
            size="small"
            sx={{ cursor: 'pointer' }}
          >
            <img
              src={expanded ? ChevronUpIcon : ChevronDownIcon}
              alt="chevron icon"
            />
          </IconButton>
        </Box>
      </Box>

      <Box
        ref={contentRef}
        sx={{
          maxHeight: expanded ? '500px' : '0px',
          opacity: expanded ? 1 : 0,
          transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            marginTop: '10px',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button
              fullWidth
              onClick={(event) =>
                handleUnlockLeftClick(
                  event,
                  serialNumber,
                  lockState !== 'LEFT_UNLOCKED'
                )
              }
              disabled={lockState === 'PROCESSING'}
              sx={{
                'backgroundColor':
                  lockState === 'LEFT_UNLOCKED'
                    ? '#B4EE2B'
                    : lockState === 'PROCESSING'
                    ? '#C7C7C7'
                    : '#000000',
                'borderRadius': '12px',
                'textTransform': 'none',
                'padding': '6px 20px',
                'height': '35px',
                'cursor': 'pointer',
                'transition': 'background-color 1s ease',
                '&:hover': {
                  boxShadow: '0px 0px 0.5px 0.5px #000000',
                },
              }}
            >
              <Typography
                sx={{
                  color: lockState === 'LEFT_UNLOCKED' ? '#000000' : '#ffffff',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                marginLeft={'6px'}
                fontSize={buttonFontSize}
              >
                {lockState === 'LEFT_UNLOCKED' ? (
                  <>
                    <img
                      src={LockIcon}
                      alt="lock"
                      style={{
                        width: '16px',
                        height: '16px',
                        filter: 'brightness(0)', // Makes it black for the green button
                      }}
                    />
                    Lock Left
                  </>
                ) : lockState === 'PROCESSING' ? (
                  'Processing...'
                ) : (
                  <>
                    <img
                      src={UnlockIcon}
                      alt="unlock"
                      style={{
                        width: '16px',
                        height: '16px',
                        filter: 'brightness(0) invert(1)', // Makes it white
                      }}
                    />
                    Unlock Left
                  </>
                )}
              </Typography>
            </Button>
            <Button
              fullWidth
              onClick={(event) =>
                handleUnlockRightClick(
                  event,
                  serialNumber,
                  lockState !== 'RIGHT_UNLOCKED'
                )
              }
              disabled={lockState === 'PROCESSING'}
              sx={{
                'backgroundColor':
                  lockState === 'RIGHT_UNLOCKED'
                    ? '#B4EE2B'
                    : lockState === 'PROCESSING'
                    ? '#C7C7C7'
                    : '#000000',
                'borderRadius': '12px',
                'textTransform': 'none',
                'padding': '6px 20px',
                'height': '35px',
                'cursor': 'pointer',
                'transition': 'background-color 1s ease',
                '&:hover': {
                  boxShadow: '0px 0px 0.5px 0.5px #000000',
                },
              }}
            >
              <Typography
                sx={{
                  color: lockState === 'RIGHT_UNLOCKED' ? '#000000' : '#ffffff',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                marginLeft={'6px'}
                fontSize={buttonFontSize}
              >
                {lockState === 'RIGHT_UNLOCKED' ? (
                  <>
                    <img
                      src={LockIcon}
                      alt="lock"
                      style={{
                        width: '16px',
                        height: '16px',
                        filter: 'brightness(0)', // Makes it black for the green button
                      }}
                    />
                    Lock Right
                  </>
                ) : lockState === 'PROCESSING' ? (
                  'Processing...'
                ) : (
                  <>
                    <img
                      src={UnlockIcon}
                      alt="unlock"
                      style={{
                        width: '16px',
                        height: '16px',
                        filter: 'brightness(0) invert(1)', // Makes it white
                      }}
                    />
                    Unlock Right
                  </>
                )}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

ButtonBox.displayName = 'ButtonBox';

export default ButtonBox;
