export const getValueStyle = (displayValue: string) => {
  let textColor = '';
  let backgroundColor = '';

  switch (displayValue) {
    case 'GOOD':
      textColor = '#34C759';
      backgroundColor = '#30D1581A';
      break;
    case 'OKAY':
      textColor = '#FF9500';
      backgroundColor = '#FFE6C8';
      break;
    case 'BAD':
      textColor = '#BA1F1F';
      backgroundColor = '#DD333333';
      break;
    default:
      textColor = '#000000';
      backgroundColor = '#FFFFFF';
  }

  return { textColor, backgroundColor };
};
