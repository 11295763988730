import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';
import ToleranceProfileTableComponent from './Components/ToleranceProfileTable';
import CreateRTLToleranceProfileModal from './Components/CreateRTLToleranceProfileModal';

function RTLToleranceProfiles() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cookies] = useCookies(['user', 'role']);
  const [openAddNew, setOpenAddNew] = useState(false);

  // Search & Pagination
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  // Column mapping for the table
  const columnFieldMapping = {
    'Profile Name': 'Profile_Name',
    'Temperature': 'Temperature',
    'Humidity': 'Humidity',
    'Light': 'Light',
  };

  const columns = Object.keys(columnFieldMapping);

  // Event Handlers
  const handlePlusIconClick = () => setOpenAddNew(true);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2 sx={{ width: '100%' }}>
        <ToleranceProfileTableComponent
          title={t('RTL Tolerance/Sensitivity Profiles')}
          searchValue={searchValue}
          columns={columns}
          onSearchValueChange={handleSearchChange}
          onPlusIconClick={handlePlusIconClick}
        />
      </Grid2>

      <CreateRTLToleranceProfileModal
        open={openAddNew}
        onClose={() => setOpenAddNew(false)}
        onSubmit={() => {}}
      />
    </Grid2>
  );
}

export default RTLToleranceProfiles;
