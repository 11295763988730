import { useEffect, useRef } from 'react';

interface UseSSEProps {
  url: string;
  onMessage: (data: { timestamp: number; data: object[]; type: string; device_id: string }) => void;
  onError?: (error: Event) => void;
}

export const useSSE = ({ url, onMessage, onError }: UseSSEProps) => {
  const eventSourceRef = useRef<EventSource | null>(null);

  useEffect(() => {
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      try {
        const sanitizedData = event.data.replace(/'/g, '"');
        const data = JSON.parse(sanitizedData);
        onMessage(data);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('SSE Error:', error);
      onError?.(error);
    };

    eventSourceRef.current = eventSource;

    return () => {
      eventSource.close();
    };
  }, [url, onMessage, onError]);

  return eventSourceRef.current;
};