import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../utils/useDebounce';
import { format } from 'date-fns';

import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/circle-xmark.svg';
import { ReactComponent as SkipIcon } from '../../../assets/icons/forward.svg';
import { ReactComponent as TripCompleteIcon } from '../../../assets/icons/trip-complete.svg';

import RouteTableComponent from '../../../components/Table/RouteTableComponent';
import { GetDriverRouteResponse } from '../../../interfaces/Driver/Response/getDriverRoute';
import { GetDriverRouteRequest } from '../../../interfaces/Driver/Request/getDriverRoute';
import { useNavigate, useParams } from 'react-router-dom';
import { SkipAddressRequest } from '../../../interfaces/Route/Request/skipAddress';
import { TripResponse } from '../../../interfaces/Route/Response/trip';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { CancelAddressRequest } from '../../../interfaces/Route/Request/cancelAddress';
import {
  addCompanyLocationRequest,
  CompanyLocationsRequest,
} from '../../../interfaces/Location/Request/companyLocations';
import { useCookies } from 'react-cookie';
import { CompanyLocationsResponse } from '../../../interfaces/Location/Response/companyLocations';
import AddNewTripModal from './Components/AddNewTripModal';
import { CompanyDriversRequest } from '../../../interfaces/Driver/Request/companyDrivers';
import { CompanyDriversResponse } from '../../../interfaces/Driver/Response/companyDrivers';
import { setLocation } from '../../../utils/locationUtil';
import { useTranslation } from 'react-i18next';
import SkipAddressModal from './Components/SkipAddressModal';
import CancelAddressTripModal from './Components/CancelAddressModal';
import { getCompanyDrivers } from '../../../services/Api/apiDriver';
import {
  addLocation,
  getCompanyLocations,
} from '../../../services/Api/apiLocation';
import {
  skipAddress,
  cancelAddress,
  addToRoute,
} from '../../../services/Api/apiRoute';
import { getDriverRoute } from '../../../services/Api/apiVehicle';
import LocationModal from '../Locations/Components/LocationModal';
import CompleteTripModal from '../Seals/Components/MarkAsCompleteModal';
import { completeTrip } from '../../../services/Api/apiSeal';

function RoutesPage() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [route, setRoute] = useState<GetDriverRouteResponse>();
  const [selectedRow, setSelectedRow] = useState<TripResponse>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columnFieldMapping = {
    'Destination': 'end_location',
    'Status': 'status',
    'Assigned Driver': 'assigned_driver',
    'Date Actioned': 'date_actioned',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['35%', '30%', '15%', '15%'];

  const actions = (row: { [key: string]: unknown }) => {
    const baseActions = [
      {
        label: 'View Destination on Map',
        icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
        onClick: () => {
          setSelectedRow(row as unknown as TripResponse);
          setLocation(
            parseFloat(row.end_location_latitude as string),
            parseFloat(row.end_location_longitude as string)
          );
          navigate('/');
        },
      },
      {
        label: 'Skip Address',
        icon: <SkipIcon style={{ width: '18px', marginRight: '8px' }} />,
        onClick: () => {
          setOpenSkipAddress(true);
          setSelectedRow(row as unknown as TripResponse);
        },
      },
      {
        label: 'Cancel',
        icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
        onClick: () => {
          setOpenCancelAddress(true);
          setSelectedRow(row as unknown as TripResponse);
        },
      },
    ];

    // Only add Complete action if status is ACTIVE
    if (row.status === 'ACTIVE') {
      baseActions.push({
        label: 'Mark as Complete',
        icon: (
          <TripCompleteIcon
            style={{
              width: '18px',
              marginRight: '8px',
              maxHeight: '18px',
            }}
          />
        ),
        onClick: () => {
          setIsCompleteModalOpen(true);
          setSelectedRow(row as unknown as TripResponse);
        },
      });
    }

    return baseActions;
  };

  const handlePlusIconClick = () => {
    setOpenAddRouteItem(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleTimeChange = (dataInput: GetDriverRouteResponse) => {
    dataInput.trips.forEach((trip) => {
      if (trip.date_actioned !== '') {
        trip.date_actioned = format(
          new Date(trip.date_actioned),
          'HH:mm - dd MMM yyyy'
        );
      }
    });

    return dataInput;
  };

  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const payload: GetDriverRouteRequest = {
          vehicle_id: id ?? '',
          search_term: debouncedSearchValue,
          page: 1,
          refetch: false,
        };

        const data = await getDriverRoute(payload);
        setRoute(handleTimeChange(data.routes[0]));
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchRoute);
        setSnackbarOpen(true);
      }
    };

    fetchRoute();
  }, [debouncedSearchValue, id, trigger]);

  // Methods pertaining to skip address

  const [openSkipAddress, setOpenSkipAddress] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);

  const handleCompleteTrip = async (routeId: string, tripId: string) => {
    try {
      await completeTrip({
        route_id: routeId,
        trip_id: tripId,
      });

      setIsCompleteModalOpen(false);
      setTrigger(!trigger);
      setSnackbarMessage('Trip successfully completed');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message || 'Failed to complete trip');
      setSnackbarSeverity('error');
      setSnackbarRetry(() => () => handleCompleteTrip(routeId, tripId));
      setSnackbarOpen(true);
      console.error('Failed to complete trip:', error);
    }
  };

  const [formValuesSkipAddress, setFormValuesSkipAddress] = useState({
    reAddAddress: false,
  });

  const handleSkipAddress = () => {
    const skipAddress_ = async () => {
      try {
        const payload: SkipAddressRequest = {
          route_id: route?.route_id ?? '',
          trip_id: selectedRow?.trip_id ?? '',
          reAdd: formValuesSkipAddress.reAddAddress,
        };
        await skipAddress(payload);

        setTrigger(!trigger);
        setOpenSkipAddress(false);
        setSnackbarMessage('Address successfully skipped.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    skipAddress_();
  };

  useEffect(() => {
    if (openSkipAddress) {
      setFormValuesSkipAddress({ reAddAddress: false });
    }
  }, [openSkipAddress]);

  // methods pertaining to cancel address

  const [openCancelAddress, setOpenCancelAddress] = useState(false);

  const handleCancelAddress = () => {
    const cancelAddress_ = async () => {
      try {
        const payload: CancelAddressRequest = {
          route_id: route?.route_id ?? '',
          trip_id: selectedRow?.trip_id ?? '',
        };
        await cancelAddress(payload);

        setTrigger(!trigger);
        setOpenCancelAddress(false);
        setSnackbarMessage('Address successfully cancelled.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    cancelAddress_();
  };

  // methods pertaining to add new route item

  const [openAddRouteItem, setOpenAddRouteItem] = useState(false);
  const [allLocations, setAllLocations] = useState<CompanyLocationsResponse[]>(
    []
  );
  const [allDrivers, setAllDrivers] = useState<CompanyDriversResponse[]>([]);
  const [formValues, setFormValues] = useState({
    location: '',
    driver: '',
  });

  useEffect(() => {
    const getAllLocations = async () => {
      try {
        const payload: CompanyLocationsRequest = {
          company_id: cookies.user.company_id,
          search_term: '',
          page: -1,
          refetch: true,
        };
        const response = await getCompanyLocations(payload);
        setAllLocations(response.locations);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => getAllLocations);
        setSnackbarOpen(true);
      }
    };
    getAllLocations();
  }, [cookies.user.company_id]);

  useEffect(() => {
    const getAllDrivers = async () => {
      try {
        const payload: CompanyDriversRequest = {
          company_id: cookies.user.company_id,
          search_term: '',
          page: -1,
          refetch: true,
        };
        const response = await getCompanyDrivers(payload);
        setAllDrivers(response.drivers);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => getAllDrivers);
        setSnackbarOpen(true);
      }
    };
    getAllDrivers();
  }, [cookies.user.company_id]);

  const handleAddNewTrip = async () => {
    try {
      const payload = {
        route_id: route?.route_id ?? '',
        start_location_id: formValues.location,
        end_location_id: formValues.location,
        expected_departure_time: new Date().toISOString(),
        expected_arrival_time: new Date().toISOString(),
        driver_id: formValues.driver === '' ? null : formValues.driver,
      };

      const response = await addToRoute(payload);

      setOpenAddRouteItem(false);
      setFormValues({ location: '', driver: '' });
      if (response.error === null) {
        setSnackbarMessage('Trip successfully added.');
        setSnackbarSeverity('success');
        setTrigger(!trigger);
      } else {
        throw new Error('Error adding address.');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = async () => {
    const payload: GetDriverRouteRequest = {
      vehicle_id: id ?? '',
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getDriverRoute(payload);
      const formattedData = handleTimeChange(data.routes[0]);

      try {
        const modifiedTrips = [
          ...route!.trips,
          ...formattedData.trips.filter(
            (newTrip) =>
              !route!.trips.some(
                (existingTrip) => existingTrip.trip_id === newTrip.trip_id
              )
          ),
        ];

        const updatedRoute: GetDriverRouteResponse = {
          ...route,
          route_id: route?.route_id ?? '',
          trips: modifiedTrips,
          vehicle_alias: route?.vehicle_alias ?? '',
          vehicle_registration: route?.vehicle_registration ?? '',
        };

        setRoute(updatedRoute);
        setHasMore(data.hasMore);
      } catch {
        // Ignore the caught exception
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  const [locationModalValues, setLocationModalValues] = useState({
    id: '',
    name: '',
    address: '',
    lat: '',
    lng: '',
  });

  const handleAddLocationOpen = () => {
    setOpenAddLocationModal(true);
  };

  const handleAddLocationClose = () => {
    setOpenAddLocationModal(false);
  };

  const fetchLocations = async () => {
    try {
      const payload: CompanyLocationsRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        page: -1,
        refetch: true,
      };
      const data = await getCompanyLocations(payload);
      setAllLocations(data.locations);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => fetchLocations);
      setSnackbarOpen(true);
    }
  };

  const handleAddLocationSubmit = async () => {
    const payload: addCompanyLocationRequest = {
      company_id: cookies.user.company_id,
      name: locationModalValues.name,
      address: locationModalValues.address,
      latitude: parseFloat(locationModalValues.lat),
      longitude: parseFloat(locationModalValues.lng),
    };

    try {
      const response = await addLocation(payload);
      if (response.success) {
        setAllLocations((prevLocations) => [...prevLocations, response.data]);
        setSnackbarSeverity('success');
        setSnackbarMessage('Location added successfully');
        fetchLocations();
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to add location');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setOpenAddLocationModal(false);
    }
    setOpenAddLocationModal(false);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <RouteTableComponent
          title={`${
            route?.vehicle_alias
              ? `${t('Route for')} ${route.vehicle_alias}`
              : route?.vehicle_registration
              ? `${t('Route for')} ${route.vehicle_registration}`
              : t('Vehicle NOT FOUND')
          }`}
          searchValue={searchValue}
          onPlusIconClick={handlePlusIconClick}
          setData={() => {}}
          columns={columns}
          data={route?.trips ?? []}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          onSearchValueChange={handleSearchChange}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <CompleteTripModal
        open={isCompleteModalOpen}
        onClose={() => setIsCompleteModalOpen(false)}
        onSubmit={handleCompleteTrip}
        routeId={route?.route_id || ''}
        tripId={selectedRow?.trip_id || ''}
      />

      <SkipAddressModal
        open={openSkipAddress}
        onClose={() => setOpenSkipAddress(false)}
        formValues={formValuesSkipAddress}
        setFormValues={setFormValuesSkipAddress}
        onSubmit={handleSkipAddress}
      />

      <CancelAddressTripModal
        open={openCancelAddress}
        onClose={() => setOpenCancelAddress(false)}
        onSubmit={handleCancelAddress}
      />

      <AddNewTripModal
        open={openAddRouteItem}
        onClose={() => setOpenAddRouteItem(false)}
        locations={allLocations}
        formValues={formValues}
        setFormValues={setFormValues}
        drivers={allDrivers}
        onSubmit={handleAddNewTrip}
        handleAddLocationOpen={handleAddLocationOpen}
      />
      <LocationModal
        open={openAddLocationModal}
        onClose={handleAddLocationClose}
        onSubmit={handleAddLocationSubmit}
        formValues={locationModalValues}
        setFormValues={setLocationModalValues}
        PaperProps={{
          style: {
            position: 'absolute',
            top: '20%',
            left: '64%',
            marginLeft: 16,
          },
        }}
      />
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default RoutesPage;
