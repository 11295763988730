import React, { useEffect, useState } from 'react';
import { Box, Grid2, Typography, useMediaQuery } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CompanyDriverCardsResponse,
  RTLInfo,
} from '../../../../../interfaces/Driver/Response/companyDriverCards';
import { CompanyDriverCardsRequest } from '../../../../../interfaces/Driver/Request/companyDriverCards';
import { getCompanyDriverCards } from '../../../../../services/Api/apiDriver';
import { setLocation } from '../../../../../utils/locationUtil';
import { getDriverId } from '../../../../../utils/expandDriverCard';
import Locking from '../../../../../components/Locking/Locking';
import SearchBar from '../../SearchBar';
import DriverCardList from '../../DriverCardList';
import { useRtlDevices } from '../../../../../context/RtlDevicesContext';

interface DriverCardsProps {
  setSelectedDriverRtl: (rtlId: string | null) => void;
}

function DriverCards({ setSelectedDriverRtl }: DriverCardsProps) {
  const navigate = useNavigate();
  const { setRtlDevices } = useRtlDevices();
  const [cookies] = useCookies(['user']);
  const [drivers, setDrivers] = useState<CompanyDriverCardsResponse[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [expandedCardId, setExpandedCardId] = useState('');
  const isSmallViewport = useMediaQuery('(max-width:1500px)');
  const { t } = useTranslation();

  const drawerWidth = isSmallViewport ? '350px' : '425px';

  const handleCardClick = (id: string, latitude: string, longitude: string) => {
    const driver = drivers.find((d) => d.id === id);
  
    if (expandedCardId === id) {
      setSelectedDriverRtl(null);
      setExpandedCardId('');
    } else {
      setExpandedCardId(id);  
      if (driver?.rtl_info?.[0]) {
        setSelectedDriverRtl(driver.rtl_info[0].id);
      } else {
        setSelectedDriverRtl(null);
      }
    }
  
    if (latitude && longitude) {
      const lat = Number(latitude);
      const lng = Number(longitude);
      if (!isNaN(lat) && !isNaN(lng)) {
        setLocation(lat, lng);
      }
    }
  };

  // Add after other handler functions
  const handleRtlClick = (rtlInfo: RTLInfo) => {
    // First try direct coordinates
    if (rtlInfo.latitude && rtlInfo.longitude) {
      const lat = Number(rtlInfo.latitude);
      const lng = Number(rtlInfo.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        setLocation(lat, lng);
        return;
      }
    }

    // Fallback to historical data if direct coordinates aren't available
    if (rtlInfo.historical && rtlInfo.historical.length > 0) {
      const latestPoint = rtlInfo.historical[rtlInfo.historical.length - 1];
      if (latestPoint.latitude && latestPoint.longitude) {
        const lat = Number(latestPoint.latitude);
        const lng = Number(latestPoint.longitude);

        if (!isNaN(lat) && !isNaN(lng)) {
          setLocation(lat, lng);
          return;
        }
      }
    }

    console.warn('No valid coordinates found in RTL info');
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      const payload: CompanyDriverCardsRequest = {
        company_id: cookies.user.company_id,
        search: debouncedSearch,
      };
      try {
        const response = await getCompanyDriverCards(payload);

        if (!response) {
          console.error('Invalid response format:', response);
          return;
        }

        setDrivers(response);

        const rtlDevices = response
          .filter(
            (driver: CompanyDriverCardsResponse) =>
              driver?.rtl_info && Array.isArray(driver.rtl_info)
          )
          .flatMap(
            (driver: CompanyDriverCardsResponse) =>
              driver.rtl_info &&
              driver.rtl_info.map(
                (rtl): RTLInfo => ({
                  id: rtl.id,
                  serial: rtl.serial,
                  status: rtl.status,
                  humidity: rtl.humidity,
                  temperature: rtl.temperature,
                  light: rtl.light,
                  shock: rtl.shock,
                  historical: rtl.historical,
                  alerts: rtl.alerts,
                })
              )
          );
        setRtlDevices(rtlDevices);
      } catch (error) {
        console.error('Error fetching drivers:', error);
        setRtlDevices([]);
      }
    };

    fetchDrivers();
  }, [debouncedSearch, cookies.user.company_id, setRtlDevices]);

  useEffect(() => {
    return () => {
      setSelectedDriverRtl(null);
    };
  }, []);

  const hasProcessingState = (data: CompanyDriverCardsResponse[]) => {
    return data.some((entry) => entry.lock_state === 'PROCESSING');
  };

  const pollApi = async () => {
    const payload: CompanyDriverCardsRequest = {
      company_id: cookies.user.company_id,
      search: debouncedSearch,
    };
    try {
      const data = await getCompanyDriverCards(payload);
      setDrivers(data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (hasProcessingState(drivers)) {
      interval = setInterval(() => {
        pollApi();
      }, 4000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [drivers]);

  useEffect(() => {
    setTimeout(() => {
      setExpandedCardId(getDriverId());
    }, 500);
    const handleExpandCard = () => {
      setTimeout(() => {
        setExpandedCardId(getDriverId());
      }, 500);
    };
    window.addEventListener('openDriverCard', handleExpandCard);

    return () => {
      window.removeEventListener('openDriverCard', handleExpandCard);
    };
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 200);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const refetchInfo = async () => {
    const payload: CompanyDriverCardsRequest = {
      company_id: cookies.user.company_id,
      search: debouncedSearch,
    };
    try {
      const data = await getCompanyDriverCards(payload);
      setDrivers(data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const handleUnlockLeftClick = (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => {
    event.stopPropagation();
    setLeftLock(true);
    setCurrentLockState(lockState);
    setSelectedSeal(serial_number);
    setOpenLocking(true);
  };

  const handleUnlockRightClick = (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => {
    event.stopPropagation();
    setLeftLock(false);
    setCurrentLockState(lockState);
    setSelectedSeal(serial_number);
    setOpenLocking(true);
  };

  const handleEditRouteClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/vehicles/${id}`);
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState('');

  return (
    <Box
      sx={{
        width: drawerWidth,
        minWidth: drawerWidth,
        height: '100vh',
      }}
    >
      <Grid2
        container
        spacing={2}
        direction={'column'}
        display={'flex'}
        margin={'0px 20px 0px 30px'}
      >
        <Grid2 marginTop={'30px'}>
          <SearchBar search={search} setSearch={setSearch} />
        </Grid2>
        <Grid2>
          <Typography sx={{ fontWeight: 550 }}>
            {t('Connected Devices')}
          </Typography>
        </Grid2>
        <Grid2
          sx={{
            'height': 'calc(100vh - 120px)',
            'overflowY': 'scroll',
            'pointerEvents': 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#D3D3D3',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#B0B0B0',
            },
          }}
        >
          <DriverCardList
            drivers={drivers}
            expandedCardId={expandedCardId}
            handleCardClick={handleCardClick}
            handleRtlClick={handleRtlClick} // Add this line
            handleUnlockLeftClick={handleUnlockLeftClick}
            handleUnlockRightClick={handleUnlockRightClick}
            handleEditRouteClick={handleEditRouteClick}
            setLeftLock={setLeftLock}
            setCurrentLockState={setCurrentLockState}
            setSelectedSeal={setSelectedSeal}
            setOpenLocking={setOpenLocking}
            isSmallViewport={isSmallViewport}
          />
        </Grid2>
      </Grid2>
      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal}
        company_id={cookies.user.company_id}
      />
    </Box>
  );
}

export default DriverCards;
