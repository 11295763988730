import React from 'react';
import { Box } from '@mui/material';

interface ChartShimmerProps {
  height?: string | number;
}

const ChartShimmer: React.FC<ChartShimmerProps> = ({ height = '400px' }) => {
  return (
    <Box
      sx={{
        'width': '100%',
        height,
        'position': 'relative',
        'backgroundColor': '#f6f7f8',
        'borderRadius': '8px',
        'overflow': 'hidden',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          transform: 'translateX(-100%)',
          backgroundImage:
            'linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0))',
          animation: 'shimmer 2s infinite',
        },
        '@keyframes shimmer': {
          '100%': {
            transform: 'translateX(100%)',
          },
        },
      }}
    >
      {/* Chart-like skeleton */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          padding: '20px',
        }}
      ></Box>
    </Box>
  );
};

export default ChartShimmer;
