import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  InputAdornment,
  Autocomplete,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';
import modalTheme from '../../../../theme/modalTheme';
import { useTranslation } from 'react-i18next';
import { RtlDevice, Vehicle } from '../../../../services/Api/apiRTL';

interface AssignToVehicleModalProps {
  open: boolean;
  onClose: () => void;
  RtlDetails: RtlDevice | Record<string, never>;
  formValues: { vehicle: string };
  setFormValues: React.Dispatch<React.SetStateAction<{ vehicle: string }>>;
  vehicles: Vehicle[];
  onSubmit: () => void;
}

const AssignToVehicleModal: React.FC<AssignToVehicleModalProps> = ({
  open,
  onClose,
  RtlDetails,
  formValues,
  setFormValues,
  vehicles,
  onSubmit,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({ vehicle: '' });
    }
  }, [open, setFormValues]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ '& .MuiDialog-paper': { width: '480px' } }}
      >
        <DialogTitle>
          {t('Assign')} {'rtl_id' in RtlDetails ? RtlDetails.rtl_id : 'N/A'}
        </DialogTitle>

        <Typography variant="subtitle1">{t('Search for a vehicle')}</Typography>

        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Vehicle')}
          </Typography>

          <Autocomplete
            options={vehicles}
            getOptionLabel={(option) =>
              `${option.vehicle_alias} (${option.vehicle_registration})`
            }
            value={
              vehicles.find(
                (v) => v.vehicle_registration === formValues.vehicle
              ) || null
            }
            onChange={(event, newValue) => {
              setFormValues({
                vehicle: newValue?.vehicle_registration || '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('Vehicle Alias or Registration')}
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!(formValues.vehicle.trim() !== '')}
            onClick={onSubmit}
            color="primary"
          >
            {t('Assign to Vehicle')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AssignToVehicleModal;
